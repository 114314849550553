export interface Application {
  id: string;
  name: string;
}

export function NewUnsetApplication(): Application {
  return {
    id: '',
    name: 'Not Set',
  };
}

export function NewMarketplaceApplication(marketplaceApp: { appId: string; name: string }): Application {
  return {
    id: '/app/marketplace-app/' + marketplaceApp.appId,
    name: marketplaceApp.name,
  };
}

export function NewPartnerCenterApplication(): Application {
  return {
    id: '/application/partner-center',
    name: 'Partner Center',
  };
}

export function NewTaskManagerApplication(): Application {
  return {
    id: '/application/task-manager',
    name: 'Task Manager',
  };
}

export function NewSalesAndSuccessCenterApplication(): Application {
  return {
    id: '/application/sales-success-center',
    name: 'Sales and Success Center',
  };
}

export function NewBusinessCenterApplication(): Application {
  return {
    id: '/application/business-center',
    name: 'Business Center',
  };
}
