import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AtlasModule } from '@galaxy/atlas';
import { CoreModule, SessionService } from '@galaxy/core';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyNavModule } from '@vendasta/galaxy/nav';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { GalaxySnackbarModule } from '@vendasta/galaxy/snackbar-service';
import { OAuth2Module, OAuth2Service, OAuth2ServiceConfigToken } from '@vendasta/oauth2';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { AuthedAppComponent } from './authed-app.component';
import { AccessInterceptor } from './core/access/interceptor';
import { AuthService, oauth2ConfigFactory } from './core/auth/auth.service';
import { AutomationsModule } from './pages/automations/automations.module';
import { EmailModule } from './pages/email/email.module';
import { LogoutModule } from './pages/logout';
import { NotificationsModule } from './pages/notifications';
import { Oauth2ScopeManagementModule } from './pages/oauth2-scope-management/oauth2-scope-management.module';
import { TheLoopModule } from './pages/the-loop/the-loop.module';

// We need a factory, since localStorage is not available during AOT build time.
function storageFactory(): OAuthStorage {
  return window.localStorage;
}

@NgModule({
  declarations: [AuthedAppComponent],
  imports: [
    AtlasModule,
    CoreModule,
    CommonModule,
    LogoutModule,
    RouterModule,
    MatCardModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    OAuth2Module,
    EmailModule,
    NotificationsModule,
    GalaxyNavModule,
    MatListModule,
    TranslateModule.forRoot(),
    GalaxySnackbarModule,
    MatSlideToggleModule,
    Oauth2ScopeManagementModule,
    TheLoopModule,
    AutomationsModule,
    GalaxyPageModule,
  ],
  providers: [
    AuthService,
    { provide: SessionService, useExisting: OAuth2Service },
    { provide: HTTP_INTERCEPTORS, useClass: AccessInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: OAuth2ServiceConfigToken, useFactory: oauth2ConfigFactory },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AuthedAppModule {}
