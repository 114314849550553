import { InAppState, initialInAppState } from './in-app.reducers';
import { InAppBundleState, initialInAppBundleState } from './in-app-bundle.reducers';
import { EmailState, initialEmailState } from './email.reducers';
import { State } from '../editor.reducers';
import { EditorActions } from '../editor.actions';
import { LocaleActionTypes } from '../actions/locale.actions';
import { InAppActionTypes } from '../actions/in-app.actions';
import { InAppBundleActionTypes } from '../actions/in-app-bundle.actions';
import { EmailActionTypes } from '../actions/email.actions';
import { DEFAULT_LOCALE } from '../locales';

export interface LocaleState {
  inApp: InAppState;
  inAppBundle: InAppBundleState;
  email: EmailState;
}

export function initialLocaleState(): LocaleState {
  return {
    inApp: initialInAppState(),
    inAppBundle: initialInAppBundleState(),
    email: initialEmailState(),
  };
}

export function localeReducer(nextState: State, action: EditorActions): State {
  switch (action.type) {
    case LocaleActionTypes.ADD_LOCALE:
      if (action.payload.locale in nextState.locales) {
        break;
      }
      if (!(DEFAULT_LOCALE in nextState.locales)) {
        nextState.locales[DEFAULT_LOCALE] = initialLocaleState();
        break;
      }
      nextState.locales[action.payload.locale] = initialLocaleState();
      nextState.locales[action.payload.locale].inApp.contentTemplate =
        nextState.locales[nextState.general.selectedLocale].inApp.contentTemplate;
      nextState.locales[action.payload.locale].inApp.contentTemplateError =
        nextState.locales[nextState.general.selectedLocale].inApp.contentTemplateError;
      nextState.locales[action.payload.locale].inApp.linkTemplate =
        nextState.locales[nextState.general.selectedLocale].inApp.linkTemplate;
      nextState.locales[action.payload.locale].inApp.linkTemplateError =
        nextState.locales[nextState.general.selectedLocale].inApp.linkTemplateError;
      nextState.locales[action.payload.locale].inApp.renderedHTML =
        nextState.locales[nextState.general.selectedLocale].inApp.renderedHTML;
      nextState.locales[action.payload.locale].inApp.renderedHTMLError =
        nextState.locales[nextState.general.selectedLocale].inApp.renderedHTMLError;
      nextState.locales[action.payload.locale].inAppBundle.contentTemplate =
        nextState.locales[nextState.general.selectedLocale].inAppBundle.contentTemplate;
      nextState.locales[action.payload.locale].inAppBundle.contentTemplateError =
        nextState.locales[nextState.general.selectedLocale].inAppBundle.contentTemplateError;
      nextState.locales[action.payload.locale].inAppBundle.linkTemplate =
        nextState.locales[nextState.general.selectedLocale].inAppBundle.linkTemplate;
      nextState.locales[action.payload.locale].inAppBundle.linkTemplateError =
        nextState.locales[nextState.general.selectedLocale].inAppBundle.linkTemplateError;
      nextState.locales[action.payload.locale].inAppBundle.renderedHTML =
        nextState.locales[nextState.general.selectedLocale].inAppBundle.renderedHTML;
      nextState.locales[action.payload.locale].inAppBundle.renderedHTMLError =
        nextState.locales[nextState.general.selectedLocale].inAppBundle.renderedHTMLError;
      nextState.locales[action.payload.locale].email.contentTemplate =
        nextState.locales[nextState.general.selectedLocale].email.contentTemplate;
      nextState.locales[action.payload.locale].email.contentTemplateError =
        nextState.locales[nextState.general.selectedLocale].email.contentTemplateError;
      nextState.locales[action.payload.locale].email.linkTemplate =
        nextState.locales[nextState.general.selectedLocale].email.linkTemplate;
      nextState.locales[action.payload.locale].email.linkTemplateError =
        nextState.locales[nextState.general.selectedLocale].email.linkTemplateError;
      nextState.locales[action.payload.locale].email.subjectTemplate =
        nextState.locales[nextState.general.selectedLocale].email.subjectTemplate;
      nextState.locales[action.payload.locale].email.subjectTemplateError =
        nextState.locales[nextState.general.selectedLocale].email.subjectTemplateError;
      nextState.locales[action.payload.locale].email.fromNameTemplate =
        nextState.locales[nextState.general.selectedLocale].email.fromNameTemplate;
      nextState.locales[action.payload.locale].email.fromNameTemplateError =
        nextState.locales[nextState.general.selectedLocale].email.fromNameTemplateError;
      nextState.locales[action.payload.locale].email.fromAddressTemplate =
        nextState.locales[nextState.general.selectedLocale].email.fromAddressTemplate;
      nextState.locales[action.payload.locale].email.fromAddressTemplateError =
        nextState.locales[nextState.general.selectedLocale].email.fromAddressTemplateError;
      nextState.locales[action.payload.locale].email.renderedHTML =
        nextState.locales[nextState.general.selectedLocale].email.renderedHTML;
      nextState.locales[action.payload.locale].email.renderedHTMLError =
        nextState.locales[nextState.general.selectedLocale].email.renderedHTMLError;
      break;
    case LocaleActionTypes.SET_LOCALES:
      nextState.locales[DEFAULT_LOCALE] = initialLocaleState();
      action.payload.locales.forEach((locale) => {
        nextState.locales[locale] = initialLocaleState();
      });
      break;
    case LocaleActionTypes.SELECTED_LOCALE_CHANGED:
      nextState.inApp.contentTemplate = nextState.locales[action.payload.locale].inApp.contentTemplate;
      nextState.inApp.contentTemplateError = nextState.locales[action.payload.locale].inApp.contentTemplateError;
      nextState.inApp.linkTemplate = nextState.locales[action.payload.locale].inApp.linkTemplate;
      nextState.inApp.linkTemplateError = nextState.locales[action.payload.locale].inApp.linkTemplateError;
      nextState.inApp.renderedHTML = nextState.locales[action.payload.locale].inApp.renderedHTML;
      nextState.inApp.renderedHTMLError = nextState.locales[action.payload.locale].inApp.renderedHTMLError;
      nextState.inAppBundle.contentTemplate = nextState.locales[action.payload.locale].inAppBundle.contentTemplate;
      nextState.inAppBundle.contentTemplateError =
        nextState.locales[action.payload.locale].inAppBundle.contentTemplateError;
      nextState.inAppBundle.linkTemplate = nextState.locales[action.payload.locale].inAppBundle.linkTemplate;
      nextState.inAppBundle.linkTemplateError = nextState.locales[action.payload.locale].inAppBundle.linkTemplateError;
      nextState.inAppBundle.renderedHTML = nextState.locales[action.payload.locale].inAppBundle.renderedHTML;
      nextState.inAppBundle.renderedHTMLError = nextState.locales[action.payload.locale].inAppBundle.renderedHTMLError;
      nextState.email.contentTemplate = nextState.locales[action.payload.locale].email.contentTemplate;
      nextState.email.contentTemplateError = nextState.locales[action.payload.locale].email.contentTemplateError;
      nextState.email.linkTemplate = nextState.locales[action.payload.locale].email.linkTemplate;
      nextState.email.linkTemplateError = nextState.locales[action.payload.locale].email.linkTemplateError;
      nextState.email.subjectTemplate = nextState.locales[action.payload.locale].email.subjectTemplate;
      nextState.email.subjectTemplateError = nextState.locales[action.payload.locale].email.subjectTemplateError;
      nextState.email.fromNameTemplate = nextState.locales[action.payload.locale].email.fromNameTemplate;
      nextState.email.fromNameTemplateError = nextState.locales[action.payload.locale].email.fromNameTemplateError;
      nextState.email.fromAddressTemplate = nextState.locales[action.payload.locale].email.fromAddressTemplate;
      nextState.email.fromAddressTemplateError =
        nextState.locales[action.payload.locale].email.fromAddressTemplateError;
      nextState.email.renderedHTML = nextState.locales[action.payload.locale].email.renderedHTML;
      nextState.email.renderedHTMLError = nextState.locales[action.payload.locale].email.renderedHTMLError;
      break;
    case InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.contentTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.contentTemplate = action.payload.template;
      break;
    case InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.contentTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.contentTemplateError = action.payload.error;
      break;
    case InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.linkTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.linkTemplate = action.payload.template;
      break;
    case InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.linkTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.linkTemplateError = action.payload.error;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.contentTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.contentTemplate = action.payload.template;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.contentTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.contentTemplateError =
        action.payload.error;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.linkTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.linkTemplate = action.payload.template;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.linkTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.linkTemplateError = action.payload.error;
      break;
    case EmailActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.contentTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.contentTemplate = action.payload.template;
      break;
    case EmailActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.contentTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.contentTemplateError = action.payload.error;
      break;
    case EmailActionTypes.LOAD_EMAIL_LINK_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.linkTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.linkTemplate = action.payload.template;
      break;
    case EmailActionTypes.LOAD_EMAIL_LINK_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.linkTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.linkTemplateError = action.payload.error;
      break;
    case EmailActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.subjectTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.subjectTemplate = action.payload.template;
      break;
    case EmailActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.subjectTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.subjectTemplateError = action.payload.error;
      break;
    case EmailActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromAddressTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromAddressTemplate = action.payload.template;
      break;
    case EmailActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromAddressTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromAddressTemplateError = action.payload.error;
      break;
    case EmailActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE_SUCCESS:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromNameTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromNameTemplate = action.payload.template;
      break;
    case EmailActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE_ERROR:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromNameTemplateLoading = false;
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromNameTemplateError = action.payload.error;
      break;
    case InAppActionTypes.SET_IN_APP_TEMPLATE_CONTENT:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.contentTemplate = action.payload.content;
      break;
    case InAppActionTypes.SET_IN_APP_TEMPLATE_LINK:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inApp.linkTemplate = action.payload.link;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_CONTENT:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.contentTemplate = action.payload.content;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_LINK:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].inAppBundle.linkTemplate = action.payload.link;
      break;
    case EmailActionTypes.SET_EMAIL_CONTENT_TEMPLATE:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.contentTemplate = action.payload.template;
      break;
    case EmailActionTypes.SET_EMAIL_LINK_TEMPLATE:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.linkTemplate = action.payload.template;
      break;
    case EmailActionTypes.SET_EMAIL_SUBJECT_TEMPLATE:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.subjectTemplate = action.payload.template;
      break;
    case EmailActionTypes.SET_EMAIL_FROM_NAME_TEMPLATE:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromNameTemplate = action.payload.template;
      break;
    case EmailActionTypes.SET_EMAIL_FROM_ADDRESS_TEMPLATE:
      nextState.locales[action.payload.locale || DEFAULT_LOCALE].email.fromAddressTemplate = action.payload.template;
      break;
  }
  return nextState;
}
