<div *ngIf="isLoading$ | async" class="loader">
  <mat-spinner diameter="24"></mat-spinner>
</div>
<notification-manage-error *ngIf="showError$ | async" (retry)="loadTypes()">
  Failed to load notifications.
</notification-manage-error>
<div *ngIf="showContent$ | async" class="content mat-elevation-z1">
  <table mat-table [dataSource]="dataSource">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let t">{{ t.name }}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let t">{{ t.description }}</td>
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let t">{{ t.category }}</td>
    </ng-container>

    <!-- App Medium Column -->
    <ng-container matColumnDef="app-medium">
      <th mat-header-cell *matHeaderCellDef>App Enabled</th>
      <td mat-cell *matCellDef="let t">
        <mat-checkbox
          [checked]="t.web ? t.web.enabled : false"
          (change)="toggleMediumDialog($event, t, 0)"
          [disabled]="!t.canEnable(0)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <!-- Email Medium Column -->
    <ng-container matColumnDef="email-medium">
      <th mat-header-cell *matHeaderCellDef>Email Enabled</th>
      <td mat-cell *matCellDef="let t">
        <mat-checkbox
          [checked]="t.email ? t.email.enabled : false"
          (change)="toggleMediumDialog($event, t, 1)"
          [disabled]="!t.canEnable(1)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <!-- Functions Column -->
    <ng-container matColumnDef="functions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let t">
        <div class="actions">
          <a mat-icon-button [routerLink]="editRoute + t.notificationTypeId">
            <mat-icon aria-label="Edit" matTooltip="Edit">edit</mat-icon>
          </a>
          <a mat-icon-button [routerLink]="releaseRoute + t.notificationTypeId">
            <mat-icon aria-label="Release" matTooltip="Release">flight_takeoff</mat-icon>
          </a>
          <button mat-icon-button (click)="deleteDialog($event, t)">
            <mat-icon aria-label="Delete" matTooltip="Delete">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async"></tr>
  </table>
  <mat-paginator
    [pageIndex]="pageIndex$ | async"
    [pageSize]="pageSize$ | async"
    [pageSizeOptions]="[10, 100, 200]"
    (page)="pageUpdate($event)"
    [length]="hasNextPage$ | async"
  ></mat-paginator>
</div>
