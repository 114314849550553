<div class="center">
  <div class="error">
    <mat-icon>sentiment_dissatisfied</mat-icon>
    <ng-content></ng-content>
    <div>
      <button mat-stroked-button color="primary" (click)="retry.emit()">
        Retry
      </button>
    </div>
  </div>
</div>
