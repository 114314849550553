import { Component, Inject, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { PageEvent } from '@angular/material/paginator';
import { DataSource } from '@angular/cdk/collections';

import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';

import {
  NotificationMedium,
  UpdateTypeRequestUpdateNotificationTypeOperationInterface,
} from '@vendasta/notifications-sdk';

import { AdminService, NotificationType } from '../../common/notifications/notifications-admin.service';
import { DeleteTypeDialogComponent } from '../common/delete-type-dialog/delete-type-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';

export class TypeDataSource extends DataSource<NotificationType> {
  constructor(
    private types: Observable<NotificationType[]>,
    private pageSize: Observable<number>,
    private pageIndex: Observable<number>,
  ) {
    super();
  }

  connect(): Observable<NotificationType[]> {
    return combineLatest([this.types, this.pageSize, this.pageIndex]).pipe(
      map(([types, size, index]) => {
        const start = index * size;
        return types.slice(start, start + size);
      }),
    );
  }

  disconnect(): void {
    // unimplemented
  }
}

@Component({
  styleUrls: ['./toggle-medium-dialog.component.scss'],
  templateUrl: 'toggle-medium-dialog.component.html',
})
export class ToggleMediumDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ToggleMediumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ToggleMediumData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

interface ToggleMediumData {
  type: NotificationType;
  enabled: boolean;
  mediumName: string;
  medium: NotificationMedium;
}

interface State {
  isLoading: boolean;
  hasError: boolean;
  types: NotificationType[];
  hasMore: boolean;
  nextCursor: string;
  displayedColumns: string[];
  pageIndex: number;
  pageSize: number;
}

const INITIAL_STATE = {
  isLoading: true,
  hasError: false,
  types: [],
  hasMore: false,
  nextCursor: '',
  displayedColumns: ['name', 'description', 'category', 'app-medium', 'email-medium', 'functions'],
  pageIndex: 0,
  pageSize: 100,
};

@Component({
  selector: 'notification-type-list',
  templateUrl: './type-list.component.html',
  styleUrls: ['./type-list.component.scss'],
})
export class TypeListComponent implements OnInit {
  @Input() editRoute: string;
  @Input() releaseRoute: string;

  private readonly store = new BehaviorSubject<State>({ ...INITIAL_STATE });

  private get state(): State {
    return this.store.getValue();
  }

  private set state(newState: State) {
    this.store.next(newState);
  }

  public readonly isLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.isLoading),
    distinctUntilChanged(),
  );
  public readonly showError$: Observable<boolean> = this.store.pipe(
    map((state) => !state.isLoading && state.hasError),
    distinctUntilChanged(),
  );
  public readonly showContent$: Observable<boolean> = this.store.pipe(
    map((state) => !state.isLoading && !state.hasError),
    distinctUntilChanged(),
  );
  public readonly types$: Observable<NotificationType[]> = this.store.pipe(
    map((state) => state.types),
    distinctUntilChanged(),
  );
  public readonly pageSize$: Observable<number> = this.store.pipe(
    map((state) => state.pageSize),
    distinctUntilChanged(),
  );
  public readonly pageIndex$: Observable<number> = this.store.pipe(
    map((state) => state.pageIndex),
    distinctUntilChanged(),
  );
  public readonly displayedColumns$: Observable<string[]> = this.store.pipe(
    map((state) => state.displayedColumns),
    distinctUntilChanged(),
  );
  public readonly hasNextPage$: Observable<number> = this.store.pipe(
    map((state) => (state.hasMore ? state.types.length + state.pageSize + 1 : state.types.length)),
    distinctUntilChanged(),
  );

  public readonly dataSource = new TypeDataSource(this.types$, this.pageSize$, this.pageIndex$);

  constructor(
    @Inject('AdminService') private notificationsAdminService: AdminService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.loadTypes();
  }

  loadTypes(): void {
    this.state = { ...this.state, isLoading: true, hasError: false };
    this.notificationsAdminService
      .listTypes$(this.state.pageSize)
      .pipe(
        tap(
          (resp) =>
            (this.state = {
              ...this.state,
              isLoading: false,
              types: resp.types,
              hasMore: resp.hasMore,
              nextCursor: resp.nextCursor,
              pageIndex: 0,
            }),
        ),
        catchError(() => this.loadTypesError()),
      )
      .subscribe();
  }

  loadMore(): void {
    this.state = { ...this.state, isLoading: true, hasError: false };
    this.notificationsAdminService
      .listTypes$(this.state.pageSize, this.state.nextCursor)
      .pipe(
        tap(
          (resp) =>
            (this.state = {
              ...this.state,
              isLoading: false,
              types: this.state.types.concat(resp.types),
              hasMore: resp.hasMore,
              nextCursor: resp.nextCursor,
            }),
        ),
        catchError(() => this.loadTypesError()),
      )
      .subscribe();
  }

  loadTypesError(): Observable<never> {
    this.state = { ...this.state, isLoading: false, hasError: true };
    return EMPTY;
  }

  pageUpdate(event: PageEvent): void {
    if (event.pageIndex !== this.state.pageIndex) {
      this.state = { ...this.state, pageIndex: event.pageIndex };
    }
    if (event.pageSize !== this.state.pageSize) {
      this.state = { ...this.state, pageSize: event.pageSize };
      this.loadTypes();
    } else if (event.pageIndex * event.pageSize >= this.state.types.length && this.state.hasMore) {
      this.loadMore();
    }
  }

  toggleMediumDialog(event: MatCheckboxChange, type: NotificationType, medium: NotificationMedium): void {
    const mediumName = medium === 0 ? 'App' : 'Email';
    const dialogRef = this.dialog.open(ToggleMediumDialogComponent, {
      width: '440px',
      data: {
        type: type,
        medium: medium,
        enabled: event.checked,
        mediumName: mediumName,
      },
    });

    dialogRef.afterClosed().subscribe((d: ToggleMediumData) => {
      if (d) {
        const t = new NotificationType(type);
        if (!t.canRelease(medium)) {
          this.snackBar.open(
            "This notification hasn't been configured to deliver notifications via " +
              mediumName +
              '. Please configure this first before enabling delivery.',
            null,
            { duration: 2400 },
          );
          event.source.toggle();
          return;
        }

        const ops: UpdateTypeRequestUpdateNotificationTypeOperationInterface[] = [];
        if (medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB) {
          ops.push({ web: { enabled: !t.web.enabled } });
        } else if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
          ops.push({ email: { enabled: !t.email.enabled } });
        }

        this.notificationsAdminService
          .updateType$(type.notificationTypeId, ops)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              let message = 'An error occurred.';
              if (err.status === 404) {
                message = 'Notification type does not exist.';
              }
              if (err.status === 401 || err.status === 403) {
                message = 'Insufficient permission.';
              }
              event.source.toggle();
              this.snackBar.open(message, null, { duration: 2400 });
              return EMPTY;
            }),
            tap(
              () =>
                (this.state = {
                  ...this.state,
                  types: this.state.types.map((nt) => {
                    if (nt.notificationTypeId === type.notificationTypeId) {
                      nt.toggleMediumEnabled(medium);
                    }
                    return nt;
                  }),
                }),
            ),
          )
          .subscribe();
      } else {
        event.source.toggle();
      }
    });
  }

  deleteDialog(event: MouseEvent, type: NotificationType): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteTypeDialogComponent, {
      width: '440px',
      data: {
        typeId: type.notificationTypeId,
        name: type.name,
      },
    });

    dialogRef.afterClosed().subscribe((typeId) => {
      if (typeId) {
        this.notificationsAdminService
          .deleteType$(typeId)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              let message = 'An error occurred.';
              if (err.status === 404) {
                message = 'Notification does not exist.';
              }
              if (err.status === 401 || err.status === 403) {
                message = 'Insufficient permission.';
              }
              this.snackBar.open(message, null, { duration: 2400 });
              return EMPTY;
            }),
            finalize(() => {
              this.state = {
                ...this.state,
                types: this.state.types.filter((t) => t.notificationTypeId !== type.notificationTypeId),
              };
              this.snackBar.open('Notification successfully deleted', null, { duration: 2400 });
            }),
          )
          .subscribe();
      }
    });
  }
}
