import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Application } from '../../application';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTypeDialogComponent } from '../../../common/delete-type-dialog/delete-type-dialog.component';
import { FeatureFlag } from '@galaxy/partner';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'notification-manage-settings-general',
  styleUrls: ['./general-settings.component.scss'],
  templateUrl: './general-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralSettingsComponent implements OnChanges {
  @Input() new: boolean;

  @Input() identifier: string;
  @Output() identifierChange: EventEmitter<string> = new EventEmitter();

  @Input() name: string;
  @Output() nameChange: EventEmitter<string> = new EventEmitter();

  @Input() description: string;
  @Output() descriptionChange: EventEmitter<string> = new EventEmitter();

  @Input() category: string;
  @Output() categoryChange: EventEmitter<string> = new EventEmitter();

  @Input() applications: Application[];
  @Input() applicationId: string;
  @Output() applicationIdChange: EventEmitter<string> = new EventEmitter();

  @Input() featureFlagId: string;
  @Input() fetchingFeatureFlags: boolean;
  @Input() featureFlags: FeatureFlag[];
  @Input() featureFlagError: any;
  @Output() featureFlagChange: EventEmitter<string> = new EventEmitter();
  @Output() featureFlagSearchTerm: EventEmitter<string> = new EventEmitter<string>();

  @Output() delete: EventEmitter<string> = new EventEmitter();

  featureFlagSearch = '';
  featureFlagTimeout = null;

  featureFlagFormControl = new FormControl<FeatureFlag>(null);

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.featureFlags) {
      const ff = this.featureFlags.find((ff) => ff.id === this.featureFlagId);
      this.featureFlagFormControl.setValue(ff);
    }
  }

  deleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteTypeDialogComponent, {
      width: '440px',
      data: { typeId: this.identifier, name: this.name },
    });

    dialogRef.afterClosed().subscribe((typeId) => {
      if (typeId) {
        this.delete.emit(typeId);
      }
    });
  }

  searchFeatureFlag(search: string): void {
    window.clearTimeout(this.featureFlagTimeout);
    this.featureFlagTimeout = window.setTimeout(() => this.featureFlagSearchTerm.emit(search), 500);
  }

  setSelectedFeatureFlag(event): void {
    this.featureFlagFormControl.setValue(event);
    this.featureFlagChange.emit(event?.id || '');
  }
}
