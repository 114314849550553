import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonaType, AccessRequirementInterface } from '@vendasta/notifications-sdk';

@Component({
  selector: 'notification-manage-settings-permissions',
  templateUrl: './permissions-settings.component.html',
  styleUrls: ['./permissions-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsSettingsComponent {
  PersonaType = PersonaType;

  @Input() personaTypes: PersonaType[];
  @Output() personaTypesChange: EventEmitter<PersonaType[]> = new EventEmitter<PersonaType[]>();

  @Input() resources: AccessRequirementInterface[];
  @Output() addResource: EventEmitter<void> = new EventEmitter();
  @Output() setResource: EventEmitter<{ resourceIndex: number; resourceName: string }> = new EventEmitter();
  @Output() removeResource: EventEmitter<number> = new EventEmitter();
  @Output() addScope: EventEmitter<number> = new EventEmitter();
  @Output() setScope: EventEmitter<{ resourceIndex: number; scopeIndex: number; scopeId: string }> = new EventEmitter();
  @Output() removeScope: EventEmitter<{ resourceIndex: number; scopeIndex: number }> = new EventEmitter();

  enabled(type: PersonaType): boolean {
    if (!this.personaTypes) {
      return false;
    }
    return this.personaTypes.find((t) => t === type) !== undefined;
  }

  update(type: PersonaType, enabled: boolean): void {
    const personas = new Set(this.personaTypes || []);
    if (enabled) {
      personas.add(type);
    } else {
      personas.delete(type);
    }
    this.personaTypesChange.emit(Array.from(personas));
  }
}
