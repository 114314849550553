import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { GalaxyNavComponent } from './nav.component';
export { GalaxyNavComponent };

import { GalaxyNavPanelComponent } from './components/nav-panel/nav-panel.component';
export { GalaxyNavPanelComponent };

import { GalaxyNavItemComponent } from './components/nav-item/nav-item.component';
export { GalaxyNavItemComponent };

import { GalaxyNavItemListComponent } from './components/nav-item-list/nav-item-list.component';
export { GalaxyNavItemListComponent };

import { GalaxyNavItemListItemsDirective } from './directives/nav-item-list-items.directive';
export { GalaxyNavItemListItemsDirective };

import { GalaxyNavHeaderDirective } from './directives/nav-header.directive';
export { GalaxyNavHeaderDirective };

import { GalaxyNavFooterDirective } from './directives/nav-footer.directive';
export { GalaxyNavFooterDirective };

import { GalaxyNavSubheaderDirective } from './directives/nav-subheader.directive';
export { GalaxyNavSubheaderDirective };

import { GalaxyNavItemAccessoryDirective } from './directives/nav-item-accessory.directive';
export { GalaxyNavItemAccessoryDirective };

import { GalaxyNavControlService } from './services/nav-control.service';
export { GalaxyNavControlService };

export const MODULE_IMPORTS = [
  CommonModule,
  MatSidenavModule,
  RouterModule,
  MatIconModule,
  MatRippleModule,
  TranslateModule,
  GalaxyNavItemComponent,
];

export const MODULE_DECLARATIONS = [
  GalaxyNavComponent,
  GalaxyNavPanelComponent,
  GalaxyNavHeaderDirective,
  GalaxyNavFooterDirective,
  GalaxyNavSubheaderDirective,
  GalaxyNavItemAccessoryDirective,
  GalaxyNavItemListItemsDirective,
  GalaxyNavItemListComponent,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  providers: [],
  exports: [
    GalaxyNavComponent,
    GalaxyNavPanelComponent,
    GalaxyNavHeaderDirective,
    GalaxyNavFooterDirective,
    GalaxyNavSubheaderDirective,
    GalaxyNavItemAccessoryDirective,
    GalaxyNavItemListItemsDirective,
    GalaxyNavItemComponent,
    GalaxyNavItemListComponent,
  ],
})
export class GalaxyNavModule {
  // Check if there are multiple instances of GalaxyNavModule, and if so, throw an error.
  // Having multiple instances will break the nav control service.
  constructor(@Optional() @SkipSelf() parentModule?: GalaxyNavModule) {
    if (parentModule) {
      console.error(
        'You have multiple copies of GalaxyNavModule loaded. GalaxyNavModule should only be imported in your app.module.ts',
      );
    }
  }
}
