<div class="container">
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="Identifier"
      autocomplete="off"
      [value]="identifier"
      (input)="identifierChange.emit($event.target.value)"
      [disabled]="!new"
      required
    />
    <mat-hint>An identifier is a kebab case name that uniquely and clearly defines the notification.</mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="Name (This is visible to users)"
      autocomplete="off"
      [value]="name"
      (input)="nameChange.emit($event.target.value)"
      required
    />
    <mat-hint>
      The helps a user to understand what notification they are subscribing to. This appears on the Settings page.
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <textarea
      matInput
      placeholder="Description (This is visible to users)"
      [value]="description"
      (change)="descriptionChange.emit($event.target.value)"
      required
    ></textarea>
    <mat-hint>
      The description gives details about situations where the user can expect to receive the notification.
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="Category (This is visible to users)"
      autocomplete="off"
      [value]="category"
      (input)="categoryChange.emit($event.target.value)"
      required
    />
    <mat-hint>
      The category helps to group similar notifications together. This defines which section the notification will be
      apart of on the Settings page.
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <mat-label>Application</mat-label>
    <mat-select [value]="applicationId" (selectionChange)="applicationIdChange.emit($event.value)">
      <mat-option *ngFor="let application of applications" [value]="application.id">
        {{ application.name }} ({{ application.id }})
      </mat-option>
    </mat-select>
    <mat-hint>
      This is the application your notification will send the user to. By setting, Notifications will be able to
      whitelabel the domain when using the link template parameter. If this is set, you should provide a relative path
      for the link template options. i.e. order-management/{{ '{' }}{{ '{' }}.account_group_id&#125;&#125;
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <mat-label>Feature Flag</mat-label>
    <mat-select
      [value]="featureFlagId"
      [formControl]="featureFlagFormControl"
      (selectionChange)="setSelectedFeatureFlag($event.value)"
    >
      <mat-select-trigger>
        {{ featureFlagFormControl?.value?.name }} ({{ featureFlagFormControl?.value?.id }})
        <ng-container *ngIf="featureFlagFormControl?.value?.archived">
          <glxy-badge [size]="'small'" [color]="'grey'">archived</glxy-badge>
        </ng-container>
      </mat-select-trigger>
      <mat-form-field>
        <div class="search">
          <mat-icon>search</mat-icon>
          <input matInput (keyup)="searchFeatureFlag(featureFlagSearch)" [(ngModel)]="featureFlagSearch" />
        </div>
      </mat-form-field>
      <div *ngIf="fetchingFeatureFlags">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <mat-option [value]="">No feature flag</mat-option>
      <mat-option *ngFor="let featureFlag of featureFlags" [value]="featureFlag" [disabled]="featureFlag.archived">
        {{ featureFlag.name }} ({{ featureFlag.id }})
        <ng-container *ngIf="featureFlag.archived">
          <glxy-badge [size]="'small'" [color]="'grey'">archived</glxy-badge>
        </ng-container>
      </mat-option>
    </mat-select>
    <mat-hint>
      Select a feature flag that corresponds to this notification. It will restrict which users can see and receive this
      type of notification.
    </mat-hint>
  </mat-form-field>
  <div class="delete">
    <mat-divider></mat-divider>
    <button mat-stroked-button color="warn" (click)="deleteDialog()" [disabled]="new">Delete Notification</button>
  </div>
</div>
