export enum InAppActionTypes {
  LOAD_IN_APP_CONTENT_TEMPLATE = 'load in app content template',
  LOAD_IN_APP_CONTENT_TEMPLATE_SUCCESS = 'load in app content template success',
  LOAD_IN_APP_CONTENT_TEMPLATE_ERROR = 'load in app content template error',
  LOAD_IN_APP_LINK_TEMPLATE = 'load in app link template',
  LOAD_IN_APP_LINK_TEMPLATE_SUCCESS = 'load in app link template success',
  LOAD_IN_APP_LINK_TEMPLATE_ERROR = 'load in app link template error',
  SET_IN_APP_TEMPLATE_ENABLED = 'set in app template enabled',
  SET_IN_APP_TEMPLATE_LINK = 'set in app template link',
  SET_IN_APP_TEMPLATE_SAMPLE = 'set in app template sample',
  SET_IN_APP_TEMPLATE_CONTENT = 'set in app template content',
  RENDER_IN_APP_TEMPLATE = 'render in app template',
  RENDER_IN_APP_TEMPLATE_SUCCESS = 'render in app template success',
  RENDER_IN_APP_TEMPLATE_ERROR = 'render in app template error',
  CONVERT_IN_APP_CONTENT_TO_TEMPLATE = 'convert in app content to template',
  CONVERT_IN_APP_LINK_TO_TEMPLATE = 'convert in app link to template',
}

export class LoadInAppContent {
  readonly type = InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE;
}

export class LoadInAppContentSuccess {
  readonly type = InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadInAppContentError {
  readonly type = InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE_ERROR;
  constructor(readonly payload: { error: any; locale?: string }) {}
}

export class LoadInAppLink {
  readonly type = InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE;
}

export class LoadInAppLinkSuccess {
  readonly type = InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadInAppLinkError {
  readonly type = InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE_ERROR;
  constructor(readonly payload: { error: any; locale?: string }) {}
}

export class SetInAppEnabled {
  readonly type = InAppActionTypes.SET_IN_APP_TEMPLATE_ENABLED;
  constructor(readonly payload: { enabled: boolean; locale?: string }) {}
}

export class SetInAppLink {
  readonly type = InAppActionTypes.SET_IN_APP_TEMPLATE_LINK;
  constructor(readonly payload: { link: string; locale?: string }) {}
}

export class SetInAppContent {
  readonly type = InAppActionTypes.SET_IN_APP_TEMPLATE_CONTENT;
  constructor(readonly payload: { content: string; locale?: string }) {}
}

export class SetInAppSample {
  readonly type = InAppActionTypes.SET_IN_APP_TEMPLATE_SAMPLE;
  constructor(readonly payload: { sample: string; sampleObj: object; edited: boolean }) {}
}

export class RenderInAppTemplate {
  readonly type = InAppActionTypes.RENDER_IN_APP_TEMPLATE;
}

export class RenderInAppTemplateSuccess {
  readonly type = InAppActionTypes.RENDER_IN_APP_TEMPLATE_SUCCESS;
  constructor(readonly payload: { html: string }) {}
}

export class RenderInAppTemplateError {
  readonly type = InAppActionTypes.RENDER_IN_APP_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string }) {}
}

export class ConvertInAppContentToTemplate {
  readonly type = InAppActionTypes.CONVERT_IN_APP_CONTENT_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export class ConvertInAppLinkToTemplate {
  readonly type = InAppActionTypes.CONVERT_IN_APP_LINK_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export type InAppActions =
  | LoadInAppContent
  | LoadInAppContentSuccess
  | LoadInAppContentError
  | LoadInAppLink
  | LoadInAppLinkSuccess
  | LoadInAppLinkError
  | SetInAppEnabled
  | SetInAppLink
  | SetInAppContent
  | SetInAppSample
  | RenderInAppTemplate
  | RenderInAppTemplateSuccess
  | RenderInAppTemplateError
  | ConvertInAppContentToTemplate
  | ConvertInAppLinkToTemplate;
