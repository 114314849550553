<glxy-page>
  <glxy-page-toolbar>
    <glxy-page-nav>
      <!--remove lingering query params from any errors on navigation back to the main app-->
      <a routerLink="/" [queryParams]="{}" mat-stroked-button>
        <mat-icon>arrow_back</mat-icon>
        <span>Admin Center</span>
      </a>
    </glxy-page-nav>
    <glxy-page-title>OAuth2 Access Token Playground</glxy-page-title>
  </glxy-page-toolbar>

  <glxy-page-wrapper>
    <mat-card appearance="outlined">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card-content>
          <mat-form-field class="scope-form">
            <input placeholder="Scope IDs" formControlName="scopes" matInput />
            <mat-hint>Space-separated scope ids</mat-hint>
          </mat-form-field>
        </mat-card-content>

        <mat-card-actions>
          <button mat-flat-button color="primary">Submit</button>
        </mat-card-actions>
      </form>

      <mat-card-content>
        <div class="mat-headline-6">
          Access Token
          <ng-container *ngIf="accessToken$ | async as accessToken">
            <button
              mat-icon-button
              (click)="copyToken(accessToken)"
              aria-label="Click to copy the access token to your clipboard"
            >
              <mat-icon>file_copy</mat-icon>
            </button>
          </ng-container>
        </div>

        <mat-form-field class="access-token-field">
          <input
            matInput
            placeholder="Generated access token will appear here."
            [value]="accessToken$ | async"
            [readonly]="true"
          />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </glxy-page-wrapper>
</glxy-page>
