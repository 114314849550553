<h2 mat-dialog-title>Delete Notification</h2>
<mat-dialog-content>
  Are you sure you want to delete
  <b>{{ data.name }}</b>
  ?
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>No</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="data.typeId" color="warn">Yes</button>
</mat-dialog-actions>
