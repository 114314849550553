import { Input, Component, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'notification-manage-template-in-app',
  templateUrl: './in-app-template-settings.component.html',
  styleUrls: ['./in-app-template-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InAppTemplateSettingsComponent {
  @Input() bundle = false;

  @Input() enabled = false;
  @Output() enabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() link: string;
  @Output() linkChange: EventEmitter<string> = new EventEmitter();

  @Input() window = 0;
  @Output() windowChange: EventEmitter<number> = new EventEmitter();
}
