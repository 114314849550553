import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ATLAS_CONFIG_TOKEN } from '@galaxy/atlas';
import { CoreModule } from '@galaxy/core';
import { LexiconModule } from '@galaxy/lexicon';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';
import { GalaxyDefaultProviderOverrides } from '@vendasta/galaxy/provider-default-overrides';
import { OAuthModule } from 'angular-oauth2-oidc';
import { of } from 'rxjs';
import En from '../assets/i18n/en_devel.json';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AuthedAppModule } from './authed-app.module';
import { LogoutModule } from './pages/logout';
import { Oauth2PlaygroundModule } from './pages/oauth2-playground/oauth2-playground.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    Oauth2PlaygroundModule,
    LogoutModule,
    RouterModule,
    AuthedAppModule,
    GalaxyI18NModule,
    TranslateModule,
    LexiconModule.forRoot({
      componentName: 'admin-center/vendasta-center-client',
      baseTranslation: En,
    }),
    OAuthModule.forRoot(),
  ],
  providers: [
    ...GalaxyDefaultProviderOverrides,
    {
      provide: ATLAS_CONFIG_TOKEN,
      useValue: of({
        partnerId: 'VA',
        serviceProviderId: 'admin-client',
      }),
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
