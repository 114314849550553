import { BreakpointObserver } from '@angular/cdk/layout';
import {
  booleanAttribute,
  Component,
  DestroyRef,
  effect,
  HostBinding,
  inject,
  input,
  Input,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GalaxyNavControlService } from './services/nav-control.service';

export const NAV_OPEN_CLOSE_ANIMATION_DURATION = 500; //Approximate animation time (in ms) required for the sidenav to open in close

@Component({
  selector: 'glxy-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GalaxyNavComponent implements OnInit {
  private router = inject(Router);
  protected navControl = inject(GalaxyNavControlService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly destroyRef = inject(DestroyRef);

  @HostBinding('class') class = 'glxy-nav';

  /** The name of the app the nav is in. Used to create unique localStorage key for local development **/
  @Input() appName = 'default';

  /** The gap between the top of the nav and the top of the viewport **/
  @Input() fixedTopGap = 0;

  /** Whether to automatically resize the nav panel whenever the size of any of its drawers changes **/
  @Input({ transform: booleanAttribute }) navAutoSize = false;

  /** The nav will automatically hide at this viewport width and below **/
  @Input() autoHideNavMaxWidth = 1023;

  @HostBinding('class.auto-hide-menu')
  autoHideNav = false;

  /** The nav is considered mobile at this viewport width and below **/
  @Input() mobileMaxWidth = 768;

  @HostBinding('class.nav-is-mobile')
  navIsMobile = false;

  usePushOnMobile = input(false, { transform: booleanAttribute });

  protected readonly navMode = signal<'over' | 'side' | 'push'>('side');
  navIsClosedForThisPage = false;
  navModeTimeout = 0;

  private localStorageKey?: string;
  private storedPanelState?: string;

  constructor() {
    effect(() => {
      const isNavOpen = this.navControl.isOpen();
      if (!this.autoHideNav && !this.navIsClosedForThisPage) {
        // only update localstorage on desktop
        if (this.localStorageKey) localStorage.setItem(this.localStorageKey, String(isNavOpen));
      }
    });
  }

  ngOnInit(): void {
    // setup the LocalStorage key to be unique per app (if set)
    this.localStorageKey = `glxyNavState-${this.appName}`;

    // Watch if viewport is tablet sized or smaller
    this.breakpointObserver
      .observe('(max-width: ' + this.autoHideNavMaxWidth + 'px)')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        this.autoHideNav = resp.matches;
        // initNavState() fires on load and on viewport change
        // if it changes the `autoHideNavMaxWidth` px value
        this.initNavState();
      });

    // Watch if viewport is mobile sized or smaller
    this.breakpointObserver
      .observe('(max-width: ' + this.mobileMaxWidth + 'px)')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        this.navIsMobile = resp.matches;
      });

    // Listen to route changes, so we can close the menu on mobile
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.navigationHasHappened();
      });
  }

  toggle(): void {
    this.navControl.toggle();
  }

  open(): void {
    this.navControl.open();
  }

  close(): void {
    this.navControl.close();
  }

  initNavState(): void {
    // set LocalStorage value
    this.storedPanelState = this.localStorageKey
      ? (localStorage.getItem(this.localStorageKey) ?? undefined)
      : undefined;

    const shouldHideNav = this.autoHideNav || this.storedPanelState === 'false' || this.navIsClosedForThisPage;

    if (shouldHideNav) {
      // collapse the nav if mobile, or hidden by user
      this.navControl.close();
    } else {
      this.navControl.open();
    }

    // clear any running timeouts first
    window.clearTimeout(this.navModeTimeout);

    if (this.autoHideNav || this.navIsClosedForThisPage) {
      // overlay the menu when on mobile,
      // or if navIsClosedForThisPage is set
      // Set the mode to "over" after any sort of animation is done
      this.navModeTimeout = window.setTimeout(() => {
        this.navMode.set(this.usePushOnMobile() ? 'push' : 'over');
      }, 500);
    } else {
      this.navMode.set('side');
    }
  }

  closeForThisPage(): void {
    // If a developer needs to close the nav temporarily for a
    // full screen page, then we close the menu, but don't save the
    // closed state. We set "menuIsTemporarilyClosed" to true so
    // we remember to open the nav
    this.navIsClosedForThisPage = true;
    this.navControl.close();
    // Set the mode to "over" after any sort of animation is done
    window.setTimeout(() => {
      this.navMode.set(this.usePushOnMobile() ? 'push' : 'over');
    }, 500);
  }

  undoCloseForThisPage(): void {
    // only open and change navMode if not mobile
    if (this.autoHideNav === false) {
      this.navMode.set('side');
      this.navControl.open();
    }
    this.navIsClosedForThisPage = false;
  }

  private navigationHasHappened(): void {
    // Close the nav when the user clicks a menu link
    // and is on a phone
    if (this.autoHideNav) {
      this.navControl.close();
    }
    // Open the nav if user navigates away from the page that
    // temporarily sets the nav to close
    else if (this.navIsClosedForThisPage) {
      this.undoCloseForThisPage();
    }
  }
}
