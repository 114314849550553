import { SchemaPropertyInterface, PropertyType, SchemaInterface } from '@vendasta/event-broker';
import { Hydrator, NotificationMedium } from '@vendasta/notifications-sdk';

const accountGroupSample = {
  _account_group_name: '',
  _account_group_address: '',
  _account_group_city: '',
  _account_group_state: '',
  _account_group_country: '',
  _account_group_zip: '',
};

const salespersonSample = {
  _salesperson_name: '',
  _salesperson_email: '',
};

const appAddonSample = {
  _product_name: '',
};

const productsSample = {
  _products: [
    {
      product_id: '',
      addon_id: '',
      edition_id: '',
      name: '',
    },
  ],
};

const packageSample = {
  _package_name: '',
};

const packagesSample = {
  _packages: [
    {
      package_id: '',
      name: '',
    },
  ],
};

const partnerBrandingSample = {
  _branding: {
    name: '',
    primary_color: '',
    assets: {
      favicon_url: {
        insecure: '',
        secure: '',
      },
      shortcut_icon_url: {
        insecure: '',
        secure: '',
      },
      logo_url: {
        insecure: '',
        secure: '',
      },
    },
    apps: [
      {
        name: 'My Reputation',
        identifier: 'RM',
      },
    ],
  },
};

const digitalAgentSample = {
  _digital_agent_name: '',
};

const userSample = {
  _user: {
    first_name: '',
    last_name: '',
  },
};

const usersSample = {
  _users: [
    {
      first_name: '',
      last_name: '',
    },
  ],
};

// Indicates if the event should have access to additional account group data based on the event's schema.
// The necessary parameters are defined here:
// https://github.com/vendasta/notifications/blob/master/DEVELOPER_GUIDE.md#account-group
export function additionalAccountGroupDataAvailable(schema: SchemaInterface): boolean {
  return !!schema.properties.find((prop) => prop.name === 'account_group_id');
}

// Indicates if the event should have access to additional salesperson data based on the event's schema.
// The necessary parameters are defined here:
// https://github.com/vendasta/notifications/blob/master/DEVELOPER_GUIDE.md#salesperson
export function additionalSalespersonDataAvailable(schema: SchemaInterface): boolean {
  return !!schema.properties.find((prop) => prop.name === 'salesperson_id');
}

// Indicates if the event should have access to additional product data based on the event's schema.
// The necessary parameters are defined here:
// https://github.com/vendasta/notifications/blob/master/DEVELOPER_GUIDE.md#product
export function additionalProductDataAvailable(schema: SchemaInterface): boolean {
  return !!schema.properties.find(
    (prop) =>
      prop.name === 'app_id' ||
      prop.name === 'addon_id' ||
      prop.name === 'product_ids' ||
      prop.name === 'package_id' ||
      prop.name === 'package_ids',
  );
}

// Indicates if the event should have access to additional partner branding data based on the event's schema.
// The necessary parameters are defined here:
// https://github.com/vendasta/notifications/blob/master/DEVELOPER_GUIDE.md#partner-branding
export function additionalPartnerBrandingDataAvailable(schema: SchemaInterface): boolean {
  return !!schema.properties.find((prop) => prop.name === 'account_group_id' || prop.name === 'partner_id');
}

// Indicates if the event should have access to additional user data based on the event's schema.
// The necessary parameters are defined here:
// https://github.com/vendasta/notifications/blob/master/DEVELOPER_GUIDE.md#user
export function additionalUserDataAvailable(schema: SchemaInterface): boolean {
  return !!schema.properties.find((prop) => prop.name === 'user_id' || prop.name === 'user_ids');
}

// Indicates if the event should have access to additional digital agent data based on the event's schema.
// The necessary parameters are defined here:
// https://github.com/vendasta/notifications/blob/master/DEVELOPER_GUIDE.md#digital-agent
export function additionalDigitalAgentDataAvailable(schema: SchemaInterface): boolean {
  return !!schema.properties.find((prop) => prop.name === 'digital_agent_id');
}

// Attach any missing properties from a schema to an object with a default value.
function attachEventProps(obj: object, props: SchemaPropertyInterface[]): void {
  props.forEach((prop) => {
    if (!obj[prop.name]) {
      let val: any;
      switch (prop.type) {
        case PropertyType.PROPERTY_TYPE_BOOL:
          val = false;
          break;
        case PropertyType.PROPERTY_TYPE_BYTES:
          val = null;
          break;
        case PropertyType.PROPERTY_TYPE_DOUBLE:
          val = 0.0;
          break;
        case PropertyType.PROPERTY_TYPE_GEOPOINT:
          val = { latitude: 0.0, longitude: 0.0 };
          break;
        case PropertyType.PROPERTY_TYPE_INT64:
          val = 0;
          break;
        case PropertyType.PROPERTY_TYPE_STRING:
          val = '';
          break;
        case PropertyType.PROPERTY_TYPE_STRUCT:
          val = {};
          break;
        case PropertyType.PROPERTY_TYPE_TIMESTAMP:
          val = '2017-01-15T01:30:15.01Z';
          break;
        default:
          val = '';
      }
      obj[prop.name] = val;
    } else if (obj[prop.name] instanceof Object && !!prop.properties && prop.properties.length > 0) {
      // Property already exists, if it's an object, try adding any nested properties.
      attachEventProps(obj[prop.name], prop.properties);
    }
  });
}

function attachProp(obj: object, attach: object): void {
  Object.keys(attach).forEach((key) => {
    if (!obj[key]) {
      obj[key] = attach[key];
    } else if (obj[key] instanceof Object && attach[key] instanceof Object) {
      attachProp(obj[key], attach[key]);
    }
  });
}

function attachSampleProps(obj: object, schema: SchemaInterface, hydrators: Hydrator[]): void {
  if (hydrators.find((h) => h.accountGroup === true)) {
    attachProp(obj, accountGroupSample);
  }
  if (hydrators.find((h) => h.salesperson === true)) {
    attachProp(obj, salespersonSample);
  }
  if (hydrators.find((h) => h.partnerBranding === true)) {
    attachProp(obj, partnerBrandingSample);
  }
  if (hydrators.find((h) => h.digitalAgent === true)) {
    attachProp(obj, digitalAgentSample);
  }
  if (hydrators.find((h) => h.user === true)) {
    // User is more complicated because different values on the schema yield different results.
    // see: https://github.com/vendasta/notifications/blob/master/GUIDE.md#user
    const schemaProps = schema.properties.map((prop) => prop.name);
    if (schemaProps.includes('user_id')) {
      attachProp(obj, userSample);
    }
    if (schemaProps.includes('user_ids')) {
      attachProp(obj, usersSample);
    }
    attachProp(obj, userSample);
  }
  if (hydrators.find((h) => h.product === true)) {
    // Product is more complicated because different values on the schema yield different results.
    // see: https://github.com/vendasta/notifications/blob/master/GUIDE.md#product
    const schemaProps = schema.properties.map((prop) => prop.name);
    if (schemaProps.includes('app_id') || schemaProps.includes('addon_id')) {
      attachProp(obj, appAddonSample);
    }
    if (schemaProps.includes('product_ids')) {
      attachProp(obj, productsSample);
    }
    if (schemaProps.includes('package_id')) {
      attachProp(obj, packageSample);
    }
    if (schemaProps.includes('package_ids')) {
      attachProp(obj, packagesSample);
    }
  }
}

function attachMediumSpecificProps(obj: object, medium: NotificationMedium): void {
  if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
    attachProp(obj, {
      _link: '',
      _unsubscribe_url: '',
    });
  }
}

export function populateSampleData(
  sampleData: object,
  schema: SchemaInterface,
  hydrators: Hydrator[],
  medium: NotificationMedium,
  bundle: boolean,
): object {
  const out = {};
  attachProp(out, sampleData);
  if (bundle) {
    if (!sampleData['_notifications']) {
      // Start _notifications with two empty objects. They will be filled out with data below. The reason it's two is
      // because a bundle will always be composed of at least two notifications.
      attachProp(out, { _notifications: [{}, {}] });
    }
    out['_notifications'].map((notification) => {
      attachMediumSpecificProps(notification, medium);
      attachEventProps(notification, schema.properties);
      attachSampleProps(notification, schema, hydrators || []);
    });
    return out;
  }
  attachMediumSpecificProps(out, medium);
  attachEventProps(out, schema.properties);
  attachSampleProps(out, schema, hydrators || []);
  return out;
}
