import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Hydrator } from '@vendasta/notifications-sdk';
import { EventTypeInterface } from '@vendasta/event-broker';

@Component({
  selector: 'notification-manage-settings-event',
  styleUrls: ['./event-settings.component.scss'],
  templateUrl: './event-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSettingsComponent {
  @Input() event: EventTypeInterface;
  @Input() loading: boolean;
  @Input() error: any;
  @Input() hydrators: Hydrator[] = [];

  @Input() accountGroupDataAvailable = false;
  @Input() salespersonDataAvailable = false;
  @Input() productDataAvailable = false;
  @Input() partnerBrandingDataAvailable = false;
  @Input() digitalAgentDataAvailable = false;
  @Input() userDataAvailable = false;

  @Output() accountGroupHydratorChange: EventEmitter<boolean> = new EventEmitter();
  @Output() salespersonHydratorChange: EventEmitter<boolean> = new EventEmitter();
  @Output() productHydratorChange: EventEmitter<boolean> = new EventEmitter();
  @Output() partnerBrandingHydratorChange: EventEmitter<boolean> = new EventEmitter();
  @Output() digitalAgentHydratorChange: EventEmitter<boolean> = new EventEmitter();
  @Output() userHydratorChange: EventEmitter<boolean> = new EventEmitter();

  @Output() reload: EventEmitter<void> = new EventEmitter();

  hydratorEnabled(name: string): boolean {
    const hydrator = this.hydrators.find((h) => h[name] !== undefined);
    if (hydrator) {
      return hydrator[name];
    }
    return false;
  }
}
