<h1 mat-dialog-title>Create notification without event</h1>
<mat-dialog-content>
  <glxy-alert type="warning" [extendedBorder]="false">
    Notifications without a linked event need to be triggered by an external
    source

    <glxy-alert-extended>
      New events automatically send notifications to subscribed users and are
      generally preferred. Skipping an event should only be considered when the
      notification is intended for a small amount of users.
    </glxy-alert-extended>
  </glxy-alert>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" cdkFocusInitial (click)="confirmed()">
    Create
  </button>
</mat-dialog-actions>
