import { State } from '../editor.reducers';
import { InAppActionTypes } from '../actions/in-app.actions';
import { EditorActions } from '../editor.actions';

export interface InAppState {
  contentTemplateLoading: boolean;
  contentTemplateError: any;
  contentTemplate: string;

  linkTemplateLoading: boolean;
  linkTemplateError: any;
  linkTemplate: string;

  renderedHTML: string;
  renderedHTMLLoading: boolean;
  renderedHTMLError: string;

  sample: string;
}

export function initialInAppState(): InAppState {
  return {
    contentTemplateLoading: false,
    contentTemplateError: null,
    contentTemplate: '',

    linkTemplateLoading: false,
    linkTemplateError: null,
    linkTemplate: '',

    renderedHTML: '',
    renderedHTMLLoading: false,
    renderedHTMLError: null,

    sample: '{}',
  };
}

export function inAppReducer(nextState: State, action: EditorActions): State {
  switch (action.type) {
    case InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE:
      nextState.inApp.contentTemplate = '';
      nextState.inApp.contentTemplateLoading = true;
      nextState.inApp.contentTemplateError = null;
      break;
    case InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE_SUCCESS:
      nextState.inApp.contentTemplateLoading = false;
      nextState.inApp.contentTemplate = action.payload.template;
      break;
    case InAppActionTypes.LOAD_IN_APP_CONTENT_TEMPLATE_ERROR:
      nextState.inApp.contentTemplateLoading = false;
      nextState.inApp.contentTemplateError = action.payload.error;
      break;
    case InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE:
      nextState.inApp.linkTemplate = '';
      nextState.inApp.linkTemplateLoading = true;
      nextState.inApp.linkTemplateError = null;
      break;
    case InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE_SUCCESS:
      nextState.inApp.linkTemplateLoading = false;
      nextState.inApp.linkTemplate = action.payload.template;
      break;
    case InAppActionTypes.LOAD_IN_APP_LINK_TEMPLATE_ERROR:
      nextState.inApp.linkTemplateLoading = false;
      nextState.inApp.linkTemplateError = action.payload.error;
      break;
    case InAppActionTypes.SET_IN_APP_TEMPLATE_ENABLED:
      if (nextState.general.type.web.enabled !== action.payload.enabled) {
        nextState.general.edited = true;
      }
      nextState.general.type.web.enabled = action.payload.enabled;
      break;
    case InAppActionTypes.SET_IN_APP_TEMPLATE_LINK:
      if (nextState.inApp.linkTemplate !== action.payload.link) {
        nextState.general.edited = true;
      }
      nextState.inApp.linkTemplate = action.payload.link;
      break;
    case InAppActionTypes.SET_IN_APP_TEMPLATE_SAMPLE:
      if (nextState.inApp.sample !== action.payload.sample) {
        nextState.general.edited = action.payload.edited;
      }
      nextState.inApp.renderedHTMLLoading = true;
      nextState.inApp.renderedHTMLError = null;
      nextState.inApp.sample = action.payload.sample;
      nextState.general.type.web.sampleData = action.payload.sampleObj;
      break;
    case InAppActionTypes.SET_IN_APP_TEMPLATE_CONTENT:
      if (nextState.inApp.contentTemplate !== action.payload.content) {
        nextState.general.edited = true;
      }
      nextState.inApp.contentTemplate = action.payload.content;
      break;
    case InAppActionTypes.RENDER_IN_APP_TEMPLATE:
      nextState.inApp.renderedHTMLLoading = true;
      nextState.inApp.renderedHTMLError = null;
      break;
    case InAppActionTypes.RENDER_IN_APP_TEMPLATE_SUCCESS:
      nextState.inApp.renderedHTMLLoading = false;
      nextState.inApp.renderedHTML = action.payload.html;
      break;
    case InAppActionTypes.RENDER_IN_APP_TEMPLATE_ERROR:
      nextState.inApp.renderedHTMLLoading = false;
      nextState.inApp.renderedHTMLError = action.payload.error;
      break;
    case InAppActionTypes.CONVERT_IN_APP_CONTENT_TO_TEMPLATE:
      nextState.general.type.web.contentTemplate.id = action.payload.templateId;
      break;
    case InAppActionTypes.CONVERT_IN_APP_LINK_TO_TEMPLATE:
      nextState.general.type.web.linkTemplate.id = action.payload.templateId;
      break;
  }
  return nextState;
}
