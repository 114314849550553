import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Oauth2PlaygroundComponent } from './oauth2-playground.component';
import { UIKitModule } from '@vendasta/uikit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OAuthModule } from 'angular-oauth2-oidc';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { OAuth2Module } from '@vendasta/oauth2';
import { PlaygroundAuthService } from './playground-auth.service';

const MaterialModules = [
  MatCardModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatIconModule,
];

@NgModule({
  providers: [PlaygroundAuthService],
  declarations: [Oauth2PlaygroundComponent],
  imports: [
    CommonModule,
    MaterialModules,
    UIKitModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OAuthModule,
    OAuth2Module,
    GalaxyPageModule,
  ],
})
export class Oauth2PlaygroundModule {}
