import { InAppActions, InAppActionTypes } from './actions/in-app.actions';
import { GeneralActions, GeneralActionTypes } from './actions/general.actions';
import { EmailActions, EmailActionTypes } from './actions/email.actions';
import { InAppBundleActions, InAppBundleActionTypes } from './actions/in-app-bundle.actions';
import { FeatureFlagActions } from './actions/feature-flag.actions';
import { LocaleActions, LocaleActionTypes } from './actions/locale.actions';

export const ActionTypes = {
  ...GeneralActionTypes,
  ...EmailActionTypes,
  ...InAppActionTypes,
  ...InAppBundleActionTypes,
  ...LocaleActionTypes,
};

export type EditorActions =
  | GeneralActions
  | EmailActions
  | InAppActions
  | InAppBundleActions
  | FeatureFlagActions
  | LocaleActions;
