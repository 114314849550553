import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { devServer, environment } from './globals';

if (!devServer) {
  enableProdMode();
}

if (environment === 'prod' || environment === 'production') {
  if (location.protocol === 'http:') {
    window.location.href = location.href.replace('http', 'https');
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
