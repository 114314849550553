<notification-manage-event-selector
  *ngIf="(new$ | async) && !typeId && (event$ | async) === null"
  [events]="events$ | async"
  [loading]="eventsLoading$ | async"
  [error]="eventsError$ | async"
  [hasMore]="eventsHasMore$ | async"
  (cancel)="cancel()"
  (loadMore)="loadMoreEvents()"
  (selected)="selectEvent($event)"
  (searchTerm)="load($event)"
  (skip)="skipEventSelection()"
></notification-manage-event-selector>
<ng-container *ngIf="typeId || (event$ | async)">
  <div class="center" *ngIf="loading$ | async">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  <notification-manage-error *ngIf="error$ | async" (retry)="load()">
    Unable to load the notification.
  </notification-manage-error>
  <div class="container" *ngIf="type$ | async as type">
    <mat-toolbar>
      {{ type.name }}
      <div class="spacer"></div>
      <div class="actions">
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <!-- need a wrapper because you can't have a tooltip on a disabled button -->
        <div
          [matTooltipDisabled]="(releaseDisabled$ | async) === false"
          matTooltip="Your notification must be saved with an enabled medium template before releasing."
        >
          <button
            mat-stroked-button
            color="accent"
            (click)="release(type.notificationTypeId)"
            [disabled]="releaseDisabled$ | async"
          >
            Release
          </button>
        </div>
        <button mat-stroked-button color="primary" (click)="save()">
          Save
        </button>
      </div>
    </mat-toolbar>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>General</mat-panel-title>
          <mat-panel-description>
            General notification settings.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <notification-manage-settings-general
          [new]="new$ | async"
          [identifier]="type.notificationTypeId"
          (identifierChange)="setIdentifier($event)"
          [name]="type.name"
          (nameChange)="setName($event)"
          [description]="type.description"
          (descriptionChange)="setDescription($event)"
          [category]="type.category"
          (categoryChange)="setCategory($event)"
          [applications]="applications$ | async"
          [applicationId]="type.domainId"
          (applicationIdChange)="setDomainId($event)"
          [featureFlagId]="featureFlagId$ | async"
          [fetchingFeatureFlags]="fetchingFeatureFlags$ | async"
          [featureFlagError]="featureFlagsError$ | async"
          [featureFlags]="featureFlags$ | async"
          (featureFlagChange)="setFeatureFlagId($event)"
          (featureFlagSearchTerm)="loadFeatureFlags($event)"
          (delete)="delete($event)"
        ></notification-manage-settings-general>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Permissions</mat-panel-title>
          <mat-panel-description>
            Setup rules for who has access to this notification.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <notification-manage-settings-permissions
          [personaTypes]="type.personaAccessRequirements"
          (personaTypesChange)="setPersonaTypes($event)"
          [resources]="type.accessRequirements"
          (addResource)="addResource()"
          (setResource)="setResource($event.resourceIndex, $event.resourceName)"
          (removeResource)="removeResource($event)"
          (addScope)="addScope($event)"
          (setScope)="
            setScope($event.resourceIndex, $event.scopeIndex, $event.scopeId)
          "
          (removeScope)="removeScope($event.resourceIndex, $event.scopeIndex)"
        ></notification-manage-settings-permissions>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Event</mat-panel-title>
          <mat-panel-description>
            Manage the settings for the events used to trigger this
            notification.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <notification-manage-settings-event
          [event]="event$ | async"
          [loading]="eventLoading$ | async"
          [error]="eventError$ | async"
          [hydrators]="type.getHydrators()"
          [accountGroupDataAvailable]="accountGroupDataAvailable$ | async"
          [salespersonDataAvailable]="salespersonDataAvailable$ | async"
          [productDataAvailable]="productDataAvailable$ | async"
          [partnerBrandingDataAvailable]="partnerBrandingDataAvailable$ | async"
          [digitalAgentDataAvailable]="digitalAgentDataAvailable$ | async"
          [userDataAvailable]="userDataAvailable$ | async"
          (accountGroupHydratorChange)="setAccountGroupHydrator($event)"
          (salespersonHydratorChange)="setSalespersonHydrator($event)"
          (productHydratorChange)="setProductHydrator($event)"
          (partnerBrandingHydratorChange)="setPartnerBrandingHydrator($event)"
          (digitalAgentHydratorChange)="setDigitalAgentHydrator($event)"
          (userHydratorChange)="setUserHydrator($event)"
          (reload)="reloadEvent()"
        ></notification-manage-settings-event>
      </mat-expansion-panel>
      <mat-expansion-panel
        (opened)="templatePanelOpen = true"
        (closed)="templatePanelOpen = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>Templates</mat-panel-title>
          <mat-panel-description>
            Customize the templates for each type of notification a user can
            receive.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="template-container">
          <ng-container *ngIf="templatePanelOpen">
            <mat-tab-group
              [selectedIndex]="selectedTemplateTab$ | async"
              (selectedIndexChange)="setTemplateTab($event)"
              animationDuration="0ms"
            >
              <mat-tab label="In-App">
                <div
                  class="tab-container"
                  *ngIf="(selectedTemplateTab$ | async) === templateTab.InApp"
                >
                  <div class="left-pane">
                    <notification-manage-template-configuration
                      [html]="inAppTemplateContent$ | async"
                      (htmlChange)="setInAppContent($event)"
                      [sample]="inAppSample$ | async"
                      (sampleChange)="setInAppSample($event)"
                    >
                      <notification-manage-template-in-app
                        [enabled]="type.web.enabled"
                        (enabledChange)="setInAppEnabled($event)"
                        [link]="inAppTemplateLink$ | async"
                        (linkChange)="setInAppLink($event)"
                      ></notification-manage-template-in-app>
                    </notification-manage-template-configuration>
                  </div>
                  <mat-divider [vertical]="true"></mat-divider>
                  <div class="right-pane">
                    <notification-manage-template-preview
                      [html]="inAppRenderedHTML$ | async"
                      [loading]="inAppRenderedHTMLLoading$ | async"
                      [error]="inAppRenderedHTMLError$ | async"
                      [sendingTest]="sendTestLoading$ | async"
                      [canSendPreview]="canSendPreview$ | async"
                      (sendTest)="
                        sendTest(notificationMedium.NOTIFICATION_MEDIUM_WEB)
                      "
                    ></notification-manage-template-preview>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="In-App Bundle">
                <div
                  class="tab-container"
                  *ngIf="
                    (selectedTemplateTab$ | async) === templateTab.InAppBundle
                  "
                >
                  <div class="left-pane">
                    <notification-manage-template-configuration
                      [html]="inAppBundleTemplateContent$ | async"
                      (htmlChange)="setInAppBundleContent($event)"
                      [sample]="inAppBundleSample$ | async"
                      (sampleChange)="setInAppBundleSample($event)"
                    >
                      <notification-manage-template-in-app
                        [bundle]="true"
                        [enabled]="type.webBundle.enabled"
                        (enabledChange)="setInAppBundleEnabled($event)"
                        [link]="inAppBundleTemplateLink$ | async"
                        (linkChange)="setInAppBundleLink($event)"
                        [window]="inAppBundleWindow$ | async"
                        (windowChange)="setInAppBundleWindow($event)"
                      ></notification-manage-template-in-app>
                    </notification-manage-template-configuration>
                  </div>
                  <mat-divider [vertical]="true"></mat-divider>
                  <div class="right-pane">
                    <notification-manage-template-preview
                      [html]="inAppBundleRenderedHTML$ | async"
                      [loading]="inAppBundleRenderedHTMLLoading$ | async"
                      [error]="inAppBundleRenderedHTMLError$ | async"
                      noPreviewMessage="To preview a bundled, send multiple in app tests within the specified window."
                    ></notification-manage-template-preview>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Email">
                <div
                  class="tab-container"
                  *ngIf="(selectedTemplateTab$ | async) === templateTab.Email"
                >
                  <div class="left-pane">
                    <notification-manage-template-configuration
                      [html]="emailTemplateContent$ | async"
                      (htmlChange)="setEmailContent($event)"
                      [sample]="emailSample$ | async"
                      (sampleChange)="setEmailSample($event)"
                    >
                      <notification-manage-template-email
                        [enabled]="type.email.enabled"
                        (enabledChange)="setEmailEnabled($event)"
                        [subject]="emailTemplateSubject$ | async"
                        (subjectChange)="setEmailSubject($event)"
                        [link]="emailTemplateLink$ | async"
                        (linkChange)="setEmailLink($event)"
                        [fromName]="emailTemplateFromName$ | async"
                        (fromNameChange)="setEmailFromName($event)"
                        [fromAddress]="emailTemplateFromAddress$ | async"
                        (fromAddressChange)="setEmailFromAddress($event)"
                        [contentType]="type.email.contentType"
                        (contentTypeChange)="setEmailContentType($event)"
                        [emailServiceProvider]="type.email.espId"
                        (emailServiceProviderChange)="
                          setEmailServiceProvider($event)
                        "
                      ></notification-manage-template-email>
                    </notification-manage-template-configuration>
                  </div>
                  <mat-divider [vertical]="true"></mat-divider>
                  <div class="right-pane">
                    <notification-manage-template-preview
                      [inApp]="false"
                      [html]="emailRenderedHTML$ | async"
                      [error]="emailRenderedHTMLError$ | async"
                      [loading]="emailRenderedHTMLLoading$ | async"
                      [canSendPreview]="canSendPreview$ | async"
                      [sendingTest]="sendTestLoading$ | async"
                      (sendTest)="
                        sendTest(notificationMedium.NOTIFICATION_MEDIUM_EMAIL)
                      "
                    ></notification-manage-template-preview>
                  </div>
                </div>
              </mat-tab>
              <mat-tab disabled>
                <ng-template mat-tab-label>
                  <button mat-flat-button [matMenuTriggerFor]="localeMenu">
                    {{ selectedLocale$ | async }}
                  </button>
                  <mat-menu #localeMenu="matMenu">
                    <span mat-menu-item (click)="addLocale()">
                      Add New Locale
                    </span>
                    <mat-divider
                      *ngIf="(locales$ | async)?.length"
                    ></mat-divider>
                    <div
                      mat-menu-item
                      *ngFor="let locale of locales$ | async"
                      (click)="selectLocale(locale.value)"
                    >
                      <span>{{ locale.label }}</span>
                      &nbsp;
                      <span class="mat-card-subtitle">{{ locale.value }}</span>
                    </div>
                  </mat-menu>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="saving" *ngIf="(saving$ | async) || (deleting$ | async)">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
</ng-container>
