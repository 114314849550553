<h2 mat-dialog-title>Copy to New Locale</h2>
<mat-dialog-content>
  Locale:
  <mat-form-field>
    <mat-select #locale placeholder="Select Locale">
      <mat-option *ngFor="let option of localeOptions" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    color="primary"
    (click)="confirm(locale.value)"
    [disabled]="!locale.value"
  >
    Save
  </button>
</mat-dialog-actions>
