import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, forkJoin, Observable, of, zip } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import {
  ContentType,
  NotificationMedium,
  PersonaType,
  PreviewRequestEventInterface,
  setAccessRequirement,
  setCategory,
  setDescription,
  setDomainId,
  setEmailContentTemplate,
  setEmailContentType,
  setEmailEnabled,
  setEmailFromAddressTemplate,
  setEmailFromNameTemplate,
  setEmailLinkTemplate,
  setEmailSample,
  setEmailServiceProvider,
  setEmailSubjectTemplate,
  setFeatureFlagId,
  setHydrators,
  setLocales,
  setName,
  setPersonaAccessRequirements,
  setWebBundleContentTemplate,
  setWebBundleEnabled,
  setWebBundleLinkTemplate,
  setWebBundleSample,
  setWebBundleWindow,
  setWebContentTemplate,
  setWebEnabled,
  setWebLinkTemplate,
  setWebSample,
} from '@vendasta/notifications-sdk';

import { FieldMask, MarketplaceAppService } from '@galaxy/marketplace-apps/v1';
import { EventBrokerService, EventTypeInterface } from '@vendasta/event-broker';

import { NotificationsAdminService, NotificationType } from '../../common/notifications/notifications-admin.service';

import {
  Application,
  NewBusinessCenterApplication,
  NewMarketplaceApplication,
  NewPartnerCenterApplication,
  NewSalesAndSuccessCenterApplication,
  NewTaskManagerApplication,
  NewUnsetApplication,
} from './application';
import {
  additionalAccountGroupDataAvailable,
  additionalDigitalAgentDataAvailable,
  additionalPartnerBrandingDataAvailable,
  additionalProductDataAvailable,
  additionalSalespersonDataAvailable,
  additionalUserDataAvailable,
  populateSampleData,
} from './events';

import {
  FeatureFlag,
  FeatureFlagsApiService,
  ListFeatureFlagFiltersInterface,
  ListFeatureFlagResponse,
} from '@galaxy/partner';
import {
  ConvertEmailContentToTemplate,
  ConvertEmailFromAddressToTemplate,
  ConvertEmailFromNameToTemplate,
  ConvertEmailLinkToTemplate,
  ConvertEmailSubjectToTemplate,
  LoadEmailContent,
  LoadEmailContentError,
  LoadEmailContentSuccess,
  LoadEmailFromAddress,
  LoadEmailFromAddressError,
  LoadEmailFromAddressSuccess,
  LoadEmailFromName,
  LoadEmailFromNameError,
  LoadEmailFromNameSuccess,
  LoadEmailLink,
  LoadEmailLinkError,
  LoadEmailLinkSuccess,
  LoadEmailSubject,
  LoadEmailSubjectError,
  LoadEmailSubjectSuccess,
  RenderEmailTemplate,
  RenderEmailTemplateError,
  RenderEmailTemplateSuccess,
  SetEmailContent,
  SetEmailContentType,
  SetEmailEnabled,
  SetEmailFromAddress,
  SetEmailFromName,
  SetEmailLink,
  SetEmailSample,
  SetEmailServiceProvider,
  SetEmailSubject,
} from './actions/email.actions';
import {
  FetchFeatureFlags,
  FetchFeatureFlagsFailure,
  FetchFeatureFlagsSuccess,
  SetFeatureFlagId,
} from './actions/feature-flag.actions';
import {
  AddResource,
  AddScope,
  Delete,
  DeleteError,
  DeleteSuccess,
  Init,
  LoadApplicationsError,
  LoadApplicationsSuccess,
  LoadEvent,
  LoadEventError,
  LoadEvents,
  LoadEventsError,
  LoadEventsSuccess,
  LoadEventSuccess,
  LoadMoreEvents,
  LoadNotification,
  LoadNotificationError,
  LoadNotificationSuccess,
  RemoveResource,
  RemoveScope,
  Save,
  SaveError,
  SaveSuccess,
  SendTest,
  SendTestError,
  SendTestSuccess,
  SetAccountGroupDataAvailable,
  SetAccountGroupHydrator,
  SetCategory,
  SetDescription,
  SetDigitalAgentDataAvailable,
  SetDigitalAgentHydrator,
  SetDomainId,
  SetEvent,
  SetIdentifier,
  SetName,
  SetPartnerBrandingDataAvailable,
  SetPartnerBrandingHydrator,
  SetPersonaTypes,
  SetProductDataAvailable,
  SetProductHydrator,
  SetResource,
  SetSalespersonDataAvailable,
  SetSalespersonHydrator,
  SetScope,
  SetSelectedLocale,
  SetTemplateTab,
  SetUserDataAvailable,
  SetUserHydrator,
} from './actions/general.actions';
import {
  ConvertInAppBundleContentToTemplate,
  ConvertInAppBundleLinkToTemplate,
  LoadInAppBundleContent,
  LoadInAppBundleContentError,
  LoadInAppBundleContentSuccess,
  LoadInAppBundleLink,
  LoadInAppBundleLinkError,
  LoadInAppBundleLinkSuccess,
  RenderInAppBundleTemplate,
  RenderInAppBundleTemplateError,
  RenderInAppBundleTemplateSuccess,
  SetInAppBundleContent,
  SetInAppBundleEnabled,
  SetInAppBundleLink,
  SetInAppBundleSample,
  SetInAppBundleWindow,
} from './actions/in-app-bundle.actions';
import {
  ConvertInAppContentToTemplate,
  ConvertInAppLinkToTemplate,
  LoadInAppContent,
  LoadInAppContentError,
  LoadInAppContentSuccess,
  LoadInAppLink,
  LoadInAppLinkError,
  LoadInAppLinkSuccess,
  RenderInAppTemplate,
  RenderInAppTemplateError,
  RenderInAppTemplateSuccess,
  SetInAppContent,
  SetInAppEnabled,
  SetInAppLink,
  SetInAppSample,
} from './actions/in-app.actions';
import { AddLocale, SelectedLocaleChanged, SetLocales } from './actions/locale.actions';
import { ActionTypes } from './editor.actions';
import { editorReducer, initialState, State } from './editor.reducers';
import { DEFAULT_LOCALE, Locale, LOCALES } from './locales';
import { TemplateTab } from './reducers/general.reducers';
import { TemplateService } from './template.service';

@Injectable()
export class EditorService {
  public readonly store = new BehaviorSubject<State>(initialState());
  public get state(): State {
    return this.store.getValue();
  }
  public set state(newState: State) {
    this.store.next(newState);
  }

  public readonly new$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.new),
    distinctUntilChanged(),
  );
  public readonly typeLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.typeLoading),
    distinctUntilChanged(),
  );
  public readonly typeError$: Observable<any> = this.store.pipe(
    map((state) => !state.general.typeLoading && state.general.typeError),
    distinctUntilChanged(),
  );
  public readonly type$: Observable<NotificationType> = this.store.pipe(
    map((state) => state.general.type),
    distinctUntilChanged(),
  );
  public readonly saving$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.saving),
    distinctUntilChanged(),
  );
  public readonly savingSuccess$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.savingSuccess),
    distinctUntilChanged(),
  );
  public readonly savingError$: Observable<any> = this.store.pipe(
    map((state) => state.general.savingError),
    distinctUntilChanged(),
  );
  public readonly deleting$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.deleting),
    distinctUntilChanged(),
  );
  public readonly deletingSuccess$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.deletingSuccess),
    distinctUntilChanged(),
  );
  public readonly deletingError$: Observable<string> = this.store.pipe(
    map((state) => state.general.deletingError),
    distinctUntilChanged(),
  );
  public readonly edited$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.edited),
    distinctUntilChanged(),
  );

  public readonly eventLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.eventLoading),
    distinctUntilChanged(),
  );
  public readonly eventError$: Observable<boolean> = this.store.pipe(
    map((state) => !state.general.eventLoading && state.general.eventError),
    distinctUntilChanged(),
  );
  public readonly event$: Observable<EventTypeInterface> = this.store.pipe(
    map((state) => state.general.event),
    distinctUntilChanged(),
  );

  public readonly eventsLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.eventsLoading),
    distinctUntilChanged(),
  );
  public readonly eventsError$: Observable<any> = this.store.pipe(
    map((state) => state.general.eventsError),
    distinctUntilChanged(),
  );
  public readonly eventsHasMore$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.eventsHasMore),
    distinctUntilChanged(),
  );
  public readonly events$: Observable<EventTypeInterface[]> = this.store.pipe(
    map((state) => state.general.events),
    distinctUntilChanged(),
  );
  public readonly accountGroupDataAvailable$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.accountGroupDataAvailable),
    distinctUntilChanged(),
  );
  public readonly salespersonDataAvailable$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.salespersonDataAvailable),
    distinctUntilChanged(),
  );
  public readonly productDataAvailable$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.productDataAvailable),
    distinctUntilChanged(),
  );
  public readonly partnerBrandingDataAvailable$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.partnerBrandingDataAvailable),
    distinctUntilChanged(),
  );
  public readonly digitalAgentDataAvailable$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.digitalAgentDataAvailable),
    distinctUntilChanged(),
  );
  public readonly userDataAvailable$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.userDataAvailable),
    distinctUntilChanged(),
  );

  public readonly applications$: Observable<Application[]> = this.store.pipe(
    map((state) => state.general.applications),
    distinctUntilChanged(),
  );
  public readonly applicationsLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.applicationsLoading),
    distinctUntilChanged(),
  );
  public readonly applicationsError$: Observable<any> = this.store.pipe(
    map((state) => state.general.applicationsError),
    distinctUntilChanged(),
  );

  public readonly featureFlagId$: Observable<string> = this.store.pipe(
    filter((state) => !!state?.general?.type?.featureFlagId),
    map((state) => state.general.type.featureFlagId),
    distinctUntilChanged(),
  );
  public readonly fetchingFeatureFlags$: Observable<boolean> = this.store.pipe(
    map((state) => state.featureFlag.fetching),
    distinctUntilChanged(),
  );
  public readonly featureFlagsError$: Observable<any> = this.store.pipe(
    map((state) => state.featureFlag.error),
    distinctUntilChanged(),
  );
  public readonly featureFlags$: Observable<FeatureFlag[]> = this.store.pipe(
    map((state) => state.featureFlag.featureFlags),
    distinctUntilChanged(),
  );

  public readonly locales$: Observable<Locale[]> = this.store.pipe(
    map((state) => Object.keys(state.locales).map((locale) => LOCALES[locale])),
    distinctUntilChanged(),
  );
  public readonly selectedLocale$: Observable<Locale> = this.store.pipe(
    map((state) => LOCALES[state.general.selectedLocale]),
    distinctUntilChanged(),
  );

  public readonly sendTestLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.sendTestLoading),
    distinctUntilChanged(),
  );
  public readonly sendTestSuccess$: Observable<boolean> = this.store.pipe(
    map((state) => state.general.sendTestSuccess),
    distinctUntilChanged(),
  );
  public readonly sendTestError$: Observable<string> = this.store.pipe(
    map((state) => state.general.sendTestError),
    distinctUntilChanged(),
  );

  public readonly inAppLinkTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.inApp.linkTemplate),
    distinctUntilChanged(),
  );
  public readonly inAppContentTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.inApp.contentTemplate),
    distinctUntilChanged(),
  );
  public readonly inAppRenderedHTML$: Observable<string> = this.store.pipe(
    map((state) => state.inApp.renderedHTML),
    distinctUntilChanged(),
  );
  public readonly inAppRenderedHTMLLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.inApp.renderedHTMLLoading),
    distinctUntilChanged(),
  );
  public readonly inAppRenderedHTMLError$: Observable<string> = this.store.pipe(
    map((state) => state.inApp.renderedHTMLError),
    distinctUntilChanged(),
  );
  public readonly inAppSample$: Observable<string> = this.store.pipe(
    map((state) => state.inApp.sample),
    distinctUntilChanged(),
  );

  public readonly inAppBundleWindowMinutes$: Observable<number> = this.store.pipe(
    map((state) => state.general.type.webBundle.window),
    map((windowString) => (windowString ? parseInt(windowString.split('s')[0], 10) : 0)),
    map((windowSeconds) => windowSeconds / 60),
    startWith(0),
    distinctUntilChanged(),
  );
  public readonly inAppBundleLinkTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.inAppBundle.linkTemplate),
    distinctUntilChanged(),
  );
  public readonly inAppBundleContentTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.inAppBundle.contentTemplate),
    distinctUntilChanged(),
  );
  public readonly inAppBundleRenderedHTML$: Observable<string> = this.store.pipe(
    map((state) => state.inAppBundle.renderedHTML),
    distinctUntilChanged(),
  );
  public readonly inAppBundleRenderedHTMLLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.inAppBundle.renderedHTMLLoading),
    distinctUntilChanged(),
  );
  public readonly inAppBundleRenderedHTMLError$: Observable<string> = this.store.pipe(
    map((state) => state.inAppBundle.renderedHTMLError),
    distinctUntilChanged(),
  );
  public readonly inAppBundleSample$: Observable<string> = this.store.pipe(
    map((state) => state.inAppBundle.sample),
    distinctUntilChanged(),
  );

  public readonly emailContentTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.email.contentTemplate),
    distinctUntilChanged(),
  );
  public readonly emailSubjectTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.email.subjectTemplate),
    distinctUntilChanged(),
  );
  public readonly emailLinkTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.email.linkTemplate),
    distinctUntilChanged(),
  );
  public readonly emailFromAddressTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.email.fromAddressTemplate),
    distinctUntilChanged(),
  );
  public readonly emailFromNameTemplate$: Observable<string> = this.store.pipe(
    map((state) => state.email.fromNameTemplate),
    distinctUntilChanged(),
  );
  public readonly emailRenderedHTML$: Observable<string> = this.store.pipe(
    map((state) => state.email.renderedHTML),
    distinctUntilChanged(),
  );
  public readonly emailRenderedHTMLLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.email.renderedHTMLLoading),
    distinctUntilChanged(),
  );
  public readonly emailRenderedHTMLError$: Observable<boolean> = this.store.pipe(
    map((state) => state.email.renderedHTMLError),
    distinctUntilChanged(),
  );
  public readonly emailSample$: Observable<string> = this.store.pipe(
    map((state) => state.email.sample),
    distinctUntilChanged(),
  );

  constructor(
    private adminService: NotificationsAdminService,
    private eventBrokerService: EventBrokerService,
    private templateService: TemplateService,
    private marketplaceAppsService: MarketplaceAppService,
    private featureFlagService: FeatureFlagsApiService,
  ) {}

  init(id?: string, searchTerm = ''): void {
    this.state = editorReducer(this.state, new Init());

    if (!id) {
      this.loadEvents(searchTerm);
    } else {
      this.loadNotification(id);
    }
    this.loadApps();
  }

  private loadNotification(id: string): void {
    this.state = editorReducer(this.state, new LoadNotification({ id }));
    this.adminService
      .getType$(id)
      .pipe(
        map((t) => new LoadNotificationSuccess({ type: t })),
        catchError((err) => of(new LoadNotificationError({ error: err }))),
        switchMap((action) => {
          this.state = editorReducer(this.state, action);
          if (action.type === ActionTypes.LOAD_TYPE_SUCCESS) {
            this.state = editorReducer(this.state, new SetLocales({ locales: action.payload.type.locales || [] }));
            this.state = editorReducer(this.state, new SetSelectedLocale({ locale: DEFAULT_LOCALE }));

            const loadFuncs = [this.loadInAppTemplate(), this.loadInAppBundleTemplate(), this.loadEmailTemplate()];
            if (action.payload.type.locales && action.payload.type.locales.length) {
              action.payload.type.locales.forEach((locale) => {
                loadFuncs.push(
                  this.loadInAppTemplate(locale),
                  this.loadInAppBundleTemplate(locale),
                  this.loadEmailTemplate(locale),
                );
              });
            }
            this.loadEvent();
            return combineLatest(loadFuncs);
          }
        }),
      )
      .subscribe(() => {
        this.state = editorReducer(
          this.state,
          new SelectedLocaleChanged({ locale: this.state.general.selectedLocale }),
        );
      });
  }

  public loadEvent(): void {
    this.state = editorReducer(this.state, new LoadEvent());
    if (!this.state.general.type.eventTypeId) {
      this.state = editorReducer(this.state, new LoadEventSuccess({ event: null }));
      return;
    }
    this.eventBrokerService
      .getEventTypes([this.state.general.type.eventTypeId])
      .pipe(
        map((events) => {
          if (events.length < 1 || !events[0]) {
            throw new Error('Failed to load event type');
          }
          return events[0];
        }),
        tap((event) => {
          this.calculateAvailableHydrators(event);
          this.populateSampleDataFromHydrators(event, false);
        }),
        map((event) => new LoadEventSuccess({ event: event })),
        catchError((err) => of(new LoadEventError({ error: err }))),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  private calculateAvailableHydrators(event: EventTypeInterface): void {
    this.state = editorReducer(
      this.state,
      new SetAccountGroupDataAvailable({ enabled: additionalAccountGroupDataAvailable(event.schema) }),
    );
    this.state = editorReducer(
      this.state,
      new SetSalespersonDataAvailable({ enabled: additionalSalespersonDataAvailable(event.schema) }),
    );
    this.state = editorReducer(
      this.state,
      new SetProductDataAvailable({ enabled: additionalProductDataAvailable(event.schema) }),
    );
    this.state = editorReducer(
      this.state,
      new SetPartnerBrandingDataAvailable({ enabled: additionalPartnerBrandingDataAvailable(event.schema) }),
    );
    this.state = editorReducer(
      this.state,
      new SetDigitalAgentDataAvailable({ enabled: additionalDigitalAgentDataAvailable(event.schema) }),
    );
    this.state = editorReducer(
      this.state,
      new SetUserDataAvailable({ enabled: additionalUserDataAvailable(event.schema) }),
    );
  }

  private populateSampleDataFromHydrators(event: EventTypeInterface, edited: boolean): void {
    try {
      const webSample = populateSampleData(
        this.state.general.type.web.sampleData || {},
        event.schema,
        this.state.general.type.hydrators,
        NotificationMedium.NOTIFICATION_MEDIUM_WEB,
        false,
      );
      this.state = editorReducer(
        this.state,
        new SetInAppSample({
          sample: JSON.stringify(webSample, null, 4),
          sampleObj: webSample,
          edited: edited,
        }),
      );
    } catch (e) {
      this.state = editorReducer(this.state, new RenderInAppTemplateError({ error: 'Invalid sample' }));
    }
    try {
      const webBundleSample = populateSampleData(
        this.state.general.type.webBundle.sampleData || {},
        event.schema,
        this.state.general.type.hydrators,
        NotificationMedium.NOTIFICATION_MEDIUM_WEB,
        true,
      );
      this.state = editorReducer(
        this.state,
        new SetInAppBundleSample({
          sample: JSON.stringify(webBundleSample, null, 4),
          sampleObj: webBundleSample,
          edited: edited,
        }),
      );
    } catch {
      this.state = editorReducer(this.state, new RenderInAppBundleTemplateError({ error: 'Invalid sample' }));
    }
    try {
      const emailSample = populateSampleData(
        this.state.general.type.email.sampleData || {},
        event.schema,
        this.state.general.type.hydrators,
        NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
        false,
      );
      this.state = editorReducer(
        this.state,
        new SetEmailSample({
          sample: JSON.stringify(emailSample, null, 4),
          sampleObj: emailSample,
          edited: edited,
        }),
      );
    } catch {
      this.state = editorReducer(this.state, new RenderEmailTemplateError({ error: 'Invalid sample' }));
    }
  }

  loadEvents(searchTerm: string): void {
    this.state = editorReducer(this.state, new LoadEvents({ searchTerm: searchTerm }));
    this.loadEventsPage();
  }

  loadMoreEvents(): void {
    this.state = editorReducer(this.state, new LoadMoreEvents());
    this.loadEventsPage();
  }

  setEvent(event: EventTypeInterface): void {
    this.state = editorReducer(this.state, new SetEvent({ event: event }));
    this.calculateAvailableHydrators(event);
    this.populateSampleDataFromHydrators(event, false);
  }

  private loadEventsPage(): void {
    this.eventBrokerService
      .listEventTypes(this.state.general.eventsCursor, 15, this.state.general.searchTerm)
      .pipe(
        map((resp) => {
          if (!resp.eventTypes) {
            return new LoadEventsSuccess({ events: [], cursor: '', hasMore: false });
          }
          return new LoadEventsSuccess({
            events: resp.eventTypes,
            cursor: resp.nextCursor,
            hasMore: resp.hasMore || false,
          });
        }),
        catchError((err) => of(new LoadEventsError({ error: err }))),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  private loadApps(): void {
    this.marketplaceAppsService
      .listApps(0, '', new FieldMask({ paths: ['appId', 'name'] }))
      .pipe(
        map((resp) => resp.apps.map((app) => NewMarketplaceApplication(app))),
        // Filter out RM from the list, we are hacking the list to make it show up at the top since there are 1000s of
        // apps being returned and that page size isn't big enough. This is just a quick fix to unblock the Rep team.
        map((apps) => apps.filter((app) => app.id !== '/app/marketplace-app/RM')),
        map((marketplaceApps) => {
          return [
            NewUnsetApplication(),
            NewPartnerCenterApplication(),
            NewTaskManagerApplication(),
            NewSalesAndSuccessCenterApplication(),
            NewBusinessCenterApplication(),
            NewMarketplaceApplication({ appId: 'RM', name: 'Reputation Management' }),
            ...marketplaceApps,
          ];
        }),
        map(
          (apps) =>
            new LoadApplicationsSuccess({
              applications: apps,
            }),
        ),
        catchError((err) => of(new LoadApplicationsError({ error: err }))),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  loadFeatureFlags(searchTerm: string): void {
    this.state = editorReducer(this.state, new FetchFeatureFlags());
    const filters = searchTerm ? ({ searchTerm: searchTerm } as ListFeatureFlagFiltersInterface) : null;
    const unarchivedFeatureFlagsResult$ = this.featureFlagService
      .list({ pageSize: 100, filters: filters })
      .pipe(catchError((err) => of(new FetchFeatureFlagsFailure({ error: err }))));

    const archivedFeatureFlagsResult$ = this.featureFlagService
      .list({ archivedOnly: true, pageSize: 100, filters: filters })
      .pipe(catchError((err) => of(new FetchFeatureFlagsFailure({ error: err }))));

    zip(unarchivedFeatureFlagsResult$, archivedFeatureFlagsResult$)
      .pipe(
        map(([unarchived, archived]: [ListFeatureFlagResponse, ListFeatureFlagResponse]) => {
          const ff = (unarchived?.flags || []).concat(archived?.flags || []);
          return new FetchFeatureFlagsSuccess({ featureFlags: ff });
        }),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  convertToTemplate(): void {
    this.convertInAppContentToTemplate();
    this.convertInAppLinkToTemplate();
    this.convertInAppBundleContentToTemplate();
    this.convertInAppBundleLinkToTemplate();
    this.convertEmailContentToTemplate();
    this.convertEmailLinkToTemplate();
    this.convertEmailSubjectToTemplate();
    this.convertEmailFromAddressToTemplate();
    this.convertEmailFromNameToTemplate();
  }

  getTemplateId(medium: NotificationMedium, bundle: boolean, type: string): string {
    const delivery = medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB ? 'web' : 'email';
    return (
      '/notification-type-id/' +
      this.state.general.type.notificationTypeId +
      '/delivery/' +
      delivery +
      '/type/' +
      type +
      (bundle ? '/bundle' : '')
    );
  }

  convertInAppContentToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_WEB, false, 'content');
    this.state = editorReducer(this.state, new ConvertInAppContentToTemplate({ templateId: templateId }));
  }

  convertInAppLinkToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_WEB, false, 'link');
    this.state = editorReducer(this.state, new ConvertInAppLinkToTemplate({ templateId: templateId }));
  }

  convertInAppBundleLinkToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_WEB, true, 'content');
    this.state = editorReducer(this.state, new ConvertInAppBundleContentToTemplate({ templateId: templateId }));
  }

  convertInAppBundleContentToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_WEB, true, 'link');
    this.state = editorReducer(this.state, new ConvertInAppBundleLinkToTemplate({ templateId: templateId }));
  }

  convertEmailContentToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_EMAIL, false, 'content');
    this.state = editorReducer(this.state, new ConvertEmailContentToTemplate({ templateId: templateId }));
  }

  convertEmailLinkToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_EMAIL, false, 'link');
    this.state = editorReducer(this.state, new ConvertEmailLinkToTemplate({ templateId: templateId }));
  }

  convertEmailSubjectToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_EMAIL, false, 'subject');
    this.state = editorReducer(this.state, new ConvertEmailSubjectToTemplate({ templateId: templateId }));
  }

  convertEmailFromAddressToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_EMAIL, false, 'name');
    this.state = editorReducer(this.state, new ConvertEmailFromNameToTemplate({ templateId: templateId }));
  }

  convertEmailFromNameToTemplate(): void {
    const templateId = this.getTemplateId(NotificationMedium.NOTIFICATION_MEDIUM_EMAIL, false, 'address');
    this.state = editorReducer(this.state, new ConvertEmailFromAddressToTemplate({ templateId: templateId }));
  }

  loadInAppTemplate(locale?: string): Observable<any> {
    return combineLatest([this.loadInAppContent(locale), this.loadInAppLink(locale)]);
  }

  loadInAppContent(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_WEB,
      false,
      'contentTemplate',
      LoadInAppContent,
      LoadInAppContentSuccess,
      LoadInAppContentError,
      () => {
        this.renderTemplate(
          this.state.inApp.contentTemplate,
          this.state.general.type.web.sampleData,
          RenderInAppTemplate,
          RenderInAppTemplateSuccess,
          RenderInAppTemplateError,
        );
      },
      locale,
    );
  }

  loadInAppLink(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_WEB,
      false,
      'linkTemplate',
      LoadInAppLink,
      LoadInAppLinkSuccess,
      LoadInAppLinkError,
      undefined,
      locale,
    );
  }

  loadInAppBundleTemplate(locale?: string): Observable<any> {
    return combineLatest([this.loadInAppBundleContent(locale), this.loadInAppBundleLink(locale)]);
  }

  loadInAppBundleContent(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_WEB,
      true,
      'contentTemplate',
      LoadInAppBundleContent,
      LoadInAppBundleContentSuccess,
      LoadInAppBundleContentError,
      () =>
        this.renderTemplate(
          this.state.inAppBundle.contentTemplate,
          this.state.general.type.webBundle.sampleData,
          RenderInAppBundleTemplate,
          RenderInAppBundleTemplateSuccess,
          RenderInAppBundleTemplateError,
        ),
      locale,
    );
  }

  loadInAppBundleLink(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_WEB,
      true,
      'linkTemplate',
      LoadInAppBundleLink,
      LoadInAppBundleLinkSuccess,
      LoadInAppBundleLinkError,
      undefined,
      locale,
    );
  }

  loadEmailTemplate(locale?: string): Observable<any> {
    return combineLatest([
      this.loadEmailContent(locale),
      this.loadEmailLink(locale),
      this.loadEmailSubject(locale),
      this.loadEmailFromAddress(locale),
      this.loadEmailFromName(locale),
    ]);
  }

  loadEmailContent(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
      false,
      'contentTemplate',
      LoadEmailContent,
      LoadEmailContentSuccess,
      LoadEmailContentError,
      () =>
        this.renderTemplate(
          this.state.email.contentTemplate,
          this.state.general.type.email.sampleData,
          RenderEmailTemplate,
          RenderEmailTemplateSuccess,
          RenderEmailTemplateError,
        ),
      locale,
    );
  }

  loadEmailLink(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
      false,
      'linkTemplate',
      LoadEmailLink,
      LoadEmailLinkSuccess,
      LoadEmailLinkError,
      null,
      locale,
    );
  }

  loadEmailSubject(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
      false,
      'subjectTemplate',
      LoadEmailSubject,
      LoadEmailSubjectSuccess,
      LoadEmailSubjectError,
      undefined,
      locale,
    );
  }

  loadEmailFromAddress(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
      false,
      'from.addressTemplate',
      LoadEmailFromAddress,
      LoadEmailFromAddressSuccess,
      LoadEmailFromAddressError,
      undefined,
      locale,
    );
  }

  loadEmailFromName(locale?: string): Observable<any> {
    return this.loadTemplate(
      NotificationMedium.NOTIFICATION_MEDIUM_EMAIL,
      false,
      'from.nameTemplate',
      LoadEmailFromName,
      LoadEmailFromNameSuccess,
      LoadEmailFromNameError,
      undefined,
      locale,
    );
  }

  // Generalized template loader.
  // Template properties can be dot separated to denote the path to the template property in the config.
  // startAction is the action which will be performed first. This may indicate a loading state
  // successAction is the action performed when the template has been retrieved.
  // errorAction will be performed if there was a problem getting the template
  // next is an optional function that will be called once the template was successfully retrieved.
  // For example, this can be used to call a function to render the template once it's been loaded.
  private loadTemplate(
    medium: NotificationMedium,
    bundle: boolean,
    templateProperty: string,
    startAction: any,
    successAction: any,
    errorAction: any,
    next?: () => void,
    locale?: string,
  ): Observable<any> {
    const start = new startAction();
    this.state = editorReducer(this.state, start);
    let templateContainer: any;
    if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
      templateContainer = this.state.general.type.email;
    } else if (medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB) {
      templateContainer = bundle ? this.state.general.type.webBundle : this.state.general.type.web;
    }
    const path = templateProperty.split('.');
    while (path.length > 0) {
      const pathPart = path.shift();
      templateContainer = templateContainer[pathPart];
      if (!templateContainer) {
        return of();
      }
    }
    if (templateContainer.inline) {
      const success = new successAction({ template: templateContainer.inline.content, locale: locale });
      this.state = editorReducer(this.state, success);
      if (next) {
        next();
      }
    } else if (templateContainer.id) {
      return this.templateService.get(templateContainer.id, locale).pipe(
        map((resp) => new successAction({ template: resp.content || '', locale: locale })),
        catchError((err) => of(new errorAction({ error: err, locale: locale }))),
        tap((action) => {
          this.state = editorReducer(this.state, action);
          if (next && action instanceof successAction) {
            next();
          }
        }),
      );
    } else {
      const success = new successAction({ template: '', locale: locale });
      this.state = editorReducer(this.state, success);
      if (next) {
        next();
      }
    }
    return of();
  }

  private renderTemplate(
    content: string,
    sample: object,
    startAction: any,
    successAction: any,
    errorAction: any,
  ): void {
    this.state = editorReducer(this.state, new startAction());
    this.templateService
      .render(content, sample, this.state.general.templateTab)
      .pipe(
        map((resp) => new successAction({ html: resp })),
        catchError((err: HttpErrorResponse) => of(new errorAction({ error: err.error.message }))),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  save(): void {
    this.state = editorReducer(this.state, new Save());

    if (!this.state.general.type.notificationTypeId.match(/^[a-z0-9-]+$/)) {
      this.state = editorReducer(this.state, new SaveError({ error: 'Identifier must be lower case and kebab case.' }));
      return;
    }
    if (!this.state.general.type.description) {
      this.state = editorReducer(this.state, new SaveError({ error: 'Description is required.' }));
      return;
    }
    if (this.state.general.type.category.length < 1 || !this.state.general.type.category[0]) {
      this.state = editorReducer(this.state, new SaveError({ error: 'Category is required.' }));
      return;
    }

    // In App template validation
    if (this.state.general.type.web.enabled) {
      for (const locale in this.state.locales) {
        if (locale in this.state.locales) {
          if (!this.state.locales[locale].inApp.linkTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'In app link is required for locale ' + locale }),
            );
            return;
          }
          if (!this.state.locales[locale].inApp.contentTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'In app content is required for locale ' + locale }),
            );
            return;
          }
        }
      }
    }

    // In app bundle validation
    if (this.state.general.type.webBundle.enabled) {
      for (const locale in this.state.locales) {
        if (locale in this.state.locales) {
          if (!this.state.locales[locale].inAppBundle.linkTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'In app bundle link is required for locale ' + locale }),
            );
            return;
          }
          if (!this.state.locales[locale].inAppBundle.contentTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'In app bundle content is required for locale ' + locale }),
            );
            return;
          }
        }
      }
      if (this.state.general.type.webBundle.window === '0s') {
        this.state = editorReducer(this.state, new SaveError({ error: 'In app bundle window is required.' }));
        return;
      }
    }

    // Email validation
    if (this.state.general.type.email.enabled) {
      for (const locale in this.state.locales) {
        if (locale in this.state.locales) {
          if (!this.state.locales[locale].email.subjectTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'Email subject is required for locale ' + locale }),
            );
            return;
          }
          if (!this.state.locales[locale].email.linkTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'Email link is required for locale ' + locale }),
            );
            return;
          }
          if (!this.state.locales[locale].email.fromNameTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'Email from name is required for locale ' + locale }),
            );
            return;
          }
          if (!this.state.locales[locale].email.fromAddressTemplate) {
            this.state = editorReducer(
              this.state,
              new SaveError({ error: 'Email from address is required for locale ' + locale }),
            );
            return;
          }
        }
      }
      if (!this.state.general.type.email.espId) {
        this.state = editorReducer(this.state, new SaveError({ error: 'Email service provider is required.' }));
        return;
      }
    }

    let emailTemplateSample: any;
    if (this.state.general.type.email.sampleData) {
      try {
        emailTemplateSample = this.state.general.type.email.sampleData;
      } catch {
        this.state = editorReducer(this.state, new SaveError({ error: 'Invalid email sample data.' }));
        return;
      }
    }
    let inAppTemplateSample: any;
    if (this.state.general.type.web.sampleData) {
      try {
        inAppTemplateSample = this.state.general.type.web.sampleData;
      } catch {
        this.state = editorReducer(this.state, new SaveError({ error: 'Invalid in app sample data.' }));
        return;
      }
    }
    let inAppBundleTemplateSample: any;
    if (this.state.general.type.webBundle.sampleData) {
      try {
        inAppBundleTemplateSample = this.state.general.type.webBundle.sampleData;
      } catch {
        this.state = editorReducer(this.state, new SaveError({ error: 'Invalid in app bundle sample data.' }));
        return;
      }
    }

    let req: Observable<any>;
    if (this.state.general.new) {
      req = this.adminService.createType$(
        this.state.general.type.notificationTypeId,
        this.state.general.event.id,
        this.state.general.type.name,
        this.state.general.type.description,
        this.state.general.type.category,
        this.state.general.type.domainId,
        this.state.general.type.featureFlagId,
        this.state.general.type.hydrators,
        this.state.general.type.accessRequirements,
        this.state.general.type.personaAccessRequirements,
        {
          enabled: this.state.general.type.web.enabled,
          contentTemplate: { inline: { content: this.state.inApp.contentTemplate } },
          linkTemplate: { inline: { content: this.state.inApp.linkTemplate } },
          sampleData: inAppTemplateSample,
        },
        {
          enabled: this.state.general.type.webBundle.enabled,
          window: this.state.general.type.webBundle.window || '0s',
          contentTemplate: { inline: { content: this.state.inAppBundle.contentTemplate } },
          linkTemplate: { inline: { content: this.state.inAppBundle.linkTemplate } },
          sampleData: inAppBundleTemplateSample,
        },
        {
          enabled: this.state.general.type.email.enabled,
          contentTemplate: { inline: { content: this.state.email.contentTemplate } },
          linkTemplate: { inline: { content: this.state.email.linkTemplate } },
          subjectTemplate: { inline: { content: this.state.email.subjectTemplate } },
          from: {
            nameTemplate: { inline: { content: this.state.email.fromNameTemplate } },
            addressTemplate: { inline: { content: this.state.email.fromAddressTemplate } },
          },
          contentType: this.state.general.type.email.contentType,
          espId: this.state.general.type.email.espId,
          sampleData: emailTemplateSample,
        },
      );
    } else {
      let ops = [
        setName(this.state.general.type.name),
        setDescription(this.state.general.type.description),
        setCategory(this.state.general.type.category),
        setDomainId(this.state.general.type.domainId),
        setHydrators(this.state.general.type.hydrators),
        setAccessRequirement(this.state.general.type.accessRequirements),
        setPersonaAccessRequirements(this.state.general.type.personaAccessRequirements),
        setFeatureFlagId(this.state.general.type.featureFlagId),
        setLocales(Object.keys(this.state.locales)),

        setEmailEnabled(this.state.general.type.email.enabled),
        setEmailContentType(this.state.general.type.email.contentType),
        setEmailServiceProvider(this.state.general.type.email.espId),
        setEmailSample(this.state.general.type.email.sampleData),

        setWebEnabled(this.state.general.type.web.enabled),
        setWebSample(this.state.general.type.web.sampleData),

        setWebBundleEnabled(this.state.general.type.webBundle.enabled),
        setWebBundleSample(this.state.general.type.webBundle.sampleData),
        setWebBundleWindow(this.state.general.type.webBundle.window),
      ];

      // Templates can be stored by Notifications, or the template µs. We need to determine which is set for this
      // notification. If it's stored in Notifications, a mutation will be added to the current set of mutations,
      // if it's stored in templates requests will be made to the template µs. This really should be hidden behind
      // the api itself so the frontend doesn't have to care about it
      const { tempReqs, templateMutations } = [
        // Email
        {
          templateId: this.state.general.type.email.contentTemplate.id,
          content: this.state.email.contentTemplate,
          delivery: 'email',
          property: 'contentTemplate',
          mutation: setEmailContentTemplate,
        },
        {
          templateId: this.state.general.type.email.linkTemplate.id,
          content: this.state.email.linkTemplate,
          delivery: 'email',
          property: 'linkTemplate',
          mutation: setEmailLinkTemplate,
        },
        {
          templateId: this.state.general.type.email.subjectTemplate.id,
          content: this.state.email.subjectTemplate,
          delivery: 'email',
          property: 'subjectTemplate',
          mutation: setEmailSubjectTemplate,
        },
        {
          templateId: this.state.general.type.email.from.nameTemplate.id,
          content: this.state.email.fromNameTemplate,
          delivery: 'email',
          property: 'fromNameTemplate',
          mutation: setEmailFromNameTemplate,
        },
        {
          templateId: this.state.general.type.email.from.addressTemplate.id,
          content: this.state.email.fromAddressTemplate,
          delivery: 'email',
          property: 'fromAddressTemplate',
          mutation: setEmailFromAddressTemplate,
        },
        // Web
        {
          templateId: this.state.general.type.web.contentTemplate.id,
          content: this.state.inApp.contentTemplate,
          delivery: 'inApp',
          property: 'contentTemplate',
          mutation: setWebContentTemplate,
        },
        {
          templateId: this.state.general.type.web.linkTemplate.id,
          content: this.state.inApp.linkTemplate,
          delivery: 'inApp',
          property: 'linkTemplate',
          mutation: setWebLinkTemplate,
        },
        // Web Bundle
        {
          templateId: this.state.general.type.webBundle.contentTemplate.id,
          content: this.state.inAppBundle.contentTemplate,
          delivery: 'inAppBundle',
          property: 'contentTemplate',
          mutation: setWebBundleContentTemplate,
        },
        {
          templateId: this.state.general.type.webBundle.linkTemplate.id,
          content: this.state.inAppBundle.linkTemplate,
          delivery: 'inAppBundle',
          property: 'linkTemplate',
          mutation: setWebBundleLinkTemplate,
        },
      ].reduce(
        (accumulator, currentValue) => {
          const templateRequests = accumulator.tempReqs;
          const muts = accumulator.templateMutations;
          if (currentValue.templateId) {
            Object.keys(this.state.locales).forEach((locale) => {
              const content = this.state.locales[locale][currentValue.delivery][currentValue.property];
              templateRequests.push(this.templateService.replace(currentValue.templateId, content, locale));
            });
            muts.push(currentValue.mutation({ id: currentValue.templateId }));
          } else {
            muts.push(currentValue.mutation({ inline: { content: currentValue.content } }));
          }
          return { tempReqs: templateRequests, templateMutations: muts };
        },
        { tempReqs: [], templateMutations: [] },
      );

      // Add any mutations to the current set of mutations
      ops = ops.concat(templateMutations);
      // If there are no template ids on the notification type, this simply make a mutation RPC to notifications.
      // If there are template ids, we will update the template by calling the template µs in addition to applying
      // any other mutation.
      const reqs = [this.adminService.updateType$(this.state.general.type.notificationTypeId, ops), ...tempReqs];

      req = forkJoin(reqs);
    }
    req
      .pipe(
        map(() => new SaveSuccess()),
        catchError((err: HttpErrorResponse) => {
          let message = err.error.message;
          if (err.status === 409) {
            message = 'Identifier is already in use.';
          }
          return of(new SaveError({ error: message }));
        }),
        tap((action) => (this.state = editorReducer(this.state, action))),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  delete(typeId: string): void {
    this.state = editorReducer(this.state, new Delete());
    this.adminService
      .deleteType$(typeId)
      .pipe(
        map(() => new DeleteSuccess()),
        catchError((err: HttpErrorResponse) => of(new DeleteError({ error: err.error.message }))),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }

  setIdentifier(identifier: string): void {
    this.state = editorReducer(this.state, new SetIdentifier({ identifier: identifier }));
  }

  setName(name: string): void {
    this.state = editorReducer(this.state, new SetName({ name: name }));
  }

  setDescription(description: string): void {
    this.state = editorReducer(this.state, new SetDescription({ description: description }));
  }

  setCategory(category: string): void {
    this.state = editorReducer(this.state, new SetCategory({ category: category }));
  }

  setDomainId(domainId: string): void {
    this.state = editorReducer(this.state, new SetDomainId({ domainId: domainId }));
  }

  setFeatureFlagId(id: string): void {
    this.state = editorReducer(this.state, new SetFeatureFlagId({ id: id }));
  }

  setPersonaTypes(personaTypes: PersonaType[]): void {
    this.state = editorReducer(this.state, new SetPersonaTypes({ personaTypes: personaTypes }));
  }

  addResource(): void {
    this.state = editorReducer(this.state, new AddResource());
  }

  setResource(resourceIndex: number, resourceName: string): void {
    this.state = editorReducer(
      this.state,
      new SetResource({ resourceIndex: resourceIndex, resourceName: resourceName }),
    );
  }

  removeResource(resourceIndex: number): void {
    this.state = editorReducer(this.state, new RemoveResource({ resourceIndex: resourceIndex }));
  }

  addScope(resourceIndex: number): void {
    this.state = editorReducer(this.state, new AddScope({ resourceIndex: resourceIndex }));
  }

  setScope(resourceIndex: number, scopeIndex: number, scopeId: string): void {
    this.state = editorReducer(
      this.state,
      new SetScope({ resourceIndex: resourceIndex, scopeIndex: scopeIndex, scopeId: scopeId }),
    );
  }

  removeScope(resourceIndex: number, scopeIndex: number): void {
    this.state = editorReducer(this.state, new RemoveScope({ resourceIndex: resourceIndex, scopeIndex }));
  }

  addLocale(locale: string): void {
    this.state = editorReducer(this.state, new AddLocale({ locale: locale }));
  }

  setSelectedLocale(locale: string): void {
    this.state = editorReducer(this.state, new SetSelectedLocale({ locale: locale }));
    this.state = editorReducer(this.state, new SelectedLocaleChanged({ locale: locale }));
    this.renderTemplate(
      this.state.email.contentTemplate,
      this.state.general.type.email.sampleData,
      RenderEmailTemplate,
      RenderEmailTemplateSuccess,
      RenderEmailTemplateError,
    );
    this.renderTemplate(
      this.state.inApp.contentTemplate,
      this.state.general.type.web.sampleData,
      RenderInAppTemplate,
      RenderInAppTemplateSuccess,
      RenderInAppTemplateError,
    );
    this.renderTemplate(
      this.state.inAppBundle.contentTemplate,
      this.state.general.type.webBundle.sampleData,
      RenderInAppBundleTemplate,
      RenderInAppBundleTemplateSuccess,
      RenderInAppBundleTemplateError,
    );
  }

  setAccountGroupHydrator(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetAccountGroupHydrator({ enabled: enabled }));
    this.populateSampleDataFromHydrators(this.state.general.event, true);
  }

  setSalespersonHydrator(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetSalespersonHydrator({ enabled: enabled }));
    this.populateSampleDataFromHydrators(this.state.general.event, true);
  }

  setProductHydrator(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetProductHydrator({ enabled: enabled }));
    this.populateSampleDataFromHydrators(this.state.general.event, true);
  }

  setPartnerBrandingHydrator(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetPartnerBrandingHydrator({ enabled: enabled }));
    this.populateSampleDataFromHydrators(this.state.general.event, true);
  }

  setDigitalAgentHydrator(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetDigitalAgentHydrator({ enabled: enabled }));
    this.populateSampleDataFromHydrators(this.state.general.event, true);
  }

  setUserHydrator(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetUserHydrator({ enabled: enabled }));
    this.populateSampleDataFromHydrators(this.state.general.event, true);
  }

  setTemplateTab(tab: TemplateTab): void {
    this.state = editorReducer(this.state, new SetTemplateTab({ templateTab: tab }));
  }

  setInAppEnabled(enabled: boolean): void {
    this.state = editorReducer(
      this.state,
      new SetInAppEnabled({ enabled: enabled, locale: this.state.general.selectedLocale }),
    );
  }

  setInAppLink(link: string): void {
    this.state = editorReducer(this.state, new SetInAppLink({ link: link, locale: this.state.general.selectedLocale }));
  }

  setInAppContent(content: string): void {
    this.state = editorReducer(
      this.state,
      new SetInAppContent({ content: content, locale: this.state.general.selectedLocale }),
    );
    this.renderTemplate(
      this.state.inApp.contentTemplate,
      this.state.general.type.web.sampleData,
      RenderInAppTemplate,
      RenderInAppTemplateSuccess,
      RenderInAppTemplateError,
    );
  }

  setInAppSample(sample: string): void {
    let sampleObj = {};
    if (sample) {
      try {
        sampleObj = JSON.parse(sample);
      } catch {
        this.state = editorReducer(this.state, new RenderInAppTemplateError({ error: 'Invalid sample' }));
        return;
      }
    }
    this.state = editorReducer(this.state, new SetInAppSample({ sample: sample, sampleObj: sampleObj, edited: true }));
    this.renderTemplate(
      this.state.inApp.contentTemplate,
      this.state.general.type.web.sampleData,
      RenderInAppTemplate,
      RenderInAppTemplateSuccess,
      RenderInAppTemplateError,
    );
  }

  setInAppBundleEnabled(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetInAppBundleEnabled({ enabled: enabled }));
  }

  setInAppBundleWindow(windowMinutes: number): void {
    const w = windowMinutes * 60 + 's';
    this.state = editorReducer(this.state, new SetInAppBundleWindow({ window: w }));
  }

  setInAppBundleLink(link: string): void {
    this.state = editorReducer(
      this.state,
      new SetInAppBundleLink({ link: link, locale: this.state.general.selectedLocale }),
    );
  }

  setInAppBundleContent(content: string): void {
    this.state = editorReducer(
      this.state,
      new SetInAppBundleContent({ content: content, locale: this.state.general.selectedLocale }),
    );
    this.renderTemplate(
      this.state.inAppBundle.contentTemplate,
      this.state.general.type.webBundle.sampleData,
      RenderInAppBundleTemplate,
      RenderInAppBundleTemplateSuccess,
      RenderInAppBundleTemplateError,
    );
  }

  setInAppBundleSample(sample: string): void {
    let sampleObj = {};
    if (sample) {
      try {
        sampleObj = JSON.parse(sample);
      } catch {
        this.state = editorReducer(this.state, new RenderInAppBundleTemplateError({ error: 'Invalid sample' }));
        return;
      }
    }
    this.state = editorReducer(
      this.state,
      new SetInAppBundleSample({ sample: sample, sampleObj: sampleObj, edited: true }),
    );
    this.renderTemplate(
      this.state.inAppBundle.contentTemplate,
      this.state.general.type.webBundle.sampleData,
      RenderInAppBundleTemplate,
      RenderInAppBundleTemplateSuccess,
      RenderInAppBundleTemplateError,
    );
  }

  setEmailEnabled(enabled: boolean): void {
    this.state = editorReducer(this.state, new SetEmailEnabled({ enabled: enabled }));
  }

  setEmailContent(template: string): void {
    this.state = editorReducer(
      this.state,
      new SetEmailContent({ template: template, locale: this.state.general.selectedLocale }),
    );
    this.renderTemplate(
      this.state.email.contentTemplate,
      this.state.general.type.email.sampleData,
      RenderEmailTemplate,
      RenderEmailTemplateSuccess,
      RenderEmailTemplateError,
    );
  }

  setEmailSample(sample: string): void {
    let sampleObj = {};
    if (sample) {
      try {
        sampleObj = JSON.parse(sample);
      } catch {
        this.state = editorReducer(this.state, new RenderEmailTemplateError({ error: 'Invalid sample' }));
        return;
      }
    }
    this.state = editorReducer(this.state, new SetEmailSample({ sample: sample, sampleObj: sampleObj, edited: true }));
    this.renderTemplate(
      this.state.email.contentTemplate,
      this.state.general.type.email.sampleData,
      RenderEmailTemplate,
      RenderEmailTemplateSuccess,
      RenderEmailTemplateError,
    );
  }

  setEmailSubject(template: string): void {
    this.state = editorReducer(
      this.state,
      new SetEmailSubject({ template: template, locale: this.state.general.selectedLocale }),
    );
  }

  setEmailLink(template: string): void {
    this.state = editorReducer(
      this.state,
      new SetEmailLink({ template: template, locale: this.state.general.selectedLocale }),
    );
  }

  setEmailFromAddress(template: string): void {
    this.state = editorReducer(
      this.state,
      new SetEmailFromAddress({ template: template, locale: this.state.general.selectedLocale }),
    );
  }

  setEmailFromName(template: string): void {
    this.state = editorReducer(
      this.state,
      new SetEmailFromName({ template: template, locale: this.state.general.selectedLocale }),
    );
  }

  setEmailContentType(contentType: ContentType): void {
    this.state = editorReducer(
      this.state,
      new SetEmailContentType({
        contentType: contentType,
      }),
    );
  }

  setEmailServiceProvider(serviceProvider: string): void {
    this.state = editorReducer(
      this.state,
      new SetEmailServiceProvider({
        serviceProvider: serviceProvider,
      }),
    );
  }

  sendTest(medium: NotificationMedium, bundle: boolean): void {
    this.state = editorReducer(this.state, new SendTest());
    let sample: object;
    switch (medium) {
      case NotificationMedium.NOTIFICATION_MEDIUM_WEB:
        if (bundle) {
          sample = this.state.general.type.webBundle.sampleData;
        } else {
          sample = this.state.general.type.web.sampleData;
        }
        break;
      case NotificationMedium.NOTIFICATION_MEDIUM_EMAIL:
        sample = this.state.general.type.email.sampleData;
        break;
    }
    const event: PreviewRequestEventInterface = {
      eventTypeId: this.state.general.event.id,
      emittedAt: new Date(),
      data: sample,
    };
    this.adminService
      .preview$(this.state.general.type.notificationTypeId, medium, event)
      .pipe(
        map(() => new SendTestSuccess()),
        catchError((err: HttpErrorResponse) => {
          let message = 'Failed to render preview.';
          if (err.status < 500) {
            message = 'Failed: ' + err.error.message;
          }
          return of(new SendTestError({ error: message }));
        }),
      )
      .subscribe((action) => (this.state = editorReducer(this.state, action)));
  }
}
