@if (route) {
  <a
    matRipple
    class="glxy-nav-item-link"
    [target]="target"
    [routerLink]="route"
    [queryParams]="queryParams"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: activeExactRoute }"
  >
    <ng-container *ngTemplateOutlet="content" />
  </a>
}

@if (href) {
  <a matRipple class="glxy-nav-item-link" [href]="href" [target]="target">
    <ng-container *ngTemplateOutlet="content" />
  </a>
}

@if (!href && !route) {
  <a matRipple class="glxy-nav-item-link">
    <ng-container *ngTemplateOutlet="content" />
  </a>
}

<ng-template #content>
  <div class="glxy-nav-item-layout">
    @if (icon && !isSvg) {
      <mat-icon>{{ icon }}</mat-icon>
    }
    @if (icon && isSvg) {
      <mat-icon [svgIcon]="icon" />
    }
    <div class="glxy-nav-item-truncate"><ng-content /></div>
    <ng-content select="glxy-nav-item-accessory" />
  </div>
</ng-template>
