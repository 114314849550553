<div class="header">
  <div class="title">Preview</div>
  <div class="spacer"></div>
  <div class="send-test-message" *ngIf="!!noPreviewMessage">
    {{ noPreviewMessage }}
  </div>
  <div class="send-test" *ngIf="!noPreviewMessage">
    <div
      [matTooltipDisabled]="canSendPreview"
      matTooltip="Please save your changes before sending a test."
      *ngIf="!sendingTest"
    >
      <button mat-stroked-button color="primary" (click)="sendTest.emit()" [disabled]="!canSendPreview">
        Send Test
      </button>
    </div>
    <mat-spinner *ngIf="sendingTest" [diameter]="24"></mat-spinner>
  </div>
</div>
<mat-divider></mat-divider>
<div class="devices" *ngIf="!inApp">
  <button mat-button (click)="setFrameWidth('100%')">
    <mat-icon>laptop</mat-icon>
    Desktop
  </button>
  <button mat-button (click)="setFrameWidth('600px')">
    <mat-icon>tablet_mac</mat-icon>
    Tablet
  </button>
  <button mat-button (click)="setFrameWidth('320px')">
    <mat-icon>phone_iphone</mat-icon>
    Mobile
  </button>
</div>
<mat-divider *ngIf="!inApp"></mat-divider>
<div class="container">
  <div class="error">
    <mat-card appearance="outlined" *ngIf="error">
      <mat-icon>error_outline</mat-icon>
      {{ error }}
    </mat-card>
  </div>
  <div class="preview" *ngIf="inApp">
    <div class="sample-container">
      <div class="sample mat-elevation-z1">
        <div [innerHTML]="_html"></div>
        <div class="time">an hour ago</div>
      </div>
    </div>
  </div>
  <div class="frame-preview preview" *ngIf="!inApp">
    <iframe #frame class="frame" sandbox="allow-same-origin allow-popups" [style.width]="width"></iframe>
  </div>
</div>
