import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
  templateUrl: 'unsaved-changes-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsavedChangesDialogComponent {
  constructor(public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
