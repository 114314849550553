import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';

import 'brace';
import 'brace/mode/json';
import 'brace/theme/dawn';

@Component({
  selector: 'notification-manage-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatePreviewComponent implements AfterViewInit {
  @Input() inApp = true;

  @Input() loading: boolean;
  @Input() error: string;

  @Input() noPreviewMessage: string;
  @Input() canSendPreview: boolean;
  @Input() sendingTest: boolean;
  @Output() sendTest: EventEmitter<void> = new EventEmitter();

  @ViewChild('frame') frame;

  width = '100%';

  public _html: string;
  @Input() public set html(value: string) {
    if (!this.inApp && this.frame) {
      this._html = value;
      this.updateIFramePreview(this._html);
    } else {
      this._html = value;
    }
  }

  ngAfterViewInit(): void {
    if (!this.inApp) {
      // hacky, but nativeElement.contentDocument will be missing sometimes without this.
      window.setTimeout(() => this.updateIFramePreview(this._html), 0);
    }
  }

  private updateIFramePreview(value: string): void {
    if (!value || !this.frame) {
      return;
    }
    const doc = this.frame.nativeElement.contentDocument;
    if (!doc) {
      return;
    }
    doc.open();
    doc.write(value);
    doc.close();
  }

  public setFrameWidth(width: string): void {
    this.width = width;
  }
}
