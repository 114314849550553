import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'notification-manage-error',
  styleUrls: ['./error.component.scss'],
  templateUrl: 'error.component.html',
})
export class ErrorComponent {
  @Output() retry: EventEmitter<void> = new EventEmitter();
}
