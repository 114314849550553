import { Clipboard } from '@angular/cdk/clipboard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { AccessInterceptor } from '../../core/access/interceptor';
import { PlaygroundAuthService } from './playground-auth.service';

// Factory produces a storage class for the playground
function storageFactory(): OAuthStorage {
  class PlaygroundLocalStorage implements Storage {
    public store: Storage = window.localStorage;
    private keyPrefix: string;

    constructor(keyPrefix: string) {
      this.keyPrefix = keyPrefix;
    }

    [name: string]: any;

    get length(): number {
      return this.store.length;
    }

    clear(): void {
      this.store.clear();
    }

    getItem(key: string): string | null {
      return this.store.getItem(this.keyPrefix + key);
    }

    key(index: number): string | null {
      return this.store.key(index);
    }

    removeItem(key: string): void {
      this.store.removeItem(this.keyPrefix + key);
    }

    setItem(key: string, value: string): void {
      this.store.setItem(this.keyPrefix + key, value);
    }
  }

  return new PlaygroundLocalStorage('playground-');
}

@Component({
  selector: 'app-oauth2-playground',
  templateUrl: './oauth2-playground.component.html',
  styleUrls: ['./oauth2-playground.component.scss'],
  providers: [
    PlaygroundAuthService,
    OAuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AccessInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
})
export class Oauth2PlaygroundComponent implements OnInit {
  public form: UntypedFormGroup;
  public accessToken$: Observable<string>;

  constructor(
    private alertService: SnackbarService,
    private authService: PlaygroundAuthService,
    private storage: OAuthStorage,
    private renderer2: Renderer2,
    private clipboard: Clipboard,
  ) {
    this.form = new UntypedFormGroup({
      scopes: new UntypedFormControl(''),
    });
    this.accessToken$ = this.authService.getToken();
  }

  ngOnInit(): void {
    // To remove the padding added by atlas (atlas can't be used on this page)
    this.renderer2.setStyle(document.querySelector('body'), 'padding', '0');
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.alertService.openErrorSnack('Form invalid, make sure you have filled out the required fields.');
      return;
    }
    this.storage.removeItem('access_token');
    this.authService.submitPlaygroundTokenRequest(this.scopes);
  }

  copyToken(token: string): void {
    this.clipboard.copy(token);
    this.alertService.openSuccessSnack('Copied token!');
  }

  get scopes(): string {
    return this.form.get('scopes').value;
  }
}
