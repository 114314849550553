import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EditorService } from '../../editor.service';
import { Locale, LOCALES } from '../../locales';

@Component({
  styleUrls: ['./add-locale-dialog.component.scss'],
  templateUrl: 'add-locale-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLocaleDialogComponent {
  additionalLocaleOptions = LOCALES;
  localeOptions: Locale[] = [];
  constructor(public dialogRef: MatDialogRef<AddLocaleDialogComponent>, private editorService: EditorService) {
    this.localeOptions = Object.values(this.additionalLocaleOptions).filter((opt) => {
      return Object.keys(this.editorService.state.locales).indexOf(opt.value) < 0;
    });
  }

  confirm(selectedLocale: string): void {
    this.dialogRef.close(selectedLocale);
  }
}
