import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment, EnvironmentService } from '@galaxy/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

// WARNING: do not copy the code in this file. It's a hacky workaround.
//
// See AuthService instead for an example of how to configure the @vendasta/oauth2 library.

interface Claim {
  sub: string;
}

interface AuthConfigs {
  [env: number]: {
    issuer: string;
    redirectUri: string;
    clientId: string;
  };
}

const baseAuthConfig = {
  // This is required because IAM and SSO serve different parts of the oauth2 workflow
  // IAM issues the tokens but sso handles auth and exchange of codes for tokens
  strictDiscoveryDocumentValidation: false,

  // response type must be 'code' for PKCE flow
  responseType: 'code',
};

const authConfigForEnv: AuthConfigs = {
  [Environment.LOCAL]: {
    issuer: 'https://iam-demo.apigateway.co',
    redirectUri: 'https://localhost:4200/playground',
    clientId: '50aa6d1c-03a2-4784-9b68-681f82abb499',
  },
  [Environment.DEMO]: {
    issuer: 'https://iam-demo.apigateway.co',
    redirectUri: 'https://admin-demo.vendasta-internal.com/playground',
    clientId: '50aa6d1c-03a2-4784-9b68-681f82abb499',
  },
  [Environment.PROD]: {
    issuer: 'https://iam-prod.apigateway.co',
    redirectUri: 'https://admin.vendasta-internal.com/playground',
    clientId: 'dd9a644d-d555-4bfd-a7db-8b0d7e65fafe',
  },
};

declare let environment: string;

// PlaygroundAuthService contains some hacky copypasta to allow 2 different oauth2 apps to be active in the same ng app.
//
// DON'T COPY THIS CODE--see AuthService for a good example of how to configure @vendasta/oauth2
@Injectable()
export class PlaygroundAuthService {
  public userId$: Observable<string>;
  private readonly accessToken$: Observable<string>;
  private discoveryLoaded$: Observable<boolean>;
  private playgroundSubmit$$: Subject<string> = new Subject<string>();

  private get authConfig(): AuthConfig {
    let env = this.environmentService.getEnvironment();
    // I manually check the environment variable, the rest of the app is set to
    // pull data from demo, but I need to redirect back to localhost for auth.
    if (typeof environment === 'undefined') {
      env = Environment.LOCAL;
    }
    const config = authConfigForEnv[env];
    return { ...config, ...baseAuthConfig };
  }

  constructor(
    private oauthService: OAuthService,
    private environmentService: EnvironmentService,
    private router: Router,
    private alertService: SnackbarService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.oauthService.configure(this.authConfig);

    // If we load the playground and a code is present we're here from redirect, so try logging in
    if (
      this.router.routerState.snapshot.root.queryParams['code'] ||
      this.router.routerState.snapshot.root.queryParams['error']
    ) {
      this.oauthService.loadDiscoveryDocumentAndTryLogin().catch(() => {
        const params = this.activatedRoute.snapshot.queryParams;
        // Get error from params and show it to the user
        const err = params['error'];
        if (err) {
          const errDesc = params['error_description'] || 'An unknown error occurred';
          this.alertService.openErrorSnack(errDesc);
          console.error(errDesc);
        }
      });
    }

    // Emit on initial load (from after oauth redirect), and on submission click
    this.discoveryLoaded$ = this.playgroundSubmit$$.pipe(
      switchMap((scopes: string) => {
        const authConfig = this.authConfig;
        authConfig.scope = scopes;
        this.oauthService.configure(authConfig);
        // loadDiscoveryDocumentAndTryLogin will set the access_token value in storage if it can, specifically in the tryLogin() part of it
        return this.oauthService.loadDiscoveryDocumentAndTryLogin().catch(() => {
          // do nothing
        });
      }),
      map(() => true),
      shareReplay(1),
    );

    this.discoveryLoaded$.subscribe(() => {
      this.oauthService.initCodeFlow();
    });

    this.userId$ = this.oauthService.events.pipe(
      map(() => this.getUserId()),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.accessToken$ = this.oauthService.events.pipe(
      filter((e) => e.type === 'token_received' || e.type === 'token_refreshed'),
      map(() => this.oauthService.getAccessToken()),
      startWith(this.oauthService.getAccessToken()),
      filter((sessionId) => !!sessionId),
      shareReplay(1),
    );
  }

  private getUserId(): string {
    const claims = this.oauthService.getIdentityClaims() as Claim;
    if (!claims) {
      return '';
    }
    return claims.sub;
  }

  public getToken(): Observable<string> {
    // AccessToken is the 'session id'.
    return this.accessToken$;
  }

  public submitPlaygroundTokenRequest(scopes: string): void {
    this.playgroundSubmit$$.next(scopes);
  }
}
