export const DEFAULT_LOCALE = 'en-US';
export const LOCALES: { [key: string]: Locale } = {
  'en-US': { value: 'en-US', label: 'English' },
  cs: { value: 'cs', label: 'Czech' },
  nl: { value: 'nl', label: 'Dutch' },
  'fr-fr': { value: 'fr-fr', label: 'French (France)' },
  'fr-ca': { value: 'fr-ca', label: 'French (Canada)' },
  pt: { value: 'pt', label: 'Portuguese' },
  it: { value: 'it', label: 'Italian' },
  de: { value: 'de', label: 'German' },
  'es-419': { value: 'es-419', label: 'Spanish' },
};
export interface Locale {
  value: string;
  label: string;
}
