import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthedAppComponent } from './authed-app.component';
import { LogoutComponent } from './pages/logout';
import {
  CreateComponent as NotificationsCreateComponent,
  EditComponent as NotificationsEditComponent,
  ListComponent as NotificationsListComponent,
  ReleaseComponent as NotificationsReleaseComponent,
} from './pages/notifications';
import { Oauth2PlaygroundComponent } from './pages/oauth2-playground/oauth2-playground.component';
import { OAuth2Module, OAuth2Service } from '@vendasta/oauth2';
import { OAuthModule } from 'angular-oauth2-oidc';

const defaultRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/dashboard/dashboard.component').then((mod) => mod.DashboardComponent),
  },

  {
    path: 'email',
    loadChildren: () => import('./pages/email/email.module').then((m) => m.EmailModule),
  },

  { path: 'notifications', component: NotificationsListComponent },

  { path: 'notifications/create', component: NotificationsCreateComponent }, // TODO: glxy-pagify (requires larger refactor)

  { path: 'notifications/edit/:typeId', component: NotificationsEditComponent }, // TODO: glxy-pagify (requires larger refactor)

  { path: 'notifications/release/:typeId', component: NotificationsReleaseComponent }, // TODO: glxy-pagify (requires larger refactor)

  {
    path: 'integrations',
    loadChildren: () => import('./pages/integrations/integrations.module').then((m) => m.IntegrationsModule),
  },

  {
    path: 'scopes',
    loadChildren: () =>
      import('./pages/oauth2-scope-management/oauth2-scope-management.module').then(
        (m) => m.Oauth2ScopeManagementModule,
      ),
  },

  {
    path: 'loop',
    loadChildren: () => import('./pages/the-loop/the-loop.module').then((m) => m.TheLoopModule),
  },

  {
    path: 'automations',
    loadChildren: () => import('./pages/automations/automations.module').then((m) => m.AutomationsModule),
  },

  {
    path: 'service-accounts',
    loadChildren: () => import('./pages/service-accounts/service-accounts.module').then((m) => m.ServiceAccountsModule),
  },

  {
    path: 'domain-management',
    loadChildren: () => import('./pages/host-domains/host-domains.module').then((m) => m.HostDomainsModule),
  },
  {
    path: 'business-categories',
    loadChildren: () =>
      import('./pages/business-categories/business-categories.module').then((m) => m.BusinessCategoriesModule),
  },
  {
    path: 'business-category-mapping',
    loadChildren: () => import('./pages/business-category-mapping/mapping.module').then((m) => m.MappingModule),
  },
  {
    path: 'google-business-information',
    loadChildren: () =>
      import('./pages/google-business-information/google-business-information.module').then(
        (m) => m.GoogleBusinessInformationModule,
      ),
  },
  {
    path: 'facebook-page-information',
    loadChildren: () =>
      import('./pages/facebook-page-information/facebook-page-information.module').then(
        (m) => m.FacebookPageInformationModule,
      ),
  },
  {
    path: 'apple-information',
    loadChildren: () =>
      import('./pages/apple-information/apple-information.module').then((m) => m.AppleInformationModule),
  },
  {
    path: 'bing-information',
    loadChildren: () => import('./pages/bing-information/bing-information.module').then((m) => m.BingInformationModule),
  },
  {
    path: 'feature-access',
    loadChildren: () => import('./pages/feature-access/feature-access.module').then((m) => m.FeatureAccessModule),
  },
  {
    path: 'event-broker-events',
    loadChildren: () => import('./pages/event-broker/event-broker.module').then((m) => m.EventBrokerModule),
  },
  {
    path: 'ai-assistant',
    loadChildren: () => import('./pages/ai-assistant/ai-assistant.module').then((m) => m.AiAssistantModule),
  },
  {
    path: 'conversation-analysis',
    loadChildren: () =>
      import('./pages/conversation-analysis/conversation-analysis.module').then((m) => m.ConversationAnalysisModule),
  },
];

@NgModule({
  imports: [
    OAuth2Module,
    OAuthModule,
    RouterModule.forRoot(
      [
        { path: 'logout', component: LogoutComponent },
        { path: 'playground', component: Oauth2PlaygroundComponent },
        { path: '', canActivate: [OAuth2Service], component: AuthedAppComponent, children: defaultRoutes },
      ],
      {},
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
