{
  "COMMON": {
    "ERROR": "Error",
    "SUCCESS": "SUCCESS",
    "ACTION_LABELS": {
      "NO_RESULTS": "No results",
      "CANCEL": "Cancel",
      "NONE_LABEL": "None",
      "UPDATE": "Update"
    },
    "TABLE": {
      "SORT": {
        "SORT_COLUMN_ORDER_DESCRIPTION_TEXT": {
          "SORT_BY": "Sort By",
          "THEN_SORT_BY": "Then Sort By"
        },
        "SELECT_COLUMN_PLACEHOLDER": "Add another column to sort by",
        "SELECT_COLUMN_PLACEHOLDER_EMPTY_STATE": "Choose a column to sort by",
        "SORT_TOGGLE_LABELS": {
          "A_TO_Z": "A to Z",
          "Z_TO_A": "Z to A",
          "_1_TO_9": "1 to 9",
          "_9_TO_1": "9 to 1",
          "EARLIEST_TO_LATEST": "Oldest first",
          "LATEST_TO_EARLIEST": "Newest first"
        }
      }
    }
  },
  "COPY_TO_CLIPBOARD": {
    "COPY": "Copy",
    "COPIED": "Copied to clipboard"
  },
  "EMAIL_REVIEW": {
    "MORE_INFO": "More Info",
    "SPAM": {
      "SPAM": "Spam",
      "IS_SPAM": "Is Spam",
      "SCORE": "Spam Score"
    }
  },
  "NAVIGATION": {
    "EMAIL_HISTORY": "E-mail History"
  }
}
