<!-- Atlas Navbar -->
<atlas-navbar [hideNotifications]="false" [hideCenters]="false" [dropdownItems]="dropdownItems">
  <atlas-item class="hide-on-md-up">
    <button mat-icon-button (click)="nav.toggle()" class="toggle-menu">
      <mat-icon class="nav-toggle">menu</mat-icon>
    </button>
  </atlas-item>
  <atlas-title interactable>Vendasta Center</atlas-title>
  <div class="spacer" style="margin-right: auto"></div>
</atlas-navbar>

<!-- Main page content -->
<glxy-nav #nav [fixedTopGap]="40" appName="vcc">
  <glxy-nav-panel>
    <mat-nav-list>
      <a mat-list-item routerLink="/" routerLinkActive="active-nav" [routerLinkActiveOptions]="{ exact: true }">
        <mat-icon matListItemIcon>dashboard</mat-icon>
        <span matListItemTitle>Dashboard</span>
      </a>

      <div mat-subheader>Feature flags</div>

      <a mat-list-item routerLink="/feature-access/list-features" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>lock</mat-icon>
        <span matListItemTitle>Feature flags</span>
      </a>

      <div mat-subheader>Email and Notifications</div>

      <a mat-list-item routerLink="/email" routerLinkActive="active-nav" [routerLinkActiveOptions]="{ exact: true }">
        <mat-icon matListItemIcon>email</mat-icon>
        <span matListItemTitle>Email History</span>
      </a>

      <a mat-list-item routerLink="/email/admin" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>adjust</mat-icon>
        <span matListItemTitle>Email Master Control</span>
      </a>

      <a mat-list-item routerLink="/email/users" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>person</mat-icon>
        <span matListItemTitle>Email Users</span>
      </a>

      <a mat-list-item routerLink="/email/senders" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>person</mat-icon>
        <span matListItemTitle>Email Sender</span>
      </a>

      <a mat-list-item routerLink="/email/sendgrid" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>api</mat-icon>
        <span matListItemTitle>SendGrid</span>
      </a>

      <a mat-list-item routerLink="/event-broker-events" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>event_seat</mat-icon>
        <span matListItemTitle>Event Broker Events</span>
      </a>

      <a mat-list-item routerLink="/notifications" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>notifications</mat-icon>
        <span matListItemTitle>Notifications</span>
      </a>

      <a mat-list-item routerLink="/loop" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>all_inclusive</mat-icon>
        <span matListItemTitle>The Loop</span>
      </a>

      <div mat-subheader>AI assistant</div>

      <a mat-list-item routerLink="/ai-assistant/settings" routerLinkActive="active-nav">
        <mat-icon matListItemIcon svgIcon="galaxy-ai-icon" />
        <span matListItemTitle>AI Assistant Settings</span>
      </a>
      <a mat-list-item routerLink="/ai-assistant/knowledge-base" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>menu_book</mat-icon>
        <span matListItemTitle>Manage Knowledge Base</span>
      </a>
      <a mat-list-item routerLink="/ai-assistant/prompt-management" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>terminal</mat-icon>
        <span matListItemTitle>Manage Prompts</span>
      </a>

      <div mat-subheader>Accounts and Auth</div>

      <a mat-list-item routerLink="/integrations" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>call_merge</mat-icon>
        <span matListItemTitle>Integrations</span>
      </a>

      <a mat-list-item routerLink="/service-accounts" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
        <span matListItemTitle>Service Accounts</span>
      </a>

      <a mat-list-item routerLink="/scopes" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>pageview</mat-icon>
        <span matListItemTitle>OAuth2 Scopes</span>
      </a>

      <a mat-list-item routerLink="/playground" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>policy</mat-icon>
        <span matListItemTitle>OAuth2 Playground</span>
      </a>

      <div mat-subheader>External Tools</div>

      <a mat-list-item href="http://weblate.apigateway.co" target="_blank">
        <mat-icon matListItemIcon>translate</mat-icon>
        <span matListItemTitle>Weblate Translation Portal</span>
      </a>

      <div mat-subheader>Listings Admin Tools</div>

      <a mat-list-item routerLink="/google-business-information" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>toll</mat-icon>
        <span matListItemTitle>Google Business Information of Awesomeness</span>
      </a>

      <a mat-list-item routerLink="/facebook-page-information" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>pages</mat-icon>
        <span matListItemTitle>Facebook Page Information (Very Cool)</span>
      </a>

      <a mat-list-item routerLink="/apple-information" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>domain</mat-icon>
        <span matListItemTitle>Apple Information Hooray</span>
      </a>

      <a mat-list-item routerLink="/bing-information" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>storefront</mat-icon>
        <span matListItemTitle>Bing Information Yippee</span>
      </a>

      <div mat-subheader>In Development</div>

      <a mat-list-item routerLink="/domain-management" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>web</mat-icon>
        <span matListItemTitle>App Host Domains</span>
      </a>
      <a mat-list-item routerLink="/business-categories" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>cake</mat-icon>
        <span matListItemTitle>Business Categories of Excellence</span>
      </a>
      <a mat-list-item routerLink="/business-category-mapping" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>fact_check</mat-icon>
        <span matListItemTitle>Business Category Mapping</span>
      </a>

      <div mat-subheader>Automations</div>
      <a mat-list-item routerLink="/automations/task-definitions/list" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>offline_bolt</mat-icon>
        <span matListItemTitle>Task Definitions</span>
      </a>

      <a mat-list-item routerLink="/automations/event-trigger-definitions/list" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>offline_bolt</mat-icon>
        <span matListItemTitle>Event Trigger Definitions</span>
      </a>

      <div mat-subheader>Conversation Analysis</div>
      <a mat-list-item routerLink="/conversation-analysis/import" routerLinkActive="active-nav">
        <mat-icon matListItemIcon>interpreter_mode</mat-icon>
        <span matListItemTitle>Import Raw Transcript </span>
      </a>
    </mat-nav-list>
  </glxy-nav-panel>

  <!-- Page Content -->
  <router-outlet />
</glxy-nav>
