import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Papa, ParseResult } from 'ngx-papaparse';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationMedium } from '@vendasta/notifications-sdk';
import { FileInput } from 'ngx-material-file-input';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';
import { AdminService } from '../../common/notifications/notifications-admin.service';

interface FormState {
  typeId: string;
  medium: NotificationMedium;
}

interface State {
  isLoading: boolean;
  hasError: boolean;
  isSubmitting: boolean;
  name: string;
  availableMediums: NotificationMedium[];
  userIds: string[];
}

const INITIAL_STATE = {
  isLoading: true,
  hasError: false,
  isSubmitting: false,
  availableMediums: [],
  name: '',
  userIds: [],
};

@Component({
  selector: 'notification-release-form',
  templateUrl: './release-form.component.html',
  styleUrls: ['./release-form.component.scss'],
})
export class ReleaseFormComponent implements OnInit {
  @Input() listRoute: string;
  @Input() typeId: string;

  private readonly store = new BehaviorSubject<State>({ ...INITIAL_STATE });
  private get state(): State {
    return this.store.getValue();
  }
  private set state(newState: State) {
    this.store.next(newState);
  }

  public readonly isLoading$: Observable<boolean> = this.store.pipe(
    map((state) => state.isLoading),
    distinctUntilChanged(),
  );
  public readonly showError$: Observable<boolean> = this.store.pipe(
    map((state) => !state.isLoading && state.hasError),
    distinctUntilChanged(),
  );
  public readonly showContent$: Observable<boolean> = this.store.pipe(
    map((state) => !state.isLoading && !state.hasError),
    distinctUntilChanged(),
  );
  public readonly isSubmitting$: Observable<boolean> = this.store.pipe(
    map((state) => state.isSubmitting),
    distinctUntilChanged(),
  );
  public readonly name$: Observable<string> = this.store.pipe(
    map((state) => state.name),
    distinctUntilChanged(),
  );
  public readonly availableMediums$: Observable<NotificationMedium[]> = this.store.pipe(
    map((state) => state.availableMediums),
    distinctUntilChanged(),
  );
  public readonly userIds$: Observable<string[]> = this.store.pipe(
    map((state) => state.userIds),
    distinctUntilChanged(),
  );
  public readonly userIdsSet$: Observable<boolean> = this.store.pipe(
    map((state) => state.userIds.length > 0),
    distinctUntilChanged(),
  );

  public readonly form = this.fb.group({
    typeId: this.fb.control('', [Validators.required, Validators.pattern('[a-z-]+')]),
    medium: this.fb.control('', Validators.required),
    file: this.fb.control(null, Validators.required),
  });

  constructor(
    @Inject('AdminService') private notificationsAdminService: AdminService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private papa: Papa,
  ) {}

  ngOnInit(): void {
    if (!this.typeId) {
      this.state = { ...this.state, isLoading: false, hasError: true };
    } else {
      this.form.get('typeId').disable();
      this.state = { ...this.state };
      this.loadType();
    }

    this.onChanges();
  }

  onChanges(): void {
    this.form.get('file').valueChanges.subscribe((input: FileInput) => {
      this.state = { ...this.state, userIds: [] };
      // reset user ids if the file is removed
      if (!input || !input.files[0]) {
        return;
      }
      this.papa.parse(input.files[0], {
        header: false,
        delimiter: ',',
        complete: (results: ParseResult) => {
          if (!results.data[0][0].startsWith('U')) {
            this.form.get('file').setErrors({ header: 'The file you uploaded has a header, please remove it.' });
            return;
          }
          const userIds = results.data.map((r: string[]) => {
            return r[0];
          });
          this.state = { ...this.state, userIds: userIds };
        },
        error: (error) => this.form.get('file').setErrors({ parse: 'There was an error parsing the CSV: ' + error }),
      });
    });
  }

  loadType(): void {
    this.state = { ...this.state, isLoading: true, hasError: false };
    this.notificationsAdminService
      .getType$(this.typeId)
      .pipe(
        tap((t) => {
          this.state = { ...this.state, name: t.name, isLoading: false };
          this.form.patchValue({
            typeId: t.notificationTypeId,
          });
          if (t.configurations) {
            this.state = { ...this.state, availableMediums: t.listEnabledMediums() };
          } else {
            throw new Error('type has no enabled mediums');
          }
        }),
        catchError(() => {
          this.state = { ...this.state, isLoading: false, hasError: true };
          return EMPTY;
        }),
      )
      .subscribe();
  }

  mediumToString(medium: NotificationMedium): string {
    if (medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB) {
      return 'In-App';
    } else if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
      return 'Email';
    }
    return 'Unknown';
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.snackBar.open(
        'The form has errors, please correct them before attempting to release the notification.',
        null,
        { duration: 2400 },
      );
      return;
    }
    this.update();
    this.state = { ...this.state, isSubmitting: true };
  }

  private update(): void {
    const vals: FormState = this.form.getRawValue();
    this.notificationsAdminService
      .releaseType$(vals.typeId, vals.medium, this.state.userIds)
      .pipe(
        tap(() => this.snackBar.open('Notification released.', null, { duration: 2400 })),
        catchError(() => {
          this.snackBar.open('Failed to release notification.', null, { duration: 2400 });
          return EMPTY;
        }),
        finalize(() => (this.state = { ...this.state, isSubmitting: false })),
      )
      .subscribe();
  }
}
