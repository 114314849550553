import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Data {
  typeId: string;
  name: string;
}

@Component({
  styleUrls: ['./delete-type-dialog.component.scss'],
  templateUrl: 'delete-type-dialog.component.html',
})
export class DeleteTypeDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
