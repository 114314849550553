import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GalaxyNavControlService {
  readonly isOpen = signal(true);

  toggle(): void {
    this.isOpen.update((isOpen) => !isOpen);
  }

  open(): void {
    this.isOpen.set(true);
  }

  close(): void {
    this.isOpen.set(false);
  }

  closeForThisPage(): void {
    this.isOpen.set(false);
  }

  undoCloseForThisPage(): void {
    this.isOpen.set(true);
  }
}
