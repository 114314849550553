import { EventTypeInterface } from '@vendasta/event-broker';
import { AccessRequirement } from '@vendasta/notifications-sdk';
import { NotificationType } from '../../../common/notifications/notifications-admin.service';
import { GeneralActionTypes } from '../actions/general.actions';
import { Application } from '../application';
import { ActionTypes, EditorActions } from '../editor.actions';
import { State } from '../editor.reducers';

export enum TemplateTab {
  InApp,
  InAppBundle,
  Email,
}

export interface GeneralState {
  new: boolean;
  saving: boolean;
  savingSuccess: boolean;
  savingError: any;
  deleting: boolean;
  deletingSuccess: boolean;
  deletingError: any;
  edited: boolean;
  type: NotificationType;
  typeLoading: boolean;
  typeError: any;

  event: EventTypeInterface;
  eventLoading: boolean;
  eventError: any;
  accountGroupDataAvailable: boolean;
  salespersonDataAvailable: boolean;
  productDataAvailable: boolean;
  partnerBrandingDataAvailable: boolean;
  digitalAgentDataAvailable: boolean;
  userDataAvailable: boolean;

  events: EventTypeInterface[];
  searchTerm: string;
  eventsCursor: string;
  eventsHasMore: boolean;
  eventsLoading: boolean;
  eventsError: any;

  applications: Application[];
  applicationsLoading: boolean;
  applicationsError: any;

  sendTestLoading: boolean;
  sendTestSuccess: boolean;
  sendTestError: any;

  selectedLocale: string;
  templateTab: TemplateTab;
}

export function initialGeneralState(): GeneralState {
  return {
    new: true,
    saving: false,
    savingSuccess: false,
    savingError: null,
    deleting: false,
    deletingSuccess: false,
    deletingError: false,
    edited: false,
    type: null,
    typeLoading: false,
    typeError: null,

    event: null,
    eventLoading: false,
    eventError: null,
    accountGroupDataAvailable: false,
    salespersonDataAvailable: false,
    productDataAvailable: false,
    partnerBrandingDataAvailable: false,
    digitalAgentDataAvailable: false,
    userDataAvailable: false,

    events: [],
    eventsCursor: '',
    eventsHasMore: false,
    eventsLoading: false,
    searchTerm: '',
    eventsError: null,

    applications: [],
    applicationsLoading: false,
    applicationsError: null,

    sendTestLoading: false,
    sendTestSuccess: false,
    sendTestError: null,

    selectedLocale: '',
    templateTab: TemplateTab.InApp,
  };
}

export function generalReducer(nextState: State, action: EditorActions): State {
  switch (action.type) {
    case ActionTypes.INIT:
      nextState.general.type = new NotificationType({
        notificationTypeId: '',
        category: [],
        description: '',
        name: 'New Notification',
        eventTypeId: '',
        configurations: [],
        domainId: '',
        hydrators: [],
        accessRequirements: [],
        personaAccessRequirements: [],
        web: {
          enabled: false,
          sampleData: {},
        },
        webBundle: {
          enabled: false,
          sampleData: {},
        },
        email: {
          enabled: false,
          sampleData: {},
        },
      });
      break;
    case GeneralActionTypes.SAVE:
      nextState.general.saving = true;
      nextState.general.savingSuccess = false;
      nextState.general.savingError = null;
      break;
    case GeneralActionTypes.SAVE_SUCCESS:
      nextState.general.new = false;
      nextState.general.edited = false;
      nextState.general.saving = false;
      nextState.general.savingSuccess = true;
      break;
    case GeneralActionTypes.SAVE_ERROR:
      nextState.general.saving = false;
      nextState.general.savingSuccess = false;
      nextState.general.savingError = action.payload.error;
      break;
    case GeneralActionTypes.DELETE:
      nextState.general.deleting = true;
      nextState.general.deletingSuccess = false;
      nextState.general.deletingError = null;
      break;
    case GeneralActionTypes.DELETE_SUCCESS:
      nextState.general.deleting = false;
      nextState.general.deletingSuccess = true;
      break;
    case GeneralActionTypes.DELETE_ERROR:
      nextState.general.deleting = false;
      nextState.general.deletingError = action.payload.error;
      break;
    case GeneralActionTypes.LOAD_TYPE:
      nextState.general.new = false;
      nextState.general.type = null;
      nextState.general.typeLoading = true;
      nextState.general.typeError = null;
      break;
    case GeneralActionTypes.LOAD_TYPE_SUCCESS:
      nextState.general.type = action.payload.type;
      nextState.general.typeLoading = false;
      break;
    case GeneralActionTypes.LOAD_TYPE_ERROR:
      nextState.general.typeLoading = false;
      nextState.general.typeError = action.payload.error;
      break;
    case GeneralActionTypes.LOAD_EVENT:
      nextState.general.eventLoading = true;
      nextState.general.event = null;
      break;
    case GeneralActionTypes.LOAD_EVENT_SUCCESS:
      nextState.general.eventLoading = false;
      nextState.general.event = action.payload.event;
      break;
    case GeneralActionTypes.LOAD_EVENT_ERROR:
      nextState.general.eventLoading = false;
      nextState.general.eventError = action.payload.error;
      break;
    case GeneralActionTypes.LOAD_EVENTS:
      nextState.general.eventsLoading = true;
      nextState.general.eventsCursor = '';
      nextState.general.searchTerm = action.payload.searchTerm;
      nextState.general.events = [];
      break;

    case GeneralActionTypes.LOAD_MORE_EVENTS:
      nextState.general.eventsLoading = true;
      break;
    case GeneralActionTypes.SET_EVENT:
      nextState.general.event = action.payload.event;
      break;
    case GeneralActionTypes.SET_ACCOUNT_GROUP_DATA_AVAILABLE:
      nextState.general.accountGroupDataAvailable = action.payload.enabled;
      break;
    case GeneralActionTypes.SET_SALESPERSON_DATA_AVAILABLE:
      nextState.general.salespersonDataAvailable = action.payload.enabled;
      break;
    case GeneralActionTypes.SET_PRODUCT_DATA_AVAILABLE:
      nextState.general.productDataAvailable = action.payload.enabled;
      break;
    case GeneralActionTypes.SET_PARTNER_BRANDING_DATA_AVAILABLE:
      nextState.general.partnerBrandingDataAvailable = action.payload.enabled;
      break;
    case GeneralActionTypes.SET_DIGITAL_AGENT_DATA_AVAILABLE:
      nextState.general.digitalAgentDataAvailable = action.payload.enabled;
      break;
    case GeneralActionTypes.SET_USER_DATA_AVAILABLE:
      nextState.general.userDataAvailable = action.payload.enabled;
      break;
    case GeneralActionTypes.LOAD_EVENTS_SUCCESS:
      nextState.general.eventsLoading = false;
      nextState.general.eventsCursor = action.payload.cursor;
      nextState.general.eventsHasMore = action.payload.hasMore;
      nextState.general.events = nextState.general.events.concat(action.payload.events);
      break;
    case GeneralActionTypes.LOAD_EVENTS_ERROR:
      nextState.general.eventsLoading = false;
      nextState.general.eventsError = action.payload.error;
      break;
    case GeneralActionTypes.LOAD_APPLICATIONS:
      nextState.general.applicationsLoading = true;
      nextState.general.applications = [];
      break;
    case GeneralActionTypes.LOAD_APPLICATIONS_SUCCESS:
      nextState.general.applicationsLoading = false;
      nextState.general.applications = action.payload.applications;
      break;
    case GeneralActionTypes.LOAD_APPLICATIONS_ERROR:
      nextState.general.applicationsLoading = false;
      nextState.general.applicationsError = action.payload.error;
      break;
    case GeneralActionTypes.SET_IDENTIFIER:
      nextState.general.edited = true;
      nextState.general.type.notificationTypeId = action.payload.identifier;
      break;
    case GeneralActionTypes.SET_NAME:
      nextState.general.edited = true;
      nextState.general.type.name = action.payload.name;
      break;
    case GeneralActionTypes.SET_DESCRIPTION:
      nextState.general.edited = true;
      nextState.general.type.description = action.payload.description;
      break;
    case GeneralActionTypes.SET_CATEGORY:
      nextState.general.edited = true;
      nextState.general.type.category = [action.payload.category];
      break;
    case GeneralActionTypes.SET_DOMAIN_ID:
      nextState.general.edited = true;
      nextState.general.type.domainId = action.payload.domainId;
      break;
    case GeneralActionTypes.SET_PERSONA_TYPES:
      nextState.general.edited = true;
      nextState.general.type.personaAccessRequirements = action.payload.personaTypes;
      break;
    case GeneralActionTypes.ADD_RESOURCE: {
      nextState.general.edited = true;
      const ar = new AccessRequirement({
        resource: { id: '' },
        scopeIds: [''],
      });
      if (nextState.general.type.accessRequirements) {
        nextState.general.type.accessRequirements.push(ar);
      } else {
        nextState.general.type.accessRequirements = [ar];
      }
      break;
    }
    case GeneralActionTypes.SET_RESOURCE:
      if (action.payload.resourceIndex > nextState.general.type.accessRequirements.length) {
        break;
      }
      nextState.general.edited = true;
      nextState.general.type.accessRequirements[action.payload.resourceIndex].resource.id = action.payload.resourceName;
      break;
    case GeneralActionTypes.REMOVE_RESOURCE:
      if (action.payload.resourceIndex > nextState.general.type.accessRequirements.length) {
        break;
      }
      nextState.general.edited = true;
      nextState.general.type.accessRequirements.splice(action.payload.resourceIndex, 1);
      break;
    case GeneralActionTypes.ADD_SCOPE:
      if (action.payload.resourceIndex > nextState.general.type.accessRequirements.length) {
        break;
      }
      nextState.general.edited = true;
      nextState.general.type.accessRequirements[action.payload.resourceIndex].scopeIds.push('');
      break;
    case GeneralActionTypes.SET_SCOPE:
      if (action.payload.resourceIndex > nextState.general.type.accessRequirements.length) {
        break;
      }
      if (
        action.payload.scopeIndex >
        nextState.general.type.accessRequirements[action.payload.resourceIndex].scopeIds.length
      ) {
        break;
      }
      nextState.general.edited = true;
      nextState.general.type.accessRequirements[action.payload.resourceIndex].scopeIds[action.payload.scopeIndex] =
        action.payload.scopeId;
      break;
    case GeneralActionTypes.REMOVE_SCOPE:
      if (action.payload.resourceIndex > nextState.general.type.accessRequirements.length) {
        break;
      }
      if (
        action.payload.scopeIndex >
        nextState.general.type.accessRequirements[action.payload.resourceIndex].scopeIds.length
      ) {
        break;
      }
      nextState.general.edited = true;
      nextState.general.type.accessRequirements[action.payload.resourceIndex].scopeIds.splice(
        action.payload.scopeIndex,
        1,
      );
      break;
    case GeneralActionTypes.SET_SELECTED_LOCALE:
      if (Object.keys(nextState.locales).indexOf(action.payload.locale) < 0) {
        break;
      }
      nextState.general.selectedLocale = action.payload.locale;
      break;
    case GeneralActionTypes.SET_ACCOUNT_GROUP_HYDRATOR:
      nextState.general.edited = true;
      nextState.general.type.setHydrator('accountGroup', action.payload.enabled);
      break;
    case GeneralActionTypes.SET_SALESPERSON_HYDRATOR:
      nextState.general.edited = true;
      nextState.general.type.setHydrator('salesperson', action.payload.enabled);
      break;
    case GeneralActionTypes.SET_PRODUCT_HYDRATOR:
      nextState.general.edited = true;
      nextState.general.type.setHydrator('product', action.payload.enabled);
      break;
    case GeneralActionTypes.SET_PARTNER_BRANDING_HYDRATOR:
      nextState.general.edited = true;
      nextState.general.type.setHydrator('partnerBranding', action.payload.enabled);
      break;
    case GeneralActionTypes.SET_DIGITAL_AGENT_HYDRATOR:
      nextState.general.edited = true;
      nextState.general.type.setHydrator('digitalAgent', action.payload.enabled);
      break;
    case GeneralActionTypes.SET_USER_HYDRATOR:
      nextState.general.edited = true;
      nextState.general.type.setHydrator('user', action.payload.enabled);
      break;
    case GeneralActionTypes.SEND_TEST:
      nextState.general.sendTestLoading = true;
      nextState.general.sendTestSuccess = false;
      nextState.general.sendTestError = null;
      break;
    case GeneralActionTypes.SEND_TEST_SUCCESS:
      nextState.general.sendTestLoading = false;
      nextState.general.sendTestSuccess = true;
      nextState.general.sendTestError = null;
      break;
    case GeneralActionTypes.SEND_TEST_ERROR:
      nextState.general.sendTestLoading = false;
      nextState.general.sendTestError = action.payload.error;
      break;
    case GeneralActionTypes.SET_TEMPLATE_TAB:
      nextState.general.templateTab = action.payload.templateTab;
      break;
  }
  return nextState;
}
