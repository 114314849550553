<mat-tab-group [selectedIndex]="1" animationDuration="0ms">
  <mat-tab label="General">
    <ng-content></ng-content>
  </mat-tab>
  <mat-tab label="HTML">
    <div class="editor">
      <ace
        [mode]="'html'"
        [theme]="'dawn'"
        [value]="html"
        (valueChange)="updateHTML($event)"
      ></ace>
    </div>
  </mat-tab>
  <mat-tab label="Sample Data">
    <div class="editor">
      <ace
        [mode]="'json'"
        [theme]="'dawn'"
        [value]="sample"
        (valueChange)="sampleChanged($event)"
      ></ace>
    </div>
  </mat-tab>
</mat-tab-group>
