import { State } from '../editor.reducers';
import { EditorActions } from '../editor.actions';
import { FeatureFlagTypes } from '../actions/feature-flag.actions';
import { FeatureFlag } from '@galaxy/partner';

export interface FeatureFlagState {
  fetching: boolean;
  error: any;
  featureFlags: FeatureFlag[];
}

export function initialFeatureFlagState(): FeatureFlagState {
  return {
    fetching: false,
    error: null,
    featureFlags: [],
  };
}

export function featureFlagReducer(state: State, action: EditorActions): State {
  switch (action.type) {
    case FeatureFlagTypes.FETCH_FEATURE_FLAGS:
      state.featureFlag.fetching = true;
      state.featureFlag.error = null;
      break;
    case FeatureFlagTypes.FETCH_FEATURE_FLAGS_FAILURE:
      state.featureFlag.fetching = false;
      state.featureFlag.error = action.payload.error;
      break;
    case FeatureFlagTypes.FETCH_FEATURE_FLAGS_SUCCESS:
      state.featureFlag.fetching = false;
      state.featureFlag.featureFlags = action.payload.featureFlags;
      break;
    case FeatureFlagTypes.SET_FEATURE_FLAG_ID:
      state.general.type.featureFlagId = action.payload.id;
      break;
  }
  return state;
}
