export enum InAppBundleActionTypes {
  LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE = 'load in app bundle content template',
  LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_SUCCESS = 'load in app bundle content template success',
  LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_ERROR = 'load in app bundle content template error',
  LOAD_IN_APP_BUNDLE_LINK_TEMPLATE = 'load in app bundle link template',
  LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_SUCCESS = 'load in app bundle link template success',
  LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_ERROR = 'load in app bundle link template error',
  SET_IN_APP_BUNDLE_TEMPLATE_ENABLED = 'set in app bundle template enabled',
  SET_IN_APP_BUNDLE_WINDOW = 'set in app bundle window',
  SET_IN_APP_BUNDLE_TEMPLATE_LINK = 'set in app bundle template link',
  SET_IN_APP_BUNDLE_TEMPLATE_SAMPLE = 'set in app bundle template sample',
  SET_IN_APP_BUNDLE_TEMPLATE_CONTENT = 'set in app bundle template content',
  RENDER_IN_APP_BUNDLE_TEMPLATE = 'render in app bundle template',
  RENDER_IN_APP_BUNDLE_TEMPLATE_SUCCESS = 'render in app bundle template success',
  RENDER_IN_APP_BUNDLE_TEMPLATE_ERROR = 'render in app bundle template error',
  CONVERT_IN_APP_BUNDLE_CONTENT_TO_TEMPLATE = 'convert in app bundle content to template',
  CONVERT_IN_APP_BUNDLE_LINK_TO_TEMPLATE = 'convert in app bundle link to template',
}

export class LoadInAppBundleContent {
  readonly type = InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE;
}

export class LoadInAppBundleContentSuccess {
  readonly type = InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadInAppBundleContentError {
  readonly type = InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_ERROR;
  constructor(readonly payload: { error: any; locale?: string }) {}
}

export class LoadInAppBundleLink {
  readonly type = InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE;
}

export class LoadInAppBundleLinkSuccess {
  readonly type = InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadInAppBundleLinkError {
  readonly type = InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_ERROR;
  constructor(readonly payload: { error: any; locale?: string }) {}
}

export class SetInAppBundleEnabled {
  readonly type = InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_ENABLED;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetInAppBundleWindow {
  readonly type = InAppBundleActionTypes.SET_IN_APP_BUNDLE_WINDOW;
  constructor(readonly payload: { window: string }) {}
}

export class SetInAppBundleLink {
  readonly type = InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_LINK;
  constructor(readonly payload: { link: string; locale?: string }) {}
}

export class SetInAppBundleContent {
  readonly type = InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_CONTENT;
  constructor(readonly payload: { content: string; locale?: string }) {}
}

export class SetInAppBundleSample {
  readonly type = InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_SAMPLE;
  constructor(readonly payload: { sample: string; sampleObj: object; edited: boolean }) {}
}

export class RenderInAppBundleTemplate {
  readonly type = InAppBundleActionTypes.RENDER_IN_APP_BUNDLE_TEMPLATE;
}

export class RenderInAppBundleTemplateSuccess {
  readonly type = InAppBundleActionTypes.RENDER_IN_APP_BUNDLE_TEMPLATE_SUCCESS;
  constructor(readonly payload: { html: string }) {}
}

export class RenderInAppBundleTemplateError {
  readonly type = InAppBundleActionTypes.RENDER_IN_APP_BUNDLE_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string }) {}
}

export class ConvertInAppBundleContentToTemplate {
  readonly type = InAppBundleActionTypes.CONVERT_IN_APP_BUNDLE_CONTENT_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string; locale?: string }) {}
}

export class ConvertInAppBundleLinkToTemplate {
  readonly type = InAppBundleActionTypes.CONVERT_IN_APP_BUNDLE_LINK_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string; locale?: string }) {}
}

export type InAppBundleActions =
  | LoadInAppBundleContent
  | LoadInAppBundleContentSuccess
  | LoadInAppBundleContentError
  | LoadInAppBundleLink
  | LoadInAppBundleLinkSuccess
  | LoadInAppBundleLinkError
  | SetInAppBundleEnabled
  | SetInAppBundleWindow
  | SetInAppBundleLink
  | SetInAppBundleContent
  | SetInAppBundleSample
  | RenderInAppBundleTemplate
  | RenderInAppBundleTemplateSuccess
  | RenderInAppBundleTemplateError
  | ConvertInAppBundleContentToTemplate
  | ConvertInAppBundleLinkToTemplate;
