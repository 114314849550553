<div class="container">
  <div class="message">
    A user must meet both the user type and resource restrictions to subscribe
    and receive this notification.
  </div>
  <h3>User Types</h3>
  <div class="message">
    Select which user types can see this notification. If none are selected,
    only Developers will be able to see this notification.
  </div>
  <div class="permission-personas">
    <div>
      <mat-checkbox
        [checked]="enabled(PersonaType.PERSONA_TYPE_PARTNER)"
        (change)="update(PersonaType.PERSONA_TYPE_PARTNER, $event.checked)"
      >
        Partner Center Users
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        [checked]="enabled(PersonaType.PERSONA_TYPE_SALES_PERSON)"
        (change)="update(PersonaType.PERSONA_TYPE_SALES_PERSON, $event.checked)"
      >
        Salespeople
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        [checked]="enabled(PersonaType.PERSONA_TYPE_SMB)"
        (change)="update(PersonaType.PERSONA_TYPE_SMB, $event.checked)"
      >
        SMB Users
      </mat-checkbox>
    </div>
    <div>
      <mat-checkbox
        [checked]="enabled(PersonaType.PERSONA_TYPE_DIGITAL_AGENT)"
        (change)="
          update(PersonaType.PERSONA_TYPE_DIGITAL_AGENT, $event.checked)
        "
      >
        Digital Agents
      </mat-checkbox>
    </div>
  </div>
  <h3>Resources</h3>
  <div *ngIf="!resources || resources.length === 0" class="empty-message">
    No resource restrictions set.
  </div>
  <div
    class="resource"
    *ngFor="
      let resource of resources;
      let resourceIndex = index;
      let last = last
    "
  >
    <div class="resource-field">
      <mat-form-field>
        <input
          matInput
          placeholder="Resource Identifier"
          autocomplete="off"
          [value]="resource.resource.id"
          (change)="
            setResource.emit({
              resourceIndex: resourceIndex,
              resourceName: $event.target.value
            })
          "
        />
        <mat-hint>Example: partner/*</mat-hint>
      </mat-form-field>
      <button mat-icon-button type="button">
        <mat-icon
          aria-label="Delete Resource"
          (click)="removeResource.emit(resourceIndex)"
        >
          delete
        </mat-icon>
      </button>
    </div>
    <div class="scopes">
      <div
        class="scope"
        *ngFor="let scope of resource.scopeIds; let scopeIndex = index"
      >
        <mat-form-field>
          <input
            matInput
            placeholder="Scope"
            autocomplete="off"
            [value]="scope"
            (change)="
              setScope.emit({
                resourceIndex: resourceIndex,
                scopeIndex: scopeIndex,
                scopeId: $event.target.value
              })
            "
          />
          <mat-hint>Example: partner.business.manage</mat-hint>
        </mat-form-field>
        <button
          mat-icon-button
          type="button"
          *ngIf="resource.scopeIds.length > 1"
        >
          <mat-icon
            aria-label="Delete Scope"
            (click)="
              removeScope.emit({
                resourceIndex: resourceIndex,
                scopeIndex: scopeIndex
              })
            "
          >
            delete
          </mat-icon>
        </button>
      </div>
      <div class="add-scope">
        <button
          type="button"
          mat-stroked-button
          color="primary"
          (click)="addScope.emit(resourceIndex)"
        >
          Add Scope
        </button>
      </div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  </div>
  <div class="add-resource">
    <button
      type="button"
      mat-stroked-button
      color="primary"
      (click)="addResource.emit()"
    >
      Add Resource
    </button>
  </div>
</div>
