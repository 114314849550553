<div class="container">
  <mat-checkbox [checked]="enabled" (change)="enabledChange.emit($event.checked)">Enabled for users</mat-checkbox>
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="Subject"
      autocomplete="off"
      [value]="subject"
      (input)="subjectChange.emit($event.target.value)"
      required
    />
    <mat-hint>The email subject line.</mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="Link"
      autocomplete="off"
      [value]="link"
      (input)="linkChange.emit($event.target.value)"
      required
    />
    <mat-hint>
      The whitelabeled link which can be used in the email. You may use {{ '{' }}{{ '{' }} ._link &#125;&#125; in the
      Email body to access this value.
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="From Name"
      autocomplete="off"
      [value]="fromName"
      (input)="fromNameChange.emit($event.target.value)"
      required
    />
  </mat-form-field>
  <mat-form-field class="hint">
    <mat-label>From Address</mat-label>
    <mat-select [value]="fromAddress" (selectionChange)="fromAddressChange.emit($event.value)">
      <mat-option [value]="sendFromOptions.SEND_FROM_SMB">
        {{ sendFromOptions.SEND_FROM_SMB }}
      </mat-option>
      <mat-option [value]="sendFromOptions.SEND_FROM_VENDASTA">
        {{ sendFromOptions.SEND_FROM_VENDASTA }}
      </mat-option>
      <mat-option [value]="sendFromOptions.SEND_FROM_DIGITAL_AGENTS">
        {{ sendFromOptions.SEND_FROM_DIGITAL_AGENTS }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Content Type</mat-label>
    <mat-select [value]="contentType" (selectionChange)="contentTypeChange.emit($event.value)">
      <mat-option [value]="contentTypeOption.CONTENT_TYPE_HTML">HTML</mat-option>
      <mat-option [value]="contentTypeOption.CONTENT_TYPE_TEXT">Text</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email Service Provider</mat-label>
    <mat-select [value]="emailServiceProvider" (selectionChange)="emailServiceProviderChange.emit($event.value)">
      <mat-option value="ESP_MARKETING">Marketing</mat-option>
      <mat-option value="ESP_PRODUCT">Product</mat-option>
      <mat-option value="ESP_SMB">SMB</mat-option>
      <mat-option value="ESP_BILLING">Billing</mat-option>
      <mat-option value="ESP_WEBSITE_PRO">Website Pro</mat-option>
      <mat-option value="ESP_DYNAMIC">Dynamic</mat-option>
    </mat-select>
  </mat-form-field>
</div>
