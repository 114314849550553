<mat-toolbar>
  Select Event
  <div class="spacer"></div>
  <div class="actions">
    <button mat-stroked-button (click)="cancel.emit()">Cancel</button>
    <button mat-stroked-button (click)="skip.emit()">Skip</button>
  </div>
</mat-toolbar>

<div>
  <mat-form-field class="hint">
    <input matInput placeholder="Search" [disabled]="false" (keyup)="changeSearch($event.target.value)" />
    <mat-hint>EventTypeID</mat-hint>
  </mat-form-field>
</div>
<div class="list mat-elevation-z1">
  <div class="message" *ngIf="!loading && error">Failed to load events</div>
  <div class="message" *ngIf="events.length === 0 && !loading && !error">There are no events.</div>
  <mat-nav-list *ngIf="events.length > 0">
    <mat-list-item *ngFor="let event of events" (click)="selected.emit(event)">
      <span matListItemTitle>{{ event.name }}</span>
      <span matListItemLine>{{ event.description }}</span>
    </mat-list-item>
  </mat-nav-list>
</div>
<div class="load-more" *ngIf="loading || hasMore">
  <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
  <button mat-stroked-button *ngIf="hasMore && !loading" (click)="loadMore.emit()">Load more</button>
</div>
