import { ActionTypes, EditorActions } from '../editor.actions';
import { State } from '../editor.reducers';

export interface EmailState {
  contentTemplate: string;
  contentTemplateLoading: boolean;
  contentTemplateError: any;

  linkTemplate: string;
  linkTemplateLoading: boolean;
  linkTemplateError: any;

  subjectTemplate: string;
  subjectTemplateLoading: boolean;
  subjectTemplateError: any;

  fromNameTemplate: string;
  fromNameTemplateLoading: boolean;
  fromNameTemplateError: any;

  fromAddressTemplate: string;
  fromAddressTemplateLoading: boolean;
  fromAddressTemplateError: any;

  renderedHTML: string;
  renderedHTMLLoading: boolean;
  renderedHTMLError: any;

  sample: string;
}

export function initialEmailState(): EmailState {
  return {
    contentTemplate: '',
    contentTemplateLoading: false,
    contentTemplateError: null,

    linkTemplate: '',
    linkTemplateLoading: false,
    linkTemplateError: null,

    subjectTemplate: '',
    subjectTemplateLoading: false,
    subjectTemplateError: null,

    fromNameTemplate: '',
    fromNameTemplateLoading: false,
    fromNameTemplateError: null,

    fromAddressTemplate: '',
    fromAddressTemplateLoading: false,
    fromAddressTemplateError: null,

    renderedHTML: '',
    renderedHTMLLoading: false,
    renderedHTMLError: null,

    sample: '{}',
  };
}

export function emailReducer(nextState: State, action: EditorActions): State {
  switch (action.type) {
    case ActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE:
      nextState.email.contentTemplateLoading = true;
      nextState.email.contentTemplate = '';
      nextState.email.contentTemplateError = null;
      break;
    case ActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE_SUCCESS:
      nextState.email.contentTemplateLoading = false;
      nextState.email.contentTemplate = action.payload.template;
      break;
    case ActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE_ERROR:
      nextState.email.contentTemplateLoading = false;
      nextState.email.contentTemplateError = action.payload.error;
      break;
    case ActionTypes.LOAD_EMAIL_LINK_TEMPLATE:
      nextState.email.linkTemplateLoading = true;
      nextState.email.linkTemplate = '';
      nextState.email.linkTemplateError = null;
      break;
    case ActionTypes.LOAD_EMAIL_LINK_TEMPLATE_SUCCESS:
      nextState.email.linkTemplateLoading = false;
      nextState.email.linkTemplate = action.payload.template;
      break;
    case ActionTypes.LOAD_EMAIL_LINK_TEMPLATE_ERROR:
      nextState.email.linkTemplateLoading = false;
      nextState.email.linkTemplateError = action.payload.error;
      break;
    case ActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE:
      nextState.email.subjectTemplateLoading = true;
      nextState.email.subjectTemplate = '';
      nextState.email.subjectTemplateError = null;
      break;
    case ActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE_SUCCESS:
      nextState.email.subjectTemplateLoading = false;
      nextState.email.subjectTemplate = action.payload.template;
      break;
    case ActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE_ERROR:
      nextState.email.subjectTemplateLoading = false;
      nextState.email.subjectTemplateError = action.payload.error;
      break;
    case ActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE:
      nextState.email.fromAddressTemplateLoading = true;
      nextState.email.fromAddressTemplate = '';
      nextState.email.fromAddressTemplateError = null;
      break;
    case ActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_SUCCESS:
      nextState.email.fromAddressTemplateLoading = false;
      nextState.email.fromAddressTemplate = action.payload.template;
      break;
    case ActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_ERROR:
      nextState.email.fromAddressTemplateLoading = false;
      nextState.email.fromAddressTemplateError = action.payload.error;
      break;
    case ActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE:
      nextState.email.fromNameTemplateLoading = true;
      nextState.email.fromNameTemplate = '';
      nextState.email.fromNameTemplateError = null;
      break;
    case ActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE_SUCCESS:
      nextState.email.fromNameTemplateLoading = false;
      nextState.email.fromNameTemplate = action.payload.template;
      break;
    case ActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE_ERROR:
      nextState.email.fromNameTemplateLoading = false;
      nextState.email.fromNameTemplateError = action.payload.error;
      break;
    case ActionTypes.SET_EMAIL_TEMPLATE_ENABLED:
      if (nextState.general.type.email.enabled !== action.payload.enabled) {
        nextState.general.edited = true;
      }
      nextState.general.type.email.enabled = action.payload.enabled;
      break;
    case ActionTypes.SET_EMAIL_CONTENT_TEMPLATE:
      if (nextState.email.contentTemplate !== action.payload.template) {
        nextState.general.edited = true;
      }
      nextState.email.contentTemplate = action.payload.template;
      break;
    case ActionTypes.SET_EMAIL_SAMPLE:
      if (nextState.email.sample !== action.payload.sample) {
        nextState.general.edited = action.payload.edited;
      }
      nextState.email.sample = action.payload.sample;
      nextState.general.type.email.sampleData = action.payload.sampleObj;
      break;
    case ActionTypes.SET_EMAIL_SUBJECT_TEMPLATE:
      if (nextState.email.subjectTemplate !== action.payload.template) {
        nextState.general.edited = true;
      }
      nextState.email.subjectTemplate = action.payload.template;
      break;
    case ActionTypes.SET_EMAIL_LINK_TEMPLATE:
      if (nextState.email.linkTemplate !== action.payload.template) {
        nextState.general.edited = true;
      }
      nextState.email.linkTemplate = action.payload.template;
      break;
    case ActionTypes.SET_EMAIL_FROM_NAME_TEMPLATE:
      if (nextState.email.fromNameTemplate !== action.payload.template) {
        nextState.general.edited = true;
      }
      nextState.email.fromNameTemplate = action.payload.template;
      break;
    case ActionTypes.SET_EMAIL_FROM_ADDRESS_TEMPLATE:
      if (nextState.email.fromAddressTemplate !== action.payload.template) {
        nextState.general.edited = true;
      }
      nextState.email.fromAddressTemplate = action.payload.template;
      break;
    case ActionTypes.SET_EMAIL_CONTENT_TYPE:
      if (nextState.general.type.email.contentType !== action.payload.contentType) {
        nextState.general.edited = true;
      }
      nextState.general.type.email.contentType = action.payload.contentType;
      break;
    case ActionTypes.SET_EMAIL_SERVICE_PROVIDER:
      if (nextState.general.type.email.espId !== action.payload.serviceProvider) {
        nextState.general.edited = true;
      }
      nextState.general.type.email.espId = action.payload.serviceProvider;
      break;
    case ActionTypes.RENDER_EMAIL_TEMPLATE:
      nextState.email.renderedHTMLLoading = true;
      nextState.email.renderedHTMLError = null;
      break;
    case ActionTypes.RENDER_EMAIL_TEMPLATE_SUCCESS:
      nextState.email.renderedHTMLLoading = false;
      nextState.email.renderedHTML = action.payload.html;
      break;
    case ActionTypes.RENDER_EMAIL_TEMPLATE_ERROR:
      nextState.email.renderedHTMLLoading = false;
      nextState.email.renderedHTMLError = action.payload.error;
      break;
    case ActionTypes.CONVERT_EMAIL_CONTENT_TO_TEMPLATE:
      nextState.general.type.email.contentTemplate.id = action.payload.templateId;
      break;
    case ActionTypes.CONVERT_EMAIL_LINK_TO_TEMPLATE:
      nextState.general.type.email.linkTemplate.id = action.payload.templateId;
      break;
    case ActionTypes.CONVERT_EMAIL_SUBJECT_TO_TEMPLATE:
      nextState.general.type.email.subjectTemplate.id = action.payload.templateId;
      break;
    case ActionTypes.CONVERT_EMAIL_FROM_NAME_TO_TEMPLATE:
      nextState.general.type.email.from.nameTemplate.id = action.payload.templateId;
      break;
    case ActionTypes.CONVERT_EMAIL_FROM_ADDRESS_TO_TEMPLATE:
      nextState.general.type.email.from.addressTemplate.id = action.payload.templateId;
      break;
  }
  return nextState;
}
