<div
  class="glxy-nav-wrapper"
  [class.nav-is-closed]="!navControl.isOpen()"
  [class.nav-is-open]="navControl.isOpen()"
  [style.top]="fixedTopGap + 'px'"
  [style.--top-gap]="fixedTopGap + 'px'"
>
  <mat-sidenav-container class="glxy-nav--mat-sidenav-container" [autosize]="navAutoSize">
    <mat-sidenav
      class="glxy-nav--mat-sidenav"
      [mode]="navMode()"
      [fixedInViewport]="true"
      [opened]="navControl.isOpen()"
      [fixedTopGap]="fixedTopGap"
      (closed)="navControl.close()"
    >
      <ng-content select="glxy-nav-panel" />
    </mat-sidenav>

    <mat-sidenav-content class="glxy-nav--mat-sidenav-content">
      <ng-content />
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
