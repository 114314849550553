<div class="container">
  <mat-checkbox [checked]="enabled" (change)="enabledChange.emit($event.checked)">Enabled for users</mat-checkbox>
  <mat-form-field class="hint">
    <input
      matInput
      placeholder="Link"
      autocomplete="off"
      [value]="link"
      (input)="linkChange.emit($event.target.value)"
      required
    />
    <mat-hint>
      Example if "Application" is set "order-management/{{ '{' }}{{ '{' }}.account_group_id&#125;&#125;/info/{{ '{'
      }}{{ '{' }}.order_id&#125;&#125;?_pid={{ '{' }}{{ '{' }}.partner_id&#125;&#125;" if the application is Not Set you
      must provide a full URL.
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="hint" *ngIf="bundle">
    <input
      matInput
      type="number"
      min="0"
      placeholder="Window"
      autocomplete="off"
      [value]="window"
      (input)="windowChange.emit($event.target.value)"
    />
    <span matSuffix>minutes</span>
    <mat-hint>
      The amount of time multiple notifications should be bucketed together. For example, 15 minutes would bucket
      notifications from 00:00 to 00:15 together.
    </mat-hint>
  </mat-form-field>
</div>
