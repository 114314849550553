import { isDevMode } from '@angular/core';
import { ActionTypes, EditorActions } from './editor.actions';
import { generalReducer, GeneralState, initialGeneralState } from './reducers/general.reducers';
import { emailReducer, EmailState, initialEmailState } from './reducers/email.reducers';
import { inAppReducer, InAppState, initialInAppState } from './reducers/in-app.reducers';
import { inAppBundleReducer, InAppBundleState, initialInAppBundleState } from './reducers/in-app-bundle.reducers';
import { featureFlagReducer, FeatureFlagState, initialFeatureFlagState } from './reducers/feature-flag.reducers';
import { localeReducer, LocaleState } from './reducers/locale.reducers';

export interface State {
  // general stores the non-template settings for this notification
  general: GeneralState;
  // locales stores the localized notification template content (email and web templates)
  // when the selected locale changes, values from this object in the relevant locale are copied into email, inApp, and
  // inAppBundle for rendering
  locales: { [key: string]: LocaleState };
  // email holds variables for rendering the email template preview
  email: EmailState;
  // inApp holds variables for rendering the web template preview
  inApp: InAppState;
  // inAppBundle holds variables for rendering the bundled web template preview
  inAppBundle: InAppBundleState;
  // featureFlag stores the feature flag id associated with this notification
  featureFlag: FeatureFlagState;
}

export function initialState(): State {
  return {
    general: { ...initialGeneralState() },
    email: { ...initialEmailState() },
    inApp: { ...initialInAppState() },
    inAppBundle: { ...initialInAppBundleState() },
    featureFlag: { ...initialFeatureFlagState() },
    locales: {},
  };
}

export function editorReducer(state: State, action: EditorActions): State {
  let nextState = action.type === ActionTypes.INIT ? initialState() : { ...state };
  nextState = generalReducer(nextState, action);
  nextState = emailReducer(nextState, action);
  nextState = inAppReducer(nextState, action);
  nextState = inAppBundleReducer(nextState, action);
  nextState = featureFlagReducer(nextState, action);
  nextState = localeReducer(nextState, action);
  if (isDevMode()) {
    console.log('ACTION:', action.type, '\nPAYLOAD:', action['payload'] || {}, '\nNEXT STATE:', nextState);
  }
  return nextState;
}
