import { State } from '../editor.reducers';
import { InAppBundleActionTypes } from '../actions/in-app-bundle.actions';
import { EditorActions } from '../editor.actions';

export interface InAppBundleState {
  contentTemplateLoading: boolean;
  contentTemplateError: any;
  contentTemplate: string;

  linkTemplateLoading: boolean;
  linkTemplateError: any;
  linkTemplate: string;

  renderedHTML: string;
  renderedHTMLLoading: boolean;
  renderedHTMLError: string;

  sample: string;
}

export function initialInAppBundleState(): InAppBundleState {
  return {
    contentTemplateLoading: false,
    contentTemplateError: null,
    contentTemplate: '',

    linkTemplateLoading: false,
    linkTemplateError: null,
    linkTemplate: '',

    renderedHTML: '',
    renderedHTMLLoading: false,
    renderedHTMLError: null,

    sample: '{}',
  };
}

export function inAppBundleReducer(nextState: State, action: EditorActions): State {
  switch (action.type) {
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE:
      nextState.inAppBundle.contentTemplate = '';
      nextState.inAppBundle.contentTemplateLoading = true;
      nextState.inAppBundle.contentTemplateError = null;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_SUCCESS:
      nextState.inAppBundle.contentTemplateLoading = false;
      nextState.inAppBundle.contentTemplate = action.payload.template;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_CONTENT_TEMPLATE_ERROR:
      nextState.inAppBundle.contentTemplateLoading = false;
      nextState.inAppBundle.contentTemplateError = action.payload.error;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE:
      nextState.inAppBundle.linkTemplate = '';
      nextState.inAppBundle.linkTemplateLoading = true;
      nextState.inAppBundle.linkTemplateError = null;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_SUCCESS:
      nextState.inAppBundle.linkTemplateLoading = false;
      nextState.inAppBundle.linkTemplate = action.payload.template;
      break;
    case InAppBundleActionTypes.LOAD_IN_APP_BUNDLE_LINK_TEMPLATE_ERROR:
      nextState.inAppBundle.linkTemplateLoading = false;
      nextState.inAppBundle.linkTemplateError = action.payload.error;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_ENABLED:
      if (nextState.general.type.webBundle.enabled !== action.payload.enabled) {
        nextState.general.edited = true;
      }
      nextState.general.type.webBundle.enabled = action.payload.enabled;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_WINDOW:
      if (nextState.general.type.webBundle.window !== action.payload.window) {
        nextState.general.edited = true;
      }
      nextState.general.type.webBundle.window = action.payload.window;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_LINK:
      if (nextState.inAppBundle.linkTemplate !== action.payload.link) {
        nextState.general.edited = true;
      }
      nextState.inAppBundle.linkTemplate = action.payload.link;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_SAMPLE:
      if (nextState.inAppBundle.sample !== action.payload.sample) {
        nextState.general.edited = action.payload.edited;
      }
      nextState.inAppBundle.renderedHTMLLoading = true;
      nextState.inAppBundle.renderedHTMLError = null;
      nextState.inAppBundle.sample = action.payload.sample;
      nextState.general.type.webBundle.sampleData = action.payload.sampleObj;
      break;
    case InAppBundleActionTypes.SET_IN_APP_BUNDLE_TEMPLATE_CONTENT:
      if (nextState.inAppBundle.contentTemplate !== action.payload.content) {
        nextState.general.edited = true;
      }
      nextState.inAppBundle.contentTemplate = action.payload.content;
      break;
    case InAppBundleActionTypes.RENDER_IN_APP_BUNDLE_TEMPLATE:
      nextState.inAppBundle.renderedHTMLLoading = true;
      nextState.inAppBundle.renderedHTMLError = null;
      break;
    case InAppBundleActionTypes.RENDER_IN_APP_BUNDLE_TEMPLATE_SUCCESS:
      nextState.inAppBundle.renderedHTMLLoading = false;
      nextState.inAppBundle.renderedHTML = action.payload.html;
      break;
    case InAppBundleActionTypes.RENDER_IN_APP_BUNDLE_TEMPLATE_ERROR:
      nextState.inAppBundle.renderedHTMLLoading = false;
      nextState.inAppBundle.renderedHTMLError = action.payload.error;
      break;
    case InAppBundleActionTypes.CONVERT_IN_APP_BUNDLE_CONTENT_TO_TEMPLATE:
      nextState.general.type.webBundle.contentTemplate.id = action.payload.templateId;
      break;
    case InAppBundleActionTypes.CONVERT_IN_APP_BUNDLE_LINK_TO_TEMPLATE:
      nextState.general.type.webBundle.linkTemplate.id = action.payload.templateId;
      break;
  }
  return nextState;
}
