import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Environment } from '@galaxy/core';
import { OAuth2Service, OAuth2ServiceConfig } from '@vendasta/oauth2';
import { devServer } from '../../../globals';

export function oauth2ConfigFactory(): OAuth2ServiceConfig {
  const config = {
    oauthConfig: {
      // showDebugInformation: true,
    },
    // set the scope for the permissions the client should request
    scopes: ['admin', 'sales.domain:read', 'taskdefinition', 'eventtriggerdefinition', 'transcript'],
    serviceProviderConfigs: {
      [Environment.LOCAL]: {
        redirectUri: 'https://localhost:4200',
        silentRefreshRedirectUri: 'https://localhost:4200/silent-refresh.html',
        clientId: '93945b59-1739-4ccc-970b-7040bd8a69fd',
        serviceProviderId: 'admin-client',
      },
      [Environment.DEMO]: {
        redirectUri: 'https://admin-demo.vendasta-internal.com',
        silentRefreshRedirectUri: 'https://admin-demo.vendasta-internal.com/silent-refresh.html',
        clientId: '93945b59-1739-4ccc-970b-7040bd8a69fd',
        serviceProviderId: 'admin-client',
      },
      [Environment.PROD]: {
        redirectUri: 'https://admin.vendasta-internal.com',
        silentRefreshRedirectUri: 'https://admin.vendasta-internal.com/silent-refresh.html',
        clientId: '78b56540-3d64-4c17-9ea4-99bc55a39e01',
        serviceProviderId: 'admin-client',
      },
    },

    // only send a partnerId param to SSO if we're not running in DataDog Synthetics. In VCC we need to use the 'VA'
    // partnerID for actual admin users, but there's currently a SSO synthetic test that uses a non-VA PID user.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customQueryParamsFunc: (_: ActivatedRouteSnapshot, __: RouterStateSnapshot) =>
      typeof (window as any)._DATADOG_SYNTHETICS_BROWSER === 'undefined' ? { partner_id: 'VA' } : {},
  };

  if (devServer) {
    config.serviceProviderConfigs[Environment.DEMO] = {
      redirectUri: 'https://localhost:4200',
      silentRefreshRedirectUri: 'https://localhost:4200/silent-refresh.html',
      clientId: '93945b59-1739-4ccc-970b-7040bd8a69fd',
      serviceProviderId: 'admin-client',
    };
  }

  return config;
}

@Injectable()
export class AuthService {
  constructor(private oauth2Service: OAuth2Service) {}

  // logout clears local app data and continues to registered logout URL
  public logout(customLogoutURLParams?: object): void {
    this.oauth2Service.logout(customLogoutURLParams);
  }

  // localLogout only clears the local app data, without redirecting to your logout URL
  public localLogout(): void {
    this.oauth2Service.localLogout();
  }
}
