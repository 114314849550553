import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AccessRequirementInterface,
  ContentType,
  Hydrator,
  HydratorInterface,
  NotificationMedium,
  NotificationTypeEmail,
  NotificationTypeEmailInterface,
  NotificationTypeInterface,
  NotificationTypeWeb,
  NotificationTypeWebBundle,
  NotificationTypeWebBundleInterface,
  NotificationTypeWebInterface,
  NotificationsAdminService as NotificationsAdminClientService,
  PersonaType,
  PreviewRequestEventInterface,
  NotificationType as SDKNotificationType,
  UpdateTypeRequestUpdateNotificationTypeOperationInterface,
} from '@vendasta/notifications-sdk';

export interface List {
  types: NotificationType[];
  hasMore: boolean;
  nextCursor: string;
}

export class NotificationType extends SDKNotificationType {
  constructor(kwargs?: NotificationTypeInterface) {
    super(kwargs);
    if (!this.web) {
      this.web = new NotificationTypeWeb({
        enabled: false,
        sampleData: {},
      });
    } else {
      this.web.enabled = this.web.enabled === undefined ? false : this.web.enabled;
    }
    if (!this.webBundle) {
      this.webBundle = new NotificationTypeWebBundle({
        enabled: false,
        sampleData: {},
      });
    } else {
      this.webBundle.enabled = this.webBundle.enabled === undefined ? false : this.webBundle.enabled;
    }
    if (!this.email) {
      this.email = new NotificationTypeEmail({
        enabled: false,
        sampleData: {},
        contentType: ContentType.CONTENT_TYPE_HTML,
      });
    } else {
      this.email.enabled = this.email.enabled === undefined ? false : this.email.enabled;
      this.email.contentType =
        this.email.contentType === undefined ? ContentType.CONTENT_TYPE_HTML : this.email.contentType;
    }
  }

  getHydrators(): Hydrator[] {
    return this.hydrators || [];
  }

  setHydrator(name: string, enabled: boolean): void {
    if (!this.hydrators) {
      this.hydrators = [];
    }
    let hydrator = this.hydrators.find((h) => h[name] !== undefined);
    if (hydrator) {
      hydrator[name] = enabled;
    } else {
      hydrator = new Hydrator();
      hydrator[name] = enabled;
      this.hydrators.push(hydrator);
    }
  }

  canEnable(medium: NotificationMedium): boolean {
    if (medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB) {
      return !!this.web && !!this.web.contentTemplate && !!this.web.linkTemplate;
    } else if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
      return (
        !!this.email &&
        !!this.email.from &&
        !!this.email.from.nameTemplate &&
        !!this.email.from.addressTemplate &&
        !!this.email.espId &&
        !!this.email.subjectTemplate
      );
    } else {
      return false;
    }
  }

  canRelease(medium: NotificationMedium): boolean {
    if (medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB) {
      return !!this.web && !!this.web.contentTemplate && !!this.web.linkTemplate;
    } else if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
      return (
        !!this.email &&
        !!this.email.from &&
        !!this.email.from.nameTemplate &&
        !!this.email.from.addressTemplate &&
        !!this.email.espId &&
        !!this.email.subjectTemplate
      );
    } else {
      return false;
    }
  }

  listEnabledMediums(): NotificationMedium[] {
    return Object.keys(NotificationMedium)
      .map((k) => NotificationMedium[k])
      .filter((m) => this.canRelease(m));
  }

  toggleMediumEnabled(medium: NotificationMedium): void {
    if (medium === NotificationMedium.NOTIFICATION_MEDIUM_WEB) {
      this.web.enabled = !this.web.enabled;
    } else if (medium === NotificationMedium.NOTIFICATION_MEDIUM_EMAIL) {
      this.email.enabled = !this.email.enabled;
    }
  }
}

export interface AdminService {
  createType$(
    typeId: string,
    eventTypeId: string,
    name: string,
    description: string,
    category: string[],
    domainId: string,
    featureFlagId: string,
    hydrators: HydratorInterface[],
    accessRequirements?: AccessRequirementInterface[],
    personaAccessRequirements?: PersonaType[],
    web?: NotificationTypeWebInterface,
    webBundle?: NotificationTypeWebBundleInterface,
    email?: NotificationTypeEmailInterface,
  ): Observable<any>;
  getType$(typeId: string): Observable<NotificationType>;
  updateType$(typeId: string, operations: UpdateTypeRequestUpdateNotificationTypeOperationInterface[]): Observable<any>;
  deleteType$(typeId: string): Observable<any>;
  listTypes$(pageSize?: number, cursor?: string): Observable<List>;
  releaseType$(typeId: string, medium: NotificationMedium, userIDs: string[]): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsAdminService implements AdminService {
  constructor(private notificationsAdminService: NotificationsAdminClientService) {}

  createType$(
    typeId: string,
    eventTypeId: string,
    name: string,
    description: string,
    category: string[],
    domainId: string,
    featureFlagId: string,
    hydrators: HydratorInterface[],
    accessRequirements?: AccessRequirementInterface[],
    personaAccessRequirements?: PersonaType[],
    web?: NotificationTypeWebInterface,
    webBundle?: NotificationTypeWebBundleInterface,
    email?: NotificationTypeEmailInterface,
  ): Observable<any> {
    return this.notificationsAdminService.createType$({
      notificationTypeId: typeId,
      eventTypeId: eventTypeId,
      name: name,
      description: description,
      category: category,
      domainId: domainId,
      hydrators: hydrators,
      accessRequirements: accessRequirements,
      personaAccessRequirements: personaAccessRequirements,
      web: web,
      webBundle: webBundle,
      email: email,
      featureFlagId: featureFlagId,
    });
  }

  getType$(typeId: string): Observable<NotificationType> {
    return this.notificationsAdminService.getType$(typeId).pipe(map((t) => new NotificationType(t)));
  }

  updateType$(
    typeId: string,
    operations: UpdateTypeRequestUpdateNotificationTypeOperationInterface[],
  ): Observable<any> {
    return this.notificationsAdminService.updateType$(typeId, operations);
  }

  listTypes$(pageSize?: number, cursor?: string): Observable<List> {
    return this.notificationsAdminService.listTypes$(pageSize, cursor).pipe(
      map((resp) => ({
        types: resp.types.map((t) => new NotificationType(t)),
        hasMore: resp.hasMore || false,
        nextCursor: resp.nextCursor,
      })),
    );
  }

  deleteType$(typeId: string): Observable<any> {
    return this.notificationsAdminService.deleteType$(typeId);
  }

  releaseType$(typeId: string, medium: NotificationMedium, userIDs: string[]): Observable<any> {
    return this.notificationsAdminService.releaseType$(typeId, medium, userIDs);
  }

  preview$(type: string, medium: NotificationMedium, event: PreviewRequestEventInterface): Observable<any> {
    return this.notificationsAdminService.preview$(type, medium, event);
  }
}
