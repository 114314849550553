import { Input, Component, EventEmitter, Output, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import 'brace';
import 'brace/mode/html';
import 'brace/theme/dawn';

@Component({
  selector: 'notification-manage-template-configuration',
  templateUrl: './template-configuration.component.html',
  styleUrls: ['./template-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateConfigurationComponent implements OnInit, OnDestroy {
  @Input() html: string;
  @Output() htmlChange: EventEmitter<string> = new EventEmitter();

  @Input() sample: string;
  @Output() sampleChange: EventEmitter<string> = new EventEmitter();

  html$$: Subject<string> = new Subject();
  sample$$: Subject<string> = new Subject();
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.html$$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((content) => this.htmlChange.emit(content)),
    );
    this.subscriptions.push(
      this.sample$$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => this.sampleChange.emit(value)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  updateHTML(content: string): void {
    this.html$$.next(content);
  }

  sampleChanged(value: string): void {
    this.sample$$.next(value);
  }
}
