import { NotificationType } from '../../../common/notifications/notifications-admin.service';
import { EventTypeInterface } from '@vendasta/event-broker';
import { Application } from '../application';
import { PersonaType } from '@vendasta/notifications-sdk';
import { TemplateTab } from '../reducers/general.reducers';

export enum GeneralActionTypes {
  INIT = 'init',
  SAVE = 'save',
  SAVE_SUCCESS = 'save success',
  SAVE_ERROR = 'save error',
  DELETE = 'delete',
  DELETE_SUCCESS = 'delete success',
  DELETE_ERROR = 'delete error',
  LOAD_TYPE = 'load type',
  LOAD_TYPE_SUCCESS = 'load type success',
  LOAD_TYPE_ERROR = 'load type error',
  LOAD_EVENT = 'load event',
  LOAD_EVENT_SUCCESS = 'load event success',
  LOAD_EVENT_ERROR = 'load event error',
  LOAD_EVENTS = 'load events',
  LOAD_MORE_EVENTS = 'load more events',
  LOAD_EVENTS_SUCCESS = 'load events success',
  LOAD_EVENTS_ERROR = 'load events error',
  SET_EVENT = 'set event',
  SET_ACCOUNT_GROUP_DATA_AVAILABLE = 'set account group data available',
  SET_SALESPERSON_DATA_AVAILABLE = 'set salesperson data available',
  SET_PRODUCT_DATA_AVAILABLE = 'set product data available',
  SET_PARTNER_BRANDING_DATA_AVAILABLE = 'set partner branding data available',
  SET_DIGITAL_AGENT_DATA_AVAILABLE = 'set digital agent data available',
  SET_USER_DATA_AVAILABLE = 'set user data available',

  LOAD_APPLICATIONS = 'load applications',
  LOAD_APPLICATIONS_SUCCESS = 'load applications success',
  LOAD_APPLICATIONS_ERROR = 'load applications error',

  SET_IDENTIFIER = 'set identifier',
  SET_NAME = 'set name',
  SET_DESCRIPTION = 'set description',
  SET_CATEGORY = 'set category',
  SET_DOMAIN_ID = 'set domain id',

  SET_PERSONA_TYPES = 'set persona types',
  ADD_RESOURCE = 'add resource',
  SET_RESOURCE = 'set resource',
  REMOVE_RESOURCE = 'remove resource',
  ADD_SCOPE = 'add scope',
  SET_SCOPE = 'set scope',
  REMOVE_SCOPE = 'remove scope',
  SET_SELECTED_LOCALE = 'set selected locale',
  SET_TEMPLATE_TAB = 'set template tab',

  SET_ACCOUNT_GROUP_HYDRATOR = 'set account group hydrator',
  SET_SALESPERSON_HYDRATOR = 'set salesperson hydrator',
  SET_PRODUCT_HYDRATOR = 'set product hydrator',
  SET_PARTNER_BRANDING_HYDRATOR = 'set partner branding hydrator',
  SET_DIGITAL_AGENT_HYDRATOR = 'set digital agent hydrator',
  SET_USER_HYDRATOR = 'set user hydrator',

  SEND_TEST = 'send test',
  SEND_TEST_SUCCESS = 'send test success',
  SEND_TEST_ERROR = 'send test error',
}

export class Init {
  readonly type = GeneralActionTypes.INIT;
}

export class Save {
  readonly type = GeneralActionTypes.SAVE;
}

export class SaveSuccess {
  readonly type = GeneralActionTypes.SAVE_SUCCESS;
}

export class SaveError {
  readonly type = GeneralActionTypes.SAVE_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export class Delete {
  readonly type = GeneralActionTypes.DELETE;
}

export class DeleteSuccess {
  readonly type = GeneralActionTypes.DELETE_SUCCESS;
}

export class DeleteError {
  readonly type = GeneralActionTypes.DELETE_ERROR;
  constructor(readonly payload: { error: string }) {}
}

export class LoadNotification {
  readonly type = GeneralActionTypes.LOAD_TYPE;
  constructor(readonly payload: { id: string }) {}
}

export class LoadNotificationSuccess {
  readonly type = GeneralActionTypes.LOAD_TYPE_SUCCESS;
  constructor(readonly payload: { type: NotificationType }) {}
}

export class LoadNotificationError {
  readonly type = GeneralActionTypes.LOAD_TYPE_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export class LoadEvent {
  readonly type = GeneralActionTypes.LOAD_EVENT;
}

export class LoadEventSuccess {
  readonly type = GeneralActionTypes.LOAD_EVENT_SUCCESS;
  constructor(readonly payload: { event: EventTypeInterface }) {}
}

export class LoadEventError {
  readonly type = GeneralActionTypes.LOAD_EVENT_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export class SetAccountGroupDataAvailable {
  readonly type = GeneralActionTypes.SET_ACCOUNT_GROUP_DATA_AVAILABLE;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetSalespersonDataAvailable {
  readonly type = GeneralActionTypes.SET_SALESPERSON_DATA_AVAILABLE;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetProductDataAvailable {
  readonly type = GeneralActionTypes.SET_PRODUCT_DATA_AVAILABLE;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetPartnerBrandingDataAvailable {
  readonly type = GeneralActionTypes.SET_PARTNER_BRANDING_DATA_AVAILABLE;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetDigitalAgentDataAvailable {
  readonly type = GeneralActionTypes.SET_DIGITAL_AGENT_DATA_AVAILABLE;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetUserDataAvailable {
  readonly type = GeneralActionTypes.SET_USER_DATA_AVAILABLE;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class LoadEvents {
  readonly type = GeneralActionTypes.LOAD_EVENTS;
  constructor(readonly payload: { searchTerm: string }) {}
}

export class LoadMoreEvents {
  readonly type = GeneralActionTypes.LOAD_MORE_EVENTS;
}

export class LoadEventsSuccess {
  readonly type = GeneralActionTypes.LOAD_EVENTS_SUCCESS;
  constructor(readonly payload: { events: EventTypeInterface[]; cursor: string; hasMore: boolean }) {}
}

export class LoadEventsError {
  readonly type = GeneralActionTypes.LOAD_EVENTS_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export class LoadApplications {
  readonly type = GeneralActionTypes.LOAD_APPLICATIONS;
}

export class LoadApplicationsSuccess {
  readonly type = GeneralActionTypes.LOAD_APPLICATIONS_SUCCESS;
  constructor(readonly payload: { applications: Application[] }) {}
}

export class LoadApplicationsError {
  readonly type = GeneralActionTypes.LOAD_APPLICATIONS_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export class SetEvent {
  readonly type = GeneralActionTypes.SET_EVENT;
  constructor(readonly payload: { event: EventTypeInterface }) {}
}

export class SetIdentifier {
  readonly type = GeneralActionTypes.SET_IDENTIFIER;
  constructor(readonly payload: { identifier: string }) {}
}

export class SetName {
  readonly type = GeneralActionTypes.SET_NAME;
  constructor(readonly payload: { name: string }) {}
}

export class SetDescription {
  readonly type = GeneralActionTypes.SET_DESCRIPTION;
  constructor(readonly payload: { description: string }) {}
}

export class SetCategory {
  readonly type = GeneralActionTypes.SET_CATEGORY;
  constructor(readonly payload: { category: string }) {}
}

export class SetDomainId {
  readonly type = GeneralActionTypes.SET_DOMAIN_ID;
  constructor(readonly payload: { domainId: string }) {}
}

export class SetPersonaTypes {
  readonly type = GeneralActionTypes.SET_PERSONA_TYPES;
  constructor(readonly payload: { personaTypes: PersonaType[] }) {}
}

export class AddResource {
  readonly type = GeneralActionTypes.ADD_RESOURCE;
}

export class SetResource {
  readonly type = GeneralActionTypes.SET_RESOURCE;
  constructor(readonly payload: { resourceIndex: number; resourceName: string }) {}
}

export class RemoveResource {
  readonly type = GeneralActionTypes.REMOVE_RESOURCE;
  constructor(readonly payload: { resourceIndex: number }) {}
}

export class AddScope {
  readonly type = GeneralActionTypes.ADD_SCOPE;
  constructor(readonly payload: { resourceIndex: number }) {}
}

export class SetScope {
  readonly type = GeneralActionTypes.SET_SCOPE;
  constructor(readonly payload: { resourceIndex: number; scopeIndex: number; scopeId: string }) {}
}

export class RemoveScope {
  readonly type = GeneralActionTypes.REMOVE_SCOPE;
  constructor(readonly payload: { resourceIndex: number; scopeIndex: number }) {}
}

export class SetSelectedLocale {
  readonly type = GeneralActionTypes.SET_SELECTED_LOCALE;
  constructor(readonly payload: { locale: string }) {}
}

export class SetTemplateTab {
  readonly type = GeneralActionTypes.SET_TEMPLATE_TAB;
  constructor(readonly payload: { templateTab: TemplateTab }) {}
}

export class SetAccountGroupHydrator {
  readonly type = GeneralActionTypes.SET_ACCOUNT_GROUP_HYDRATOR;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetSalespersonHydrator {
  readonly type = GeneralActionTypes.SET_SALESPERSON_HYDRATOR;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetProductHydrator {
  readonly type = GeneralActionTypes.SET_PRODUCT_HYDRATOR;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetPartnerBrandingHydrator {
  readonly type = GeneralActionTypes.SET_PARTNER_BRANDING_HYDRATOR;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetDigitalAgentHydrator {
  readonly type = GeneralActionTypes.SET_DIGITAL_AGENT_HYDRATOR;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetUserHydrator {
  readonly type = GeneralActionTypes.SET_USER_HYDRATOR;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SendTest {
  readonly type = GeneralActionTypes.SEND_TEST;
}

export class SendTestSuccess {
  readonly type = GeneralActionTypes.SEND_TEST_SUCCESS;
}

export class SendTestError {
  readonly type = GeneralActionTypes.SEND_TEST_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export type GeneralActions =
  | Init
  | Save
  | SaveSuccess
  | SaveError
  | Delete
  | DeleteSuccess
  | DeleteError
  | LoadNotification
  | LoadNotificationSuccess
  | LoadNotificationError
  | LoadEvent
  | LoadEventSuccess
  | LoadEventError
  | SetAccountGroupDataAvailable
  | SetSalespersonDataAvailable
  | SetProductDataAvailable
  | SetPartnerBrandingDataAvailable
  | SetDigitalAgentDataAvailable
  | SetUserDataAvailable
  | LoadEvents
  | LoadMoreEvents
  | LoadEventsSuccess
  | LoadEventsError
  | LoadApplications
  | LoadApplicationsSuccess
  | LoadApplicationsError
  | SetEvent
  | SetIdentifier
  | SetName
  | SetDescription
  | SetCategory
  | SetDomainId
  | SetPersonaTypes
  | AddResource
  | SetResource
  | RemoveResource
  | AddScope
  | SetScope
  | RemoveScope
  | SetSelectedLocale
  | SetTemplateTab
  | SetAccountGroupHydrator
  | SetSalespersonHydrator
  | SetDigitalAgentHydrator
  | SetProductHydrator
  | SetPartnerBrandingHydrator
  | SetUserHydrator
  | SendTest
  | SendTestSuccess
  | SendTestError;
