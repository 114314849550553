import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  styleUrls: ['./common.scss'],
  template: `
    <div class="container">
      <notification-manage
        [typeId]="typeId$ | async"
        createRoute="/notifications/create"
        editRoute="/notifications/edit/"
        listRoute="/notifications"
        releaseRoute="/notifications/release/"
      />
    </div>
  `,
})
export class EditComponent {
  public readonly typeId$: Observable<string> = this.route.params.pipe(map((r) => r.typeId));

  constructor(private route: ActivatedRoute) {}
}
