import { Component, inject } from '@angular/core';
import { CustomUserDropdownItem } from '@galaxy/atlas';
import { AuthService } from './core/auth/auth.service';
import { GalaxyAiIconService } from '@vendasta/galaxy/ai-icon';

@Component({
  selector: 'app-authed-app',
  templateUrl: './authed-app.component.html',
  styleUrls: ['./authed-app.component.scss'],
})
export class AuthedAppComponent {
  private authService = inject(AuthService);
  private aiIconService = inject(GalaxyAiIconService);

  dropdownItems: CustomUserDropdownItem[] = [
    {
      label: 'Logout',
      callback: () => this.logout(),
    },
  ];

  logout(): void {
    this.authService.logout({ namespace: 'VA' });
  }
}
