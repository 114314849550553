import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConfirmNoEventDialogComponent,
  ConfirmNoEventDialogResult,
} from './components/event-selector/confirm-no-event-dialog/confirm-no-event-dialog.component';

import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { NotificationMedium, PersonaType } from '@vendasta/notifications-sdk';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeatureFlag } from '@galaxy/partner';
import { EventTypeInterface } from '@vendasta/event-broker';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { NotificationType } from '../../common/notifications/notifications-admin.service';
import { Application } from './application';
import { AddLocaleDialogComponent } from './components/add-locale-dialog/add-locale-dialog.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { EditorService } from './editor.service';
import { Locale } from './locales';
import { TemplateTab } from './reducers/general.reducers';

@Component({
  selector: 'notification-manage',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() typeId: string;
  @Input() createRoute: string;
  @Input() editRoute: string;
  @Input() listRoute: string;
  @Input() releaseRoute: string;

  public medium = NotificationMedium;

  public new$: Observable<boolean> = this.editorService.new$;
  public releaseDisabled$: Observable<boolean> = combineLatest([
    this.editorService.new$,
    this.editorService.edited$,
    this.editorService.type$,
  ]).pipe(
    map(([newType, edited, type]) => newType || edited || type.listEnabledMediums().length === 0),
    distinctUntilChanged(),
  );
  public loading$: Observable<boolean> = this.editorService.typeLoading$;
  public error$: Observable<boolean> = this.editorService.typeError$;
  public type$: Observable<NotificationType> = this.editorService.type$;
  public saving$: Observable<boolean> = this.editorService.saving$;
  public savingSuccess$: Observable<any> = this.editorService.savingSuccess$;
  public savingError$: Observable<any> = this.editorService.savingError$;
  public deleting$: Observable<boolean> = this.editorService.deleting$;
  public deletingSuccess$: Observable<boolean> = this.editorService.deletingSuccess$;
  public deletingError$: Observable<string> = this.editorService.deletingError$;
  public edited$: Observable<boolean> = this.editorService.edited$;
  public event$: Observable<EventTypeInterface> = this.editorService.event$;
  public eventLoading$: Observable<boolean> = this.editorService.eventLoading$;
  public eventError$: Observable<any> = this.editorService.eventError$;
  public accountGroupDataAvailable$: Observable<boolean> = this.editorService.accountGroupDataAvailable$;
  public salespersonDataAvailable$: Observable<boolean> = this.editorService.salespersonDataAvailable$;
  public productDataAvailable$: Observable<boolean> = this.editorService.productDataAvailable$;
  public partnerBrandingDataAvailable$: Observable<boolean> = this.editorService.partnerBrandingDataAvailable$;
  public digitalAgentDataAvailable$: Observable<boolean> = this.editorService.digitalAgentDataAvailable$;
  public userDataAvailable$: Observable<boolean> = this.editorService.userDataAvailable$;

  public events$: Observable<EventTypeInterface[]> = this.editorService.events$;
  public eventsLoading$: Observable<boolean> = this.editorService.eventsLoading$;
  public eventsError$: Observable<any> = this.editorService.eventsError$;
  public eventsHasMore$: Observable<boolean> = this.editorService.eventsHasMore$;

  public applications$: Observable<Application[]> = this.editorService.applications$;

  public featureFlagId$: Observable<string> = this.editorService.featureFlagId$;
  public fetchingFeatureFlags$: Observable<boolean> = this.editorService.fetchingFeatureFlags$;
  public featureFlagsError$: Observable<any> = this.editorService.featureFlagsError$;
  public featureFlags$: Observable<FeatureFlag[]> = this.editorService.featureFlags$;

  public locales$: Observable<Locale[]> = this.editorService.locales$;
  public selectedLocale$: Observable<string> = this.editorService.selectedLocale$.pipe(
    map((selectedLocale) => (selectedLocale ? selectedLocale.label : 'Select Locale')),
  );

  public sendTestLoading$: Observable<boolean> = this.editorService.sendTestLoading$;
  public canSendPreview$: Observable<boolean> = combineLatest([
    this.editorService.new$,
    this.editorService.edited$,
  ]).pipe(map(([newNotification, edited]) => !newNotification && !edited));

  public inAppSample$: Observable<string> = this.editorService.inAppSample$;
  public inAppTemplateLink$: Observable<string> = this.editorService.inAppLinkTemplate$;
  public inAppTemplateContent$: Observable<string> = this.editorService.inAppContentTemplate$;
  public inAppRenderedHTML$: Observable<string> = this.editorService.inAppRenderedHTML$;
  public inAppRenderedHTMLLoading$: Observable<boolean> = this.editorService.inAppRenderedHTMLLoading$;
  public inAppRenderedHTMLError$: Observable<any> = this.editorService.inAppRenderedHTMLError$;

  public inAppBundleWindow$: Observable<number> = this.editorService.inAppBundleWindowMinutes$;
  public inAppBundleSample$: Observable<string> = this.editorService.inAppBundleSample$;
  public inAppBundleTemplateLink$: Observable<string> = this.editorService.inAppBundleLinkTemplate$;
  public inAppBundleTemplateContent$: Observable<string> = this.editorService.inAppBundleContentTemplate$;
  public inAppBundleRenderedHTML$: Observable<string> = this.editorService.inAppBundleRenderedHTML$;
  public inAppBundleRenderedHTMLLoading$: Observable<boolean> = this.editorService.inAppBundleRenderedHTMLLoading$;
  public inAppBundleRenderedHTMLError$: Observable<any> = this.editorService.inAppBundleRenderedHTMLError$;

  public emailSample$: Observable<string> = this.editorService.emailSample$;
  public emailTemplateContent$: Observable<string> = this.editorService.emailContentTemplate$;
  public emailTemplateSubject$: Observable<string> = this.editorService.emailSubjectTemplate$;
  public emailTemplateLink$: Observable<string> = this.editorService.emailLinkTemplate$;
  public emailTemplateFromAddress$: Observable<string> = this.editorService.emailFromAddressTemplate$;
  public emailTemplateFromName$: Observable<string> = this.editorService.emailFromNameTemplate$;
  public emailRenderedHTML$: Observable<string> = this.editorService.emailRenderedHTML$;
  public emailRenderedHTMLLoading$: Observable<boolean> = this.editorService.emailRenderedHTMLLoading$;
  public emailRenderedHTMLError$: Observable<boolean> = this.editorService.emailRenderedHTMLError$;

  public readonly templateTab = TemplateTab;
  public readonly notificationMedium = NotificationMedium;
  public readonly selectedTemplateTab$: Observable<TemplateTab> = this.editorService.store.pipe(
    map((state) => state.general.templateTab),
    distinctUntilChanged(),
  );
  private subscriptions: Subscription[] = [];

  templatePanelOpen = false;

  constructor(
    private editorService: EditorService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.load();
    const loadFeatureFlags$ = this.featureFlagId$.subscribe((fid) => {
      if (!fid) {
        fid = '';
      }
      this.editorService.loadFeatureFlags(fid);
    });

    const saveSuccess$ = this.savingSuccess$
      .pipe(
        filter((success) => !!success),
        switchMap(() => this.type$),
        map((type) => type.notificationTypeId),
      )
      .subscribe((typeId) => {
        this.snackBar.open('Notification saved', null, { duration: 2400 });
        if (this.router.url === this.createRoute) {
          this.router.navigate([this.editRoute, typeId]);
        }
      });
    const saveError$ = this.savingError$
      .pipe(filter((err) => !!err))
      .subscribe((msg) => this.snackBar.open(msg || 'Failed to save notification.', null, { duration: 2400 }));
    const deleteSuccess$ = this.editorService.deletingSuccess$
      .pipe(filter((success) => !!success))
      .subscribe(() => this.router.navigate([this.listRoute]));
    const deleteError$ = this.editorService.deletingError$
      .pipe(filter((err) => !!err))
      .subscribe((msg) => this.snackBar.open(msg || 'Failed to delete notification.', null, { duration: 2400 }));
    const sendTestSuccess$ = this.editorService.sendTestSuccess$
      .pipe(filter((success) => !!success))
      .subscribe(() => this.snackBar.open('Test sent.', null, { duration: 2400 }));
    const sendTestError$ = this.editorService.sendTestError$
      .pipe(filter((error) => !!error))
      .subscribe((err) => this.snackBar.open(err, null, { duration: 6000 }));

    this.subscriptions.push(
      saveSuccess$,
      saveError$,
      deleteSuccess$,
      deleteError$,
      sendTestSuccess$,
      sendTestError$,
      loadFeatureFlags$,
    );
  }

  loadFeatureFlags(searchTerm: string): void {
    this.editorService.loadFeatureFlags(searchTerm);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  load(searchTerm?: string): void {
    this.editorService.init(this.typeId, searchTerm);
  }

  reloadEvent(): void {
    this.editorService.loadEvent();
  }

  loadMoreEvents(): void {
    this.editorService.loadMoreEvents();
  }

  selectEvent(event: EventTypeInterface): void {
    this.editorService.setEvent(event);
  }

  skipEventSelection(): void {
    const ref = this.dialog.open(ConfirmNoEventDialogComponent, {
      maxWidth: '90vw',
      width: '650px',
    });
    ref.afterClosed().subscribe((result?: ConfirmNoEventDialogResult) => {
      if (result?.confirmed) {
        this.editorService.setEvent({
          id: 'provided',
          description: 'Notification will be manually sent opposed to being event-triggered',
          name: 'Provided',
        });
      }
    });
  }

  private warnOnUnsavedChanges(fn: () => Promise<boolean>): void {
    this.editorService.edited$.pipe(take(1)).subscribe((edited) => {
      if (!edited) {
        fn();
      } else {
        const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, { width: '440px' });
        dialogRef.afterClosed().subscribe((exit: boolean) => {
          if (exit) {
            fn();
          }
        });
      }
    });
  }

  save(): void {
    this.editorService.save();
  }

  cancel(): void {
    this.warnOnUnsavedChanges(() => this.router.navigateByUrl(this.listRoute));
  }

  release(id: string): void {
    this.warnOnUnsavedChanges(() => this.router.navigateByUrl(this.releaseRoute + id));
  }

  setIdentifier(identifier: string): void {
    this.editorService.setIdentifier(identifier);
  }

  setName(name: string): void {
    this.editorService.setName(name);
  }

  setDescription(description: string): void {
    this.editorService.setDescription(description);
  }

  setCategory(category: string): void {
    this.editorService.setCategory(category);
  }

  setDomainId(domainId: string): void {
    this.editorService.setDomainId(domainId);
  }

  setFeatureFlagId(id: string): void {
    this.editorService.setFeatureFlagId(id);
  }

  setPersonaTypes(personaTypes: PersonaType[]): void {
    this.editorService.setPersonaTypes(personaTypes);
  }

  addResource(): void {
    this.editorService.addResource();
  }

  setResource(resourceIndex: number, resourceName: string): void {
    this.editorService.setResource(resourceIndex, resourceName);
  }

  removeResource(resourceIndex: number): void {
    this.editorService.removeResource(resourceIndex);
  }

  addScope(resourceIndex: number): void {
    this.editorService.addScope(resourceIndex);
  }

  setScope(resourceIndex: number, scopeIndex: number, scopeId: string): void {
    this.editorService.setScope(resourceIndex, scopeIndex, scopeId);
  }

  removeScope(resourceIndex: number, scopeIndex: number): void {
    this.editorService.removeScope(resourceIndex, scopeIndex);
  }

  setAccountGroupHydrator(enabled: boolean): void {
    this.editorService.setAccountGroupHydrator(enabled);
  }

  setSalespersonHydrator(enabled: boolean): void {
    this.editorService.setSalespersonHydrator(enabled);
  }

  setProductHydrator(enabled: boolean): void {
    this.editorService.setProductHydrator(enabled);
  }

  setPartnerBrandingHydrator(enabled: boolean): void {
    this.editorService.setPartnerBrandingHydrator(enabled);
  }

  setDigitalAgentHydrator(enabled: boolean): void {
    this.editorService.setDigitalAgentHydrator(enabled);
  }

  setUserHydrator(enabled: boolean): void {
    this.editorService.setUserHydrator(enabled);
  }

  delete(typeId: string): void {
    this.editorService.delete(typeId);
  }

  public setTemplateTab(tab: TemplateTab): void {
    this.editorService.setTemplateTab(tab);
  }

  public setInAppEnabled(enabled: boolean): void {
    this.editorService.setInAppEnabled(enabled);
  }

  public setInAppLink(link: string): void {
    this.editorService.setInAppLink(link);
  }

  public setInAppContent(content: string): void {
    this.editorService.setInAppContent(content);
  }

  public setInAppSample(sample: string): void {
    this.editorService.setInAppSample(sample);
  }

  public setInAppBundleEnabled(enabled: boolean): void {
    this.editorService.setInAppBundleEnabled(enabled);
  }

  public setInAppBundleWindow(window: number): void {
    this.editorService.setInAppBundleWindow(window);
  }

  public setInAppBundleLink(link: string): void {
    this.editorService.setInAppBundleLink(link);
  }

  public setInAppBundleContent(content: string): void {
    this.editorService.setInAppBundleContent(content);
  }

  public setInAppBundleSample(sample: string): void {
    this.editorService.setInAppBundleSample(sample);
  }

  public setEmailEnabled(enabled: boolean): void {
    this.editorService.setEmailEnabled(enabled);
  }

  public setEmailContent(template: string): void {
    this.editorService.setEmailContent(template);
  }

  public setEmailSample(sample: string): void {
    this.editorService.setEmailSample(sample);
  }

  public setEmailLink(template: string): void {
    this.editorService.setEmailLink(template);
  }

  public setEmailSubject(template: string): void {
    this.editorService.setEmailSubject(template);
  }

  public setEmailFromAddress(template: string): void {
    this.editorService.setEmailFromAddress(template);
  }

  public setEmailFromName(template: string): void {
    this.editorService.setEmailFromName(template);
  }

  public setEmailContentType(contentType: any): void {
    this.editorService.setEmailContentType(contentType);
  }

  public setEmailServiceProvider(serviceProvider: string): void {
    this.editorService.setEmailServiceProvider(serviceProvider);
  }

  public sendTest(medium: NotificationMedium, bundle?: boolean): void {
    this.editorService.sendTest(medium, bundle === true);
  }

  public addLocale(): void {
    const dialogRef = this.dialog.open(AddLocaleDialogComponent, { width: '440px', autoFocus: false });
    dialogRef.afterClosed().subscribe((selectedLocale) => {
      if (!selectedLocale) {
        return;
      }
      this.editorService.convertToTemplate();
      this.editorService.addLocale(selectedLocale);
      this.selectLocale(selectedLocale);
    });
  }

  public selectLocale(locale: string): void {
    this.editorService.setSelectedLocale(locale);
  }
}
