<h2 mat-dialog-title>Toggle Medium Notification</h2>
<mat-dialog-content>
  <span *ngIf="data.enabled">
    Are you sure you want to enable
    <b>{{ data.type.name }}</b>
    notifications via
    <b>{{ data.mediumName }}</b>
    ? Once you turn this on, users can receive notifications for events
    published.
  </span>
  <span *ngIf="!data.enabled">
    Are you sure you want to disable
    <b>{{ data.type.name }}</b>
    notifications via
    <b>{{ data.mediumName }}</b>
    ? Once you turn this off, users will not receive notifications for events
    published. You can turn the channel back on in the future, but any events
    that happen while the channel is disabled will be discarded.
  </span>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>No</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="data" color="warn">Yes</button>
</mat-dialog-actions>
