import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'notification-confirm-no-event-dialog',
  templateUrl: './confirm-no-event-dialog.component.html',
})
export class ConfirmNoEventDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmNoEventDialogComponent, ConfirmNoEventDialogResult>) {}

  confirmed(): void {
    this.dialogRef.close({ confirmed: true });
  }
}

export interface ConfirmNoEventDialogResult {
  confirmed: boolean;
}
