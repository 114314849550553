<div class="event-loader" *ngIf="loading">
  <mat-spinner diameter="16"></mat-spinner>
</div>
<div class="error" *ngIf="error">
  Failed to load Event.
  <div>
    <button mat-stroked-button color="primary" (click)="reload.emit()">
      Retry
    </button>
  </div>
</div>
<div *ngIf="!loading && !error" class="container">
  <mat-form-field *ngIf="!!event" class="hint">
    <input
      matInput
      placeholder="Event Name"
      [value]="event.name"
      [disabled]="true"
      required
    />
    <mat-hint>
      Events cause users to get a Notification. The type may not be changed once
      a notification has been created.
    </mat-hint>
  </mat-form-field>
  <h4>Additional Template Data</h4>
  <div class="description">
    Some events can have additional data attached to them. By selecting the
    following options, the template will have more options available.
  </div>
  <div>
    <mat-checkbox
      [checked]="hydratorEnabled('accountGroup')"
      (change)="accountGroupHydratorChange.emit($event.checked)"
      [disabled]="!accountGroupDataAvailable"
      matTooltip="Event does not have the necessary data (string `account_group_id`) to include additional account group information."
      [matTooltipDisabled]="accountGroupDataAvailable"
    >
      Account Group (Name, Address)
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      [checked]="hydratorEnabled('salesperson')"
      (change)="salespersonHydratorChange.emit($event.checked)"
      [disabled]="!salespersonDataAvailable"
      matTooltip="Event does not have the necessary data (string `sales_person_id`) to include additional salesperson information."
      [matTooltipDisabled]="salespersonDataAvailable"
    >
      Salesperson (Name, Email)
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      [checked]="hydratorEnabled('product')"
      (change)="productHydratorChange.emit($event.checked)"
      [disabled]="!productDataAvailable"
      matTooltip="Event does not have the necessary data (string `app_id` or string `addon_id` or `product_ids`, see DEVELOPER_GUIDE.md for more information) to include additional product information."
      [matTooltipDisabled]="productDataAvailable"
    >
      Product and Package (Name)
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      [checked]="hydratorEnabled('partnerBranding')"
      (change)="partnerBrandingHydratorChange.emit($event.checked)"
      [disabled]="!partnerBrandingDataAvailable"
      matTooltip="Event does not have the necessary data (string `account_group_id` or string `partner_id` and string `market_id`) to include additional partner branding information."
      [matTooltipDisabled]="partnerBrandingDataAvailable"
    >
      Partner Branding (Name, Branding Colour, Favicon, Logo, Whitelabeled App
      Names)
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      [checked]="hydratorEnabled('digitalAgent')"
      (change)="digitalAgentHydratorChange.emit($event.checked)"
      [disabled]="!digitalAgentDataAvailable"
      matTooltip="Event does not have the necessary data (string `digital_agent_id`) to include additional digital agent information."
      [matTooltipDisabled]="digitalAgentDataAvailable"
    >
      Digital Agent (Name)
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox
      [checked]="hydratorEnabled('user')"
      (change)="userHydratorChange.emit($event.checked)"
      [disabled]="!userDataAvailable"
      matTooltip="Event does not have the necessary data (string `user_id`) to include additional user information."
      [matTooltipDisabled]="userDataAvailable"
    >
      User (First Name, Last Name)
    </mat-checkbox>
  </div>
</div>
