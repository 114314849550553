import { Component } from '@angular/core';

@Component({
  styleUrls: ['./common.scss'],
  template: `
    <div class="container">
      <notification-manage
        createRoute="/notifications/create"
        editRoute="/notifications/edit/"
        listRoute="/notifications"
        releaseRoute="/notifications/release/"
      />
    </div>
  `,
})
export class CreateComponent {}
