import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ContentType } from '@vendasta/notifications-sdk';

@Component({
  selector: 'notification-manage-template-email',
  styleUrls: ['email-template-settings.component.scss'],
  templateUrl: './email-template-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailTemplateSettingsComponent {
  @Input() enabled = false;
  @Output() enabledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() subject: string;
  @Output() subjectChange: EventEmitter<string> = new EventEmitter();

  @Input() link: string;
  @Output() linkChange: EventEmitter<string> = new EventEmitter();

  @Input() fromName: string;
  @Output() fromNameChange: EventEmitter<string> = new EventEmitter();

  @Input() fromAddress: string;
  @Output() fromAddressChange: EventEmitter<string> = new EventEmitter();

  @Input() contentType: ContentType;
  @Output() contentTypeChange: EventEmitter<ContentType> = new EventEmitter();

  @Input() emailServiceProvider: string;
  @Output() emailServiceProviderChange: EventEmitter<string> = new EventEmitter();

  contentTypeOption = ContentType;

  sendFromOptions = {
    SEND_FROM_SMB: 'noreply@smblogin.com',
    SEND_FROM_VENDASTA: 'noreply@vendasta.com',
    SEND_FROM_DIGITAL_AGENTS: 'noreply@yourdigitalagents.com',
  };
}
