import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  styleUrls: ['./common.scss'],
  template: `
    <div class="container">
      <notification-release-form [typeId]="typeId$ | async" listRoute="/notifications" />
    </div>
  `,
})
export class ReleaseComponent {
  public readonly typeId$: Observable<string> = this.route.params.pipe(map((r) => r.typeId));

  constructor(private route: ActivatedRoute) {}
}
