import { NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterModule, MatIconModule, NgTemplateOutlet, MatRippleModule],
  selector: 'glxy-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalaxyNavItemComponent {
  @HostBinding('class') class = 'glxy-nav-item';

  /** Material icon name, or custom SVG icon **/
  @Input() icon?: string;

  /** Route for the nav item to use with routerLink **/
  @Input() route?: string;

  /** Or use an href for the nav item **/
  @Input() href?: string;

  /** Optionally specify a target for the href link **/
  @Input() target?: string;

  /** Optional query params for the route **/
  @Input() queryParams: unknown;

  /** If your icon is a custom SVG **/
  @Input({ transform: booleanAttribute }) isSvg = false;

  /** If the nav item should show active state only when it's an exact match **/
  @Input({ transform: booleanAttribute }) activeExactRoute = false;
}
