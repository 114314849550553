import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => null,
        (httpEvent: HttpEvent<any>) => {
          if ('status' in httpEvent && httpEvent.status === 403) {
            console.error("Access denied: You're not authorized to access this resource");
          }
        },
      ),
    );
  }
}
