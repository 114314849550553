import { NgModule } from '@angular/core';
import { ListComponent } from './list.component';
import { BrowserModule } from '@angular/platform-browser';
import { CreateComponent } from './create.component';
import { EditComponent } from './edit.component';
import { ReleaseComponent } from './release.component';
import { ManageModule } from '@vendasta/notifications';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ListComponent, CreateComponent, EditComponent, ReleaseComponent],
  exports: [ListComponent, CreateComponent, EditComponent, ReleaseComponent],
  imports: [BrowserModule, ManageModule, GalaxyPageModule, MatButtonModule, RouterModule],
  providers: [],
})
export class NotificationsModule {}
