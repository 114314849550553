import { FeatureFlag } from '@galaxy/partner';

export enum FeatureFlagTypes {
  FETCH_FEATURE_FLAGS = 'fetch feature flags',
  FETCH_FEATURE_FLAGS_SUCCESS = 'fetch feature flags success',
  FETCH_FEATURE_FLAGS_FAILURE = 'fetch feature flags failure',
  SET_FEATURE_FLAG_ID = 'set feature flag id',
}

export class FetchFeatureFlags {
  readonly type = FeatureFlagTypes.FETCH_FEATURE_FLAGS;
}

export class FetchFeatureFlagsSuccess {
  readonly type = FeatureFlagTypes.FETCH_FEATURE_FLAGS_SUCCESS;
  constructor(readonly payload: { featureFlags: FeatureFlag[] }) {}
}

export class FetchFeatureFlagsFailure {
  readonly type = FeatureFlagTypes.FETCH_FEATURE_FLAGS_FAILURE;
  constructor(readonly payload: { error: any }) {}
}

export class SetFeatureFlagId {
  readonly type = FeatureFlagTypes.SET_FEATURE_FLAG_ID;
  constructor(readonly payload: { id: string }) {}
}

export type FeatureFlagActions =
  | FetchFeatureFlags
  | FetchFeatureFlagsSuccess
  | FetchFeatureFlagsFailure
  | SetFeatureFlagId;
