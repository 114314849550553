export enum LocaleActionTypes {
  ADD_LOCALE = 'add locale',
  SET_LOCALES = 'set locales',
  SELECTED_LOCALE_CHANGED = 'selected locale changed',
}

export class AddLocale {
  readonly type = LocaleActionTypes.ADD_LOCALE;
  constructor(readonly payload: { locale: string }) {}
}

export class SetLocales {
  readonly type = LocaleActionTypes.SET_LOCALES;
  constructor(readonly payload: { locales: string[] }) {}
}

export class SelectedLocaleChanged {
  readonly type = LocaleActionTypes.SELECTED_LOCALE_CHANGED;
  constructor(readonly payload: { locale: string }) {}
}

export type LocaleActions = AddLocale | SetLocales | SelectedLocaleChanged;
