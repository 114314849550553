import { ContentType } from '@vendasta/notifications-sdk';

export enum EmailActionTypes {
  LOAD_EMAIL_CONTENT_TEMPLATE = 'load email content template',
  LOAD_EMAIL_CONTENT_TEMPLATE_SUCCESS = 'load email content template success',
  LOAD_EMAIL_CONTENT_TEMPLATE_ERROR = 'load email content template error',
  LOAD_EMAIL_LINK_TEMPLATE = 'load email link template',
  LOAD_EMAIL_LINK_TEMPLATE_SUCCESS = 'load email link template success',
  LOAD_EMAIL_LINK_TEMPLATE_ERROR = 'load email link template error',
  LOAD_EMAIL_SUBJECT_TEMPLATE = 'load email subject template',
  LOAD_EMAIL_SUBJECT_TEMPLATE_SUCCESS = 'load email subject template success',
  LOAD_EMAIL_SUBJECT_TEMPLATE_ERROR = 'load email subject template error',
  LOAD_EMAIL_FROM_ADDRESS_TEMPLATE = 'load email from address template',
  LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_SUCCESS = 'load email from address template success',
  LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_ERROR = 'load email from address template error',
  LOAD_EMAIL_FROM_NAME_TEMPLATE = 'load email from name template',
  LOAD_EMAIL_FROM_NAME_TEMPLATE_SUCCESS = 'load email from name template success',
  LOAD_EMAIL_FROM_NAME_TEMPLATE_ERROR = 'load email from name template error',
  SET_EMAIL_TEMPLATE_ENABLED = 'set email template enabled',
  SET_EMAIL_CONTENT_TEMPLATE = 'set email content template',
  SET_EMAIL_SAMPLE = 'set email sample',
  SET_EMAIL_LINK_TEMPLATE = 'set email link template',
  SET_EMAIL_SUBJECT_TEMPLATE = 'set email subject template',
  SET_EMAIL_FROM_ADDRESS_TEMPLATE = 'set email from address template',
  SET_EMAIL_FROM_NAME_TEMPLATE = 'set email from name template',
  SET_EMAIL_CONTENT_TYPE = 'set email content type',
  SET_EMAIL_SERVICE_PROVIDER = 'set email service provider',
  RENDER_EMAIL_TEMPLATE = 'render email template',
  RENDER_EMAIL_TEMPLATE_SUCCESS = 'render email template success',
  RENDER_EMAIL_TEMPLATE_ERROR = 'render email template error',
  CONVERT_EMAIL_CONTENT_TO_TEMPLATE = 'convert email content to template',
  CONVERT_EMAIL_LINK_TO_TEMPLATE = 'convert email link to template',
  CONVERT_EMAIL_SUBJECT_TO_TEMPLATE = 'convert email subject to template',
  CONVERT_EMAIL_FROM_NAME_TO_TEMPLATE = 'convert email from name to template',
  CONVERT_EMAIL_FROM_ADDRESS_TO_TEMPLATE = 'convert email from address to template',
}

export class LoadEmailContent {
  readonly type = EmailActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE;
}

export class LoadEmailContentSuccess {
  readonly type = EmailActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadEmailContentError {
  readonly type = EmailActionTypes.LOAD_EMAIL_CONTENT_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string; locale?: string }) {}
}

export class LoadEmailLink {
  readonly type = EmailActionTypes.LOAD_EMAIL_LINK_TEMPLATE;
}

export class LoadEmailLinkSuccess {
  readonly type = EmailActionTypes.LOAD_EMAIL_LINK_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadEmailLinkError {
  readonly type = EmailActionTypes.LOAD_EMAIL_LINK_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string; locale?: string }) {}
}

export class LoadEmailSubject {
  readonly type = EmailActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE;
}

export class LoadEmailSubjectSuccess {
  readonly type = EmailActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadEmailSubjectError {
  readonly type = EmailActionTypes.LOAD_EMAIL_SUBJECT_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string; locale?: string }) {}
}

export class LoadEmailFromAddress {
  readonly type = EmailActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE;
}

export class LoadEmailFromAddressSuccess {
  readonly type = EmailActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadEmailFromAddressError {
  readonly type = EmailActionTypes.LOAD_EMAIL_FROM_ADDRESS_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string; locale?: string }) {}
}

export class LoadEmailFromName {
  readonly type = EmailActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE;
}

export class LoadEmailFromNameSuccess {
  readonly type = EmailActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE_SUCCESS;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class LoadEmailFromNameError {
  readonly type = EmailActionTypes.LOAD_EMAIL_FROM_NAME_TEMPLATE_ERROR;
  constructor(readonly payload: { error: string; locale?: string }) {}
}

export class SetEmailEnabled {
  readonly type = EmailActionTypes.SET_EMAIL_TEMPLATE_ENABLED;
  constructor(readonly payload: { enabled: boolean }) {}
}

export class SetEmailContent {
  readonly type = EmailActionTypes.SET_EMAIL_CONTENT_TEMPLATE;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class SetEmailSample {
  readonly type = EmailActionTypes.SET_EMAIL_SAMPLE;
  constructor(readonly payload: { sample: string; sampleObj: object; edited: boolean }) {}
}

export class SetEmailSubject {
  readonly type = EmailActionTypes.SET_EMAIL_SUBJECT_TEMPLATE;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class SetEmailLink {
  readonly type = EmailActionTypes.SET_EMAIL_LINK_TEMPLATE;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class SetEmailFromAddress {
  readonly type = EmailActionTypes.SET_EMAIL_FROM_ADDRESS_TEMPLATE;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class SetEmailFromName {
  readonly type = EmailActionTypes.SET_EMAIL_FROM_NAME_TEMPLATE;
  constructor(readonly payload: { template: string; locale?: string }) {}
}

export class SetEmailContentType {
  readonly type = EmailActionTypes.SET_EMAIL_CONTENT_TYPE;
  constructor(readonly payload: { contentType: ContentType }) {}
}

export class SetEmailServiceProvider {
  readonly type = EmailActionTypes.SET_EMAIL_SERVICE_PROVIDER;
  constructor(readonly payload: { serviceProvider: string }) {}
}

export class RenderEmailTemplate {
  readonly type = EmailActionTypes.RENDER_EMAIL_TEMPLATE;
}

export class RenderEmailTemplateSuccess {
  readonly type = EmailActionTypes.RENDER_EMAIL_TEMPLATE_SUCCESS;
  constructor(readonly payload: { html: string }) {}
}

export class RenderEmailTemplateError {
  readonly type = EmailActionTypes.RENDER_EMAIL_TEMPLATE_ERROR;
  constructor(readonly payload: { error: any }) {}
}

export class ConvertEmailContentToTemplate {
  readonly type = EmailActionTypes.CONVERT_EMAIL_CONTENT_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export class ConvertEmailLinkToTemplate {
  readonly type = EmailActionTypes.CONVERT_EMAIL_LINK_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export class ConvertEmailSubjectToTemplate {
  readonly type = EmailActionTypes.CONVERT_EMAIL_SUBJECT_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export class ConvertEmailFromNameToTemplate {
  readonly type = EmailActionTypes.CONVERT_EMAIL_FROM_NAME_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export class ConvertEmailFromAddressToTemplate {
  readonly type = EmailActionTypes.CONVERT_EMAIL_FROM_ADDRESS_TO_TEMPLATE;
  constructor(readonly payload: { templateId: string }) {}
}

export type EmailActions =
  | LoadEmailContent
  | LoadEmailContentSuccess
  | LoadEmailContentError
  | LoadEmailLink
  | LoadEmailLinkSuccess
  | LoadEmailLinkError
  | LoadEmailSubject
  | LoadEmailSubjectSuccess
  | LoadEmailSubjectError
  | LoadEmailFromAddress
  | LoadEmailFromAddressSuccess
  | LoadEmailFromAddressError
  | LoadEmailFromName
  | LoadEmailFromNameSuccess
  | LoadEmailFromNameError
  | SetEmailEnabled
  | SetEmailContent
  | SetEmailSample
  | SetEmailSubject
  | SetEmailLink
  | SetEmailFromName
  | SetEmailFromAddress
  | SetEmailContentType
  | SetEmailServiceProvider
  | RenderEmailTemplate
  | RenderEmailTemplateSuccess
  | RenderEmailTemplateError
  | ConvertEmailContentToTemplate
  | ConvertEmailLinkToTemplate
  | ConvertEmailSubjectToTemplate
  | ConvertEmailFromNameToTemplate
  | ConvertEmailFromAddressToTemplate;
