import { Injectable } from '@angular/core';
import { Template, TemplateType, TemplatesApiService } from '@vendasta/templates';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TemplateTab } from './reducers/general.reducers';

const applicationId = 'notifications';

@Injectable()
export class TemplateService {
  constructor(private client: TemplatesApiService) {}

  public get(templateId: string, locale?: string): Observable<Template> {
    return this.client.get({ applicationId, templateId, locale }).pipe(map((resp) => resp.template));
  }

  public update(templateId: string, content: string, locale?: string): Observable<any> {
    return this.client.update({ applicationId, template: { templateId, content, locale } }).pipe(map(() => ({})));
  }

  public replace(templateId: string, content: string, locale?: string): Observable<any> {
    return this.client.replace({ applicationId, template: { templateId, content, locale } }).pipe(map(() => ({})));
  }

  public render(templateContent: string, parameters: any, templateTab?: TemplateTab): Observable<string> {
    const type =
      templateTab === TemplateTab.Email ? TemplateType.TEMPLATE_TYPE_GOLANG_TEXT : TemplateType.TEMPLATE_TYPE_GOLANG;
    return this.client.render({ templateContent, parameters, type }).pipe(map((resp) => resp.renderedTemplate || ''));
  }
}
