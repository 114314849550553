import { Component } from '@angular/core';

@Component({
  template: `
    <glxy-page [pagePadding]="false">
      <glxy-page-toolbar>
        <glxy-page-title>Notifications</glxy-page-title>

        <glxy-page-actions>
          <div class="actions">
            <a mat-stroked-button routerLink="/notifications/create" color="primary">Create</a>
          </div>
        </glxy-page-actions>
      </glxy-page-toolbar>

      <glxy-page-wrapper maxWidth="100%">
        <notification-type-list editRoute="/notifications/edit/" releaseRoute="/notifications/release/" />
      </glxy-page-wrapper>
    </glxy-page>
  `,
})
export class ListComponent {}
