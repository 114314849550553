<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-toolbar>
    Release {{ name$ | async }}
    <div class="spacer"></div>
    <div class="actions">
      <a mat-stroked-button [routerLink]="listRoute" [disabled]="isSubmitting$ | async">Cancel</a>
      <button type="submit" mat-stroked-button color="primary" [disabled]="isSubmitting$ | async">Release</button>
    </div>
  </mat-toolbar>
  <div class="loader" *ngIf="isLoading$ | async">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  <notification-manage-error *ngIf="showError$ | async" (retry)="loadType()">
    Unable to load the notification.
  </notification-manage-error>
  <div *ngIf="showContent$ | async">
    <div class="form content spacing mat-elevation-z1">
      <h2>Subscribe Users in Their Partner's Context</h2>
      <em>
        This form allows you to subscribe users to the notification in the context of the partner they exist in the
        namespace of. That is, they will have a subscription that cause them to receive notifications for all events
        that ultimately belong to their partner id.
      </em>
      <em class="last-note">
        IMPORTANT NOTE: Users with superadmin partner personas exist outside of a namespace, so they are skipped if
        present and cannot be subscribed using this method. If you want to subscribe namespaceless users to a specific
        partner context programmatically,
        <a
          href="https://github.com/vendasta/notifications/blob/master/USAGE_GUIDE.md#automatic-action-indicated-subscriptions"
        >
          see this section of User Guide
        </a>
      </em>
      <mat-form-field class="hint">
        <input matInput formControlName="typeId" placeholder="Identifier" autocomplete="off" />
        <mat-hint>An identifier is a kebab case name that uniquely and clearly defines the notification.</mat-hint>
        <mat-error *ngIf="form.get('typeId').hasError('pattern') && !form.get('typeId').hasError('required')">
          The identifier must be kebab case, and lowercase. Example: platform-announcement
        </mat-error>
        <mat-error *ngIf="!form.get('typeId').hasError('pattern') && form.get('typeId').hasError('required')">
          An identifier is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="hint">
        <mat-label>Medium</mat-label>
        <mat-select formControlName="medium">
          <mat-option *ngFor="let m of availableMediums$ | async" [value]="m">
            {{ mediumToString(m) }}
          </mat-option>
        </mat-select>
        <mat-hint>
          Choose a channel through which users will receive your notification. In-App refers to the notification tray in
          the navigation bar (Atlas).
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="hint">
        <ngx-mat-file-input
          formControlName="file"
          valuePlaceholder="CSV file only"
          [accept]="'.csv'"
        ></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
        <mat-hint>
          A header-less CSV with one column, where each row is an IAM User ID (not a Subject ID). This can be acquired
          using BigQuery or a manual method.
        </mat-hint>
        <mat-error *ngIf="form.get('file').hasError('header')">
          {{ form.get('file').getError('header') }}
        </mat-error>
        <mat-error *ngIf="form.get('file').hasError('parse')">
          {{ form.get('file').getError('parse') }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form content spacing mat-elevation-z1" *ngIf="userIdsSet$ | async">
      <h2>Users</h2>
      <mat-list role="list">
        <mat-list-item *ngFor="let user of userIds$ | async; last as last">
          <h4 mat-line>{{ user }}</h4>
          <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</form>
