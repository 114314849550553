import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AceModule } from 'ngx-ace-wrapper';
import { ACE_CONFIG } from 'ngx-ace-wrapper';
import { AceConfigInterface } from 'ngx-ace-wrapper';

import { NotificationsAdminService as NotificationsAdminServiceSDK } from '@vendasta/notifications-sdk';
import { NotificationsAdminService } from '../common/notifications/notifications-admin.service';

import { TypeListComponent, ToggleMediumDialogComponent } from './type-list/type-list.component';
import { ReleaseFormComponent } from './release/release-form.component';

import { EditorComponent } from './editor/editor.component';
import { UnsavedChangesDialogComponent } from './editor/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { GeneralSettingsComponent } from './editor/components/general-settings/general-settings.component';
import { PermissionsSettingsComponent } from './editor/components/permissions-settings/permissions-settings.component';
import { InAppTemplateSettingsComponent } from './editor/components/in-app-template-settings/in-app-template-settings.component';
import { EmailTemplateSettingsComponent } from './editor/components/email-template-settings/email-template-settings.component';
import { TemplatePreviewComponent } from './editor/components/template-preview/template-preview.component';
import { EventSettingsComponent } from './editor/components/event-settings/event-settings.component';
import { EditorService } from './editor/editor.service';
import { EventSelectorComponent } from './editor/components/event-selector/event-selector.component';
import { DeleteTypeDialogComponent } from './common/delete-type-dialog/delete-type-dialog.component';
import { ErrorComponent } from './common/error/error.component';
import { TemplateConfigurationComponent } from './editor/components/template-configuration/template-configuration.component';
import { TemplateService } from './editor/template.service';
import { MatMenuModule } from '@angular/material/menu';
import { AddLocaleDialogComponent } from './editor/components/add-locale-dialog/add-locale-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { ConfirmNoEventDialogComponent } from './editor/components/event-selector/confirm-no-event-dialog/confirm-no-event-dialog.component';
import { GalaxyAlertModule } from '@vendasta/galaxy/alert';
import { GalaxyBadgeModule } from '@vendasta/galaxy/badge';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

@NgModule({
  declarations: [
    TypeListComponent,
    DeleteTypeDialogComponent,
    UnsavedChangesDialogComponent,
    ToggleMediumDialogComponent,
    AddLocaleDialogComponent,
    ReleaseFormComponent,
    ErrorComponent,
    EditorComponent,
    GeneralSettingsComponent,
    PermissionsSettingsComponent,
    EventSettingsComponent,
    InAppTemplateSettingsComponent,
    EmailTemplateSettingsComponent,
    TemplatePreviewComponent,
    EventSelectorComponent,
    TemplateConfigurationComponent,
    ConfirmNoEventDialogComponent,
  ],
  exports: [TypeListComponent, ReleaseFormComponent, EditorComponent],
  imports: [
    CommonModule,
    RouterModule,
    AceModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatListModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MaterialFileInputModule,
    MatExpansionModule,
    MatToolbarModule,
    MatCardModule,
    GalaxyAlertModule,
    GalaxyBadgeModule,
  ],
  providers: [
    NotificationsAdminServiceSDK,
    { provide: 'AdminService', useClass: NotificationsAdminService },
    TemplateService,
    EditorService,
    { provide: ACE_CONFIG, useValue: DEFAULT_ACE_CONFIG },
  ],
})
export class ManageModule {}
