import { CDK_ACCORDION, CdkAccordion } from '@angular/cdk/accordion';
import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'glxy-nav-panel',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss'],
  providers: [{ provide: CDK_ACCORDION, useExisting: GalaxyNavPanelComponent }],
  encapsulation: ViewEncapsulation.None,
})
export class GalaxyNavPanelComponent extends CdkAccordion {
  @HostBinding('class') class = 'glxy-nav-panel';

  /** Whether the panel should allow multiple expanded accordion items simultaneously. */
  override multi = true;
}
