import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventTypeInterface } from '@vendasta/event-broker';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'notification-manage-event-selector',
  styleUrls: ['./event-selector.component.scss'],
  templateUrl: './event-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSelectorComponent implements OnInit {
  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() events: EventTypeInterface[];
  @Input() hasMore: boolean;
  @Output() searchTerm: EventEmitter<string> = new EventEmitter();
  @Output() loadMore: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() selected: EventEmitter<EventTypeInterface> = new EventEmitter();
  @Output() skip: EventEmitter<void> = new EventEmitter();

  private searchTerm$$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  ngOnInit(): void {
    this.searchTerm$$
      .asObservable()
      .pipe(debounceTime(200))
      .subscribe((s: string) => this.searchTerm.emit(s));
  }

  changeSearch(searchTerm: string): void {
    this.searchTerm$$.next(searchTerm);
  }
}
